import { IconCircleMinus, IconCirclePlus } from 'assets/icons';
import { useEffect } from 'react';
import { useRef, useState } from 'react';
import { FiChevronDown } from 'react-icons/fi';

import { Button, Container, Content } from './styles';

interface AccordionProps extends React.HTMLAttributes<HTMLDivElement> {
  buttonTitle: React.ReactNode;
  isOpen: boolean;
  icon: 'arrow' | 'circle';
}

export const Accordion: React.FC<AccordionProps> = ({
  buttonTitle,
  children,
  isOpen,
  icon,
  ...props
}: AccordionProps) => {
  const contentSpace = useRef<HTMLDivElement>(null);

  const [active, setActive] = useState(false);
  const [height, setHeight] = useState('0px');

  useEffect(() => {
    if (!isOpen) return;
    setActive(isOpen);
    setHeight(
      active
        ? '0px'
        : `${contentSpace?.current?.scrollHeight ? contentSpace.current.scrollHeight + 20 : 0}px`
    );
  }, []);

  function toggleAccordion() {
    setActive(!active);
    setHeight(
      active
        ? '0px'
        : `${contentSpace?.current?.scrollHeight ? contentSpace.current.scrollHeight + 20 : 0}px`
    );
  }

  return (
    <Container {...props}>
      <Button onClick={toggleAccordion}>
        <span>{buttonTitle}</span>
        {icon === 'arrow' && <FiChevronDown className={active ? 'open' : ''} />}
        {icon === 'circle' && active && <IconCircleMinus className="open" />}
        {icon === 'circle' && !active && <IconCirclePlus />}
      </Button>
      <Content ref={contentSpace} className={active ? 'open' : ''} style={{ height: `${height}` }}>
        {children}
      </Content>
    </Container>
  );
};
