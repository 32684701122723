import { LogoHi } from 'assets/vectors';
import { Button } from 'components';
import { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { animated, useSpring } from 'react-spring';

import { appearFromBottomConfig, fadeInConfig } from '../animations';
import { Main, Section } from '../styles';

export const Welcome: React.FC = () => {
  const history = useHistory();
  const fadeIn = useSpring(fadeInConfig);
  const appearFromBottom = useSpring(appearFromBottomConfig);

  useEffect(() => {
    document.body.classList.add('purple-bg');
  }, []);

  return (
    <Main>
      <Section style={fadeIn}>
        <Link to="/">
          <LogoHi className="logo" />
        </Link>
        <div className="description">
          <h1>Bem-vindo/a ao Hi!</h1>
          <p>
            Os melhores conselhos de saúde
            <br /> para a tua vida!
          </p>
        </div>
        <animated.div style={appearFromBottom} className="btn-container">
          <Button size="lg" onClick={() => history.push('/login')}>
            Entrar
          </Button>
          <p>
            Ainda não é cliente? <Link to="/">Saiba mais</Link>
          </p>
        </animated.div>
      </Section>
    </Main>
  );
};
