export type SvgEl = React.HTMLAttributes<HTMLOrSVGElement>;

export const IconBonusOutline: React.FC<SvgEl> = (props: SvgEl) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 99.3 114.9" {...props}>
      <path
        className="reflex"
        d="M83 61.2a1 1 0 00-1.1-.2.9.9 0 00-.4.2 1 1 0 00-.2.7 1 1 0 000 .4 1 1 0 00.6.5 1 1 0 00.4 0 1 1 0 00.7-.2 1 1 0 00.2-.3 1 1 0 000-.4 1 1 0 00-.2-.7zm-3.7-.8L60 57.6l-7.5-15.1a1 1 0 00-1.3-.5 1 1 0 00-.4 1.4l7.9 16 20.4 3h.2a1 1 0 00.1-2z"
      />
      <path
        className="icon"
        d="M49.6 22.7l14.7 29.8 32.9 4.7-23.8 23.2 5.6 32.7-29.3-15.4L20.3 113l5.6-32.7L2.1 57.2 35 52.5l14.6-29.8zm0-7.5V0M34.8 27.5L28 17.9m36.1 9.6l6.7-9.6"
      />
    </svg>
  );
};
