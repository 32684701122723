import { Button } from 'components';
import { format, formatISO, sub } from 'date-fns';
import PTLocale from 'date-fns/locale/pt';
import { useAuth } from 'hooks/useAuth';
import { useCallback, useEffect, useRef, useState } from 'react';
import { FiChevronLeft, FiChevronRight, FiPlus } from 'react-icons/fi';
import { colors, userActivity } from 'static';
import { UserActivityData } from 'types';
import { convertActivityDuration, convertActivityName } from 'utils';

import { LogSelect } from './LogSelect';
import { Card } from './styles';

export const ActivityTrackerForm: React.FC = () => {
  const now = new Date();
  const { user } = useAuth();
  const { oceanBlue300, white } = colors;
  const [isFlipped, setIsFlipped] = useState(false);
  const [isProcessing, setIsProcessing] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [isToday, setIsToday] = useState(false);
  const [currentDate, setCurrentDate] = useState(now);
  const [userActivities, setUserActivities] = useState<UserActivityData[]>(
    [] as UserActivityData[]
  );
  const [userCurrentActivity, setUserCurrentActivity] = useState<UserActivityData | undefined>(
    undefined
  );
  const activitySelectRef = useRef<HTMLSelectElement>(null);
  const durationSelectRef = useRef<HTMLSelectElement>(null);
  const pointsFieldRef = useRef<HTMLInputElement>(null);
  const score = '12';
  const activityOptions = [
    { label: 'Passeio de bicicleta', value: 'bike' },
    { label: 'Caminhada', value: 'walk' },
    { label: 'Corrida', value: 'run' },
    { label: 'Natação', value: 'swim' },
    { label: 'Surf', value: 'surf' }
  ];
  const durationOptions = [
    { label: '5 a 15 min', value: '15' },
    { label: '15 a 30 min', value: '30' },
    { label: '30 a 45 min', value: '45' },
    { label: '45 a 60 min', value: '60' },
    { label: '60 a 75 min', value: '75' }
  ];

  useEffect(() => {
    setUserActivities(userActivity.filter(({ userId }) => userId === user.id));
  }, []);

  useEffect(() => {
    setIsToday(currentDate.getDate() === now.getDate());
  }, [currentDate]);

  useEffect(() => {
    setSubmitted(false);
    setIsProcessing('');
    console.log({ submitted });
  }, []);

  const handleDateNav = useCallback((action: 'add' | 'remove') => {
    switch (action) {
      case 'add':
        setCurrentDate(new Date(currentDate.setDate(currentDate.getDate() + 1)));
        break;
      case 'remove':
        setCurrentDate(new Date(currentDate.setDate(currentDate.getDate() - 1)));
        break;

      default:
        setCurrentDate(currentDate);
        break;
    }
  }, []);

  const getCurrentActivity = useCallback(() => {
    setUserCurrentActivity(
      userActivities
        .filter(
          ({ date }) =>
            formatISO(new Date(date), { representation: 'date' }) ===
            formatISO(new Date(currentDate), { representation: 'date' })
        )
        .shift()
    );
  }, [userActivities]);

  const handleFormSubmit = useCallback(() => {
    if (activitySelectRef?.current?.value !== '' && durationSelectRef?.current?.value !== '') {
      setSubmitted(true);
      setIsFlipped(false);
    }
  }, []);

  const handlePointsAttribution = useCallback(() => {
    const activityValue = activitySelectRef?.current?.value;
    const durationValue = durationSelectRef?.current?.value;

    if (activityValue !== '' && durationValue !== '') {
      setIsProcessing('A calcular...');
      setTimeout(() => {
        setIsProcessing(score);
      }, 2000);
    }
  }, []);

  return (
    <Card className={`${isFlipped ? 'flipped' : ''}`}>
      <div className="card-front card-face">
        {userCurrentActivity ? (
          <div className="card-body">
            <span className="score">
              {userCurrentActivity.score} ponto{userCurrentActivity.score !== 1 && 's'}
            </span>
            <img
              className="icon"
              src={`/assets/icons/activity/i-${userCurrentActivity.activity}.svg`}
              alt={convertActivityName(userCurrentActivity.activity)}
            />
            <span className="activity">{convertActivityName(userCurrentActivity.activity)}</span>
            <span className="duration">
              {convertActivityDuration(userCurrentActivity.duration)}
            </span>
          </div>
        ) : (
          <div className="card-body">
            <button onClick={() => setIsFlipped(true)}>
              <FiPlus />
            </button>
            <span>adicione uma atividade</span>
          </div>
        )}
        <div className="card-footer">
          <button
            disabled={currentDate < sub(new Date(now), { days: 7 })}
            onClick={() => {
              handleDateNav('remove');
              getCurrentActivity();
            }}>
            <FiChevronLeft />
          </button>
          <span className="date-container">
            <span className="weekday">
              {format(currentDate, 'EEEE', {
                locale: PTLocale
              })}
            </span>
            <span className="date">
              {format(currentDate, 'd MMM yyyy', {
                locale: PTLocale
              })}
            </span>
          </span>
          <button
            disabled={isToday}
            onClick={() => {
              handleDateNav('add');
              getCurrentActivity();
            }}>
            <FiChevronRight />
          </button>
        </div>
      </div>
      <div className="card-back card-face">
        <div className="card-body">
          <form>
            <LogSelect
              selectRef={activitySelectRef}
              id="activity"
              label="Atividade"
              options={activityOptions}
              onChangeCallBack={handlePointsAttribution}
            />
            <LogSelect
              selectRef={durationSelectRef}
              id="duration"
              label="Duração"
              options={durationOptions}
              onChangeCallBack={handlePointsAttribution}
            />
            <span className="field-container">
              <label htmlFor="points">Pontos conquistados</label>
              <input
                ref={pointsFieldRef}
                type="text"
                name="points"
                id="points"
                placeholder={isProcessing}
                disabled
              />
            </span>
            <Button
              size="xs"
              textColor={white}
              backgroundColor={oceanBlue300}
              onClick={handleFormSubmit}
              className="btn-submit"
              type="button">
              adicionar
            </Button>
          </form>
        </div>
      </div>
    </Card>
  );
};
