export type SvgEl = React.HTMLAttributes<HTMLOrSVGElement>;

export const IconMovementOutline: React.FC<SvgEl> = (props: SvgEl) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 93 92" {...props}>
      <path
        className="reflex"
        d="M85.37 73.33a1 1 0 00-1.26.77l-.07.3a1 1 0 00.76 1.27 1.15 1.15 0 00.26 0 1.05 1.05 0 001-.8l.07-.32a1 1 0 00-.76-1.22zm.89-10.12c-3-12.66-10-21.71-37-30.45a1 1 0 00-1.31.67 1 1 0 00.67 1.32c26 8.44 32.75 17 35.6 28.95a21.76 21.76 0 01.36 7.83 1 1 0 00.9 1.18h.13a1.05 1.05 0 001-.91 23.75 23.75 0 00-.33-8.59z"
      />
      <path
        className="icon"
        d="M91.29 61.38c-3.39-14.26-11.3-24.45-41.68-34.3-29-9.39-46-25.63-46.21-25.79a1.06 1.06 0 00-1.71.39c-.31.84-7 20.25 28.4 38.77-2.83-.23-8.28-1.6-18.19-6.84a1 1 0 00-1 0 1.06 1.06 0 00-.48.94 25 25 0 005.45 12.77c4.25 5.43 12.69 12 29.08 13.94-2.68.57-7.22.71-14.51-1.11a1 1 0 00-.9.2 1 1 0 00-.39.85 16.55 16.55 0 002.39 7.29c2.06 3.42 6.34 7.77 15.06 9.05 2.86.42 5.55.73 8.15 1 12 1.37 20.63 2.37 26.36 11.88a1 1 0 00.8.51H82a1.08 1.08 0 00.79-.35c.44-.5 12.15-13.92 8.5-29.2z"
      />
    </svg>
  );
};
