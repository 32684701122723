import { IconPhone } from 'assets/icons';
import { Cover, Header, Main } from 'components';
import React, { useEffect, useState } from 'react';
import { services } from 'static';
import { CoverData } from 'types';

import { PhoneButton, Section, SpecsSection } from './styles';

export const OnlineAppointment: React.FC = () => {
  const [coverContent, setCoverContent] = useState<CoverData>({} as CoverData);

  useEffect(() => {
    setCoverContent(
      services.filter(({ slug }) => slug === '/saude/consulta').shift()?.cover || ({} as CoverData)
    );
  }, []);

  return (
    <>
      <Header />
      <Cover {...coverContent} />
      <Main>
        <Section>
          <span className="sub-title">contacto</span>
          <p>Exclusivo para a utilização deste serviço</p>
          <PhoneButton>
            <IconPhone />
            <a href="tel:217225693">21 722 5693</a>
          </PhoneButton>
        </Section>
        <SpecsSection>
          <h2>Detalhes do Serviço</h2>
          <p>
            Urgentemente de espolinhar-se na relva, beber «no fio dos regatos, e dormir balançada
            num ramo de castanheiro. O solicito «Baptiste que me remeta amanhã pelo ónibus a mala
            com que eu não quis «sobrecarregar a tropia do Mulato. Eu demoro-me apenas três ou
            quatro «dias. O tempo de cavaquear um bocado com o Absoluto no alto dos «Capuchos, e ver
            o que estão fazendo os miosótis junto à meiga fonte dos Amores...»
          </p>
          <h2>Inclui:</h2>
          <p>
            Arrastou Carlos: e pelo Chiado abaixo falou-lhe logo do Dâmaso. Não tornara a ver essa
            flor? Pois essa flor andava apregoando por toda a parte que o Maia, depois do caso do
            Chiado, lhe dera por um amigo explicações humildes, covardes... Terrível, aquele Dâmaso!
            Tinha figura, interior, e natureza de pela! Com quanto mais força se atirava ao chão,
            mais ele ressaltava para o ar, triunfante!...
          </p>
        </SpecsSection>
      </Main>
    </>
  );
};
