import { Header, HighlightsSection, Main, ScoreSection, ServicesCards } from 'components';
import React, { useCallback, useEffect, useState } from 'react';
import { notifications, services } from 'static';
import { ServicesData } from 'types';

export const Home: React.FC = () => {
  // const [notifications, setNotifications] = useState<NotificationsData[]>(
  //   [] as NotificationsData[]
  // );
  const [hasUnreadNotifications, setHasUnreadNotifications] = useState(false);
  const [highlightedServices, setHighlightedServices] = useState<ServicesData[]>(
    [] as ServicesData[]
  );

  const getNotifications = useCallback(() => {
    // setNotifications(notificationList);
    const unreadNotifications = notifications.filter(({ status }) => status === 'unread');
    if (unreadNotifications.length > 0) setHasUnreadNotifications(true);
  }, []);

  useEffect(getNotifications, []);

  useEffect(() => {
    document.body.classList.remove('purple-bg');
  }, []);

  useEffect(() => {
    setHighlightedServices(services.filter(({ card }) => card.home_highlight && card.show));
  }, []);

  return (
    <>
      <Header hasUnreadNotifications={hasUnreadNotifications} />
      <Main>
        <HighlightsSection />
        <ScoreSection />
        <ServicesCards
          services={highlightedServices}
          title="A pensar em si"
          description="Selecione um serviço e prepare-se para conquistar mais pontos"
        />
      </Main>
    </>
  );
};
