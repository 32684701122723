import { animated } from 'react-spring';
import styled from 'styled-components';

export const Profile = styled(animated.div)`
  position: fixed;
  width: 100%;
  top: 0;
  right: -120%;
  padding: 2rem 3.2rem;
  z-index: 2;
  display: grid;
  background: var(--c-night-blue-900);
  grid-template-columns: 6rem calc(100% - 6rem - 1.6rem - 8vw);
  gap: 1.6rem;
  transition: right 200ms ease;

  &.open {
    right: 0;
  }

  .avatar-container {
    position: relative;
    border-radius: 50%;
    border: 2px solid var(--c-white);

    img {
      width: 6rem;
      height: 6rem;
      object-fit: cover;
      border-radius: 50%;
    }

    .avatar-icon {
      fill: var(--c-old-navy-blue-800);
    }

    // notification badge
    &::after {
      content: '';
      position: absolute;
      top: 0.5rem;
      right: 0;
      width: 1rem;
      height: 1rem;
      border-radius: 50%;
      background: red;
      border: 2px solid var(--c-white);
      transform: scale(0);
      transition: transform 200ms ease 1000ms, border 600ms ease;
    }

    &.new {
      &::after {
        transform: scale(1);
      }
    }
  }

  & > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    h2 {
      font-size: 2rem;
      font-weight: var(--f-light);
      margin-bottom: 0;
      color: var(--c-old-navy-blue-800);
    }

    .points {
      display: flex;
      flex-direction: column;

      text-decoration: none;

      strong {
        font-size: 2rem;
        line-height: 1;
        color: var(--c-old-navy-blue-800);
        font-weight: var(--f-semi-bold);
        margin-bottom: 0;
        letter-spacing: 0.05rem;

        span {
          font-size: 0.6em;
          text-transform: uppercase;
          font-weight: bolder;
          margin-left: 0.6rem;
        }
      }

      & > span {
        color: var(--c-old-navy-blue-800);
        font-size: 1.4rem;
      }
    }
  }
`;
