import { animated } from 'react-spring';
import styled from 'styled-components';

export const MainSection = styled(animated.main)`
  width: 100%;
  overflow: hidden;
  padding-bottom: 8rem;
  background: var(--c-silver-grey-100);

  //dev
  max-width: 500px;
  position: relative;
  margin: 0 auto;
`;
