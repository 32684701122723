import styled from 'styled-components';

export const Cover = styled.div`
  padding: 9rem 0 3rem;
  position: relative;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background: radial-gradient(var(--c-movement-1), var(--c-movement-2));
  background-position: 0% 50%;
  background-size: 400% 400%;

  animation: Gradient 10000ms ease infinite;
  animation-play-state: running;

  &::after {
    content: '';
    position: absolute;
    bottom: -270px;
    left: 50%;
    transform: translateX(-50%) skewY(352deg);
    width: calc(100% + 20%);
    height: 360px;
    background: var(--c-silver-grey-100);
  }

  h1 {
    text-align: center;
    color: var(--c-white);
    font-size: 3.2rem;
    width: 90%;
  }
`;

export const Rating = styled.span`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 1.6rem;
    height: 1.6rem;
    fill: var(--c-white);
    opacity: 0.5;

    & + svg {
      margin-left: 1rem;
    }

    &.on {
      opacity: 1;
    }
  }
`;

export const CoverImage = styled.img`
  width: 100%;
  height: 32rem;
  object-fit: cover;
  margin-top: 1rem;
  clip-path: polygon(0 14.5%, 100% 0, 102% 85.5%, 0 100%);
`;

export const ProfileHeader = styled.div`
  margin-top: -12rem;
  display: grid;
  align-items: end;
  justify-content: space-between;
  grid-template-columns: 1fr 12rem 1fr;
  gap: 0.8rem;
  z-index: 1;
  width: 100%;
  padding: 0 2rem;
`;

export const ProfileImage = styled.img`
  width: 12rem;
  height: 12rem;
  border-radius: 6rem;
  object-fit: cover;
`;

export const Languages = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.4rem;

  span {
    color: var(--c-movement-2);
    border: 1px solid var(--c-movement-2);
    line-height: 0;
    width: 2.2rem;
    height: 2.2rem;
    border-radius: 0.6rem;
    font-size: 1.2rem;
    font-weight: var(--f-semi-bold);

    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (min-width: 375px) {
      width: 2.8rem;
      height: 2.8rem;
      font-size: 1.3rem;
      border-radius: 0.8rem;
    }
  }
`;

export const SocialMedia = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.4rem;

  a {
    width: 2.2rem;
    height: 2.2rem;
    border: none;
    background: transparent;

    svg {
      width: 100%;
      height: 100%;
      fill: var(--c-nordic-blue-200);
      transition: fill 300ms ease;
    }

    &:first-of-type {
      margin-left: auto;
    }

    &:hover {
      svg {
        fill: var(--c-ocean-blue-300);
      }
    }
  }
`;

export const Profile = styled.div`
  padding: 0 2rem 2rem;

  .locations {
    margin-bottom: 0.4rem;
    span {
      font-size: 1.4rem;
      white-space: pre-wrap;
    }
  }

  .platforms span {
    text-transform: uppercase;
    font-size: 1.3rem;
    font-weight: var(--f-semi-bold);
    white-space: pre-wrap;
  }

  .locations,
  .platforms {
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      text-align: center;
    }
  }

  .bio {
    margin-top: 4.2rem;
    text-align: center;
  }
`;

export const Tags = styled.div`
  width: 100%;
  margin-top: 4.2rem;

  h2 {
    text-align: center;
  }

  .tag-container {
    margin-top: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 0.8rem;
  }
`;

export const PriceList = styled.div`
  background: var(--c-night-blue-900);
  padding: 2rem;
  margin-top: 4.6rem;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    h2 {
      color: var(--c-phantom-grey-200);
    }
  }

  .slider {
    padding: 4rem 0;

    .swiper-pagination .swiper-pagination-bullet {
      background: var(--c-old-navy-blue-800);
      opacity: 1;

      &.swiper-pagination-bullet-active {
        background: var(--c-ocean-blue-300);
      }
    }

    .card {
      background: var(--c-ocean-blue-300);
      padding: 1.6rem;
      border-radius: 2rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;

      & > * {
        color: var(--c-white);
      }

      h3 {
        font-weight: var(--f-light);
        font-size: 3.4rem;
        color: var(--c-white);
        margin-bottom: 0.4rem;
        line-height: 1;

        span {
          font-size: 4.2rem;
        }
      }

      strong {
        text-transform: uppercase;
        letter-spacing: 0.1rem;
        font-weight: var(--f-semi-bold);
      }

      ul {
        list-style: none;
        margin-bottom: 2rem;

        li {
          text-align: center;
          font-size: 1.3rem;
          line-height: 1.4;
        }
      }

      button {
        background: var(--c-nordic-blue-200);
        border: none;
        border-radius: 1rem;
        padding: 1rem 1.6rem;
        font-weight: var(--f-semi-bold);
        line-height: 1;
        transition: background-color 300ms ease, color 300ms ease;

        &:hover {
          background: var(--c-white);
          color: var(--c-ocean-blue-300);
        }
      }
    }
  }

  .conditions {
    font-size: 1.4rem;
    text-align: center;
    color: var(--c-old-navy-blue-800);
  }
`;

export const ContactList = styled.div`
  padding: 4.2rem 2rem;

  .container {
    margin-top: 3rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    button {
      width: 3.2rem;
      height: 3.2rem;
      background: transparent;
      border: none;

      & + button {
        margin-left: 3.2rem;
      }

      svg {
        width: 100%;
        height: 100%;
        fill: var(--c-ocean-blue-300);
      }
    }
  }
`;

export const CommentsList = styled.section`
  padding: 4.2rem 2rem;
`;
