import { AreaType } from 'types';

export const areaToEN = (area: AreaType): string => {
  switch (area) {
    case 'movimento':
      return 'movement';

    case 'nutrição':
      return 'nutrition';

    case 'saúde':
      return 'health';

    case 'bonus':
      return 'bonus';

    default:
      return '';
  }
};
