import { Cover, FAQsAccordionSection, GuruFormSection, Header, Main, SearchForm } from 'components';
import React, { useEffect, useState } from 'react';
import { colors, faqs, services } from 'static';
import { CoverData, FaqsData } from 'types';

import { Section } from './styles';

export const FitGuru: React.FC = () => {
  const [coverContent, setCoverContent] = useState<CoverData>({} as CoverData);
  const [allMovementFAQs, setAllMovementFAQs] = useState<FaqsData[]>([] as FaqsData[]);
  const [movementFAQs, setMovementFAQs] = useState<{ faqs?: FaqsData[] | undefined }>({ faqs: [] });

  const { oceanBlue300, white } = colors;

  useEffect(() => {
    setCoverContent(
      services.filter(({ slug }) => slug === '/movimento/guru').shift()?.cover || ({} as CoverData)
    );
  }, []);

  useEffect(() => {
    setAllMovementFAQs(faqs.filter(({ area }) => area === 'movimento'));
    setMovementFAQs({ faqs: faqs.filter(({ area }) => area === 'movimento') });
  }, []);

  return (
    <>
      <Header />
      <Main>
        <Cover {...coverContent} />
        <Section>
          <p>Planos de treino, recuperação de lesões, treino intermitente... o guru ajuda</p>
          <SearchForm
            label="Pesquisar FAQ’s"
            content={{ faqs: allMovementFAQs }}
            setCurrentContent={setMovementFAQs}
            area="faqs"
          />
        </Section>
        <FAQsAccordionSection questions={movementFAQs.faqs || []} />
        <GuruFormSection
          button={{ backgroundColor: oceanBlue300, textColor: white }}
          title={{ color: oceanBlue300 }}
        />
      </Main>
    </>
  );
};
