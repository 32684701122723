import styled from 'styled-components';

export const Section = styled.section`
  background: var(--c-silver-grey-100);
  padding: 3.6rem 2rem;

  figure {
    width: 100%;

    img {
      width: 20rem;
      height: 20rem;
      margin: 2rem auto;
      border-radius: 0.8rem;
      object-fit: cover;
    }

    figcaption {
      text-align: center;
    }
  }

  a {
    width: 60%;
    background: var(--c-white);
    color: var(--c-night-blue-900);
    margin: 1rem auto;
    display: block;
    text-transform: uppercase;
    font-weight: var(--f-semi-bold);
    text-align: center;
    text-decoration: none;
    line-height: 1;
    padding: 1rem;
    border-radius: 1.6rem;
  }
`;

export const Card = styled.div`
  background: var(--c-white);
  width: 100%;
  max-width: 38rem;
  min-height: 18rem;
  margin: 2rem auto;
  border-radius: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.6rem;
  overflow: hidden;

  &.activities {
    padding: 1.6rem;
    display: grid;
    grid-template-rows: auto 3.6rem;
    gap: 0.8rem;
    box-shadow: 0 0 1.6rem 0px rgba(0, 0, 0, 0.08);

    .card-body {
      display: grid;
      grid-template-columns: 1fr 1.5fr;
      gap: 1.6rem;
      padding: 0;

      img {
        width: 100%;
        height: 100%;
        max-height: 12rem;
        object-fit: cover;
        border-radius: 1rem;
      }

      h3 {
        font-size: 1.8rem;
        font-weight: var(--f-semi-bold);
      }

      p {
        font-size: 1.4rem;
      }

      a {
        font-size: 1.4rem;
        padding: 0;
        width: unset;
        margin: unset;
        margin: 2rem 0 0 auto;
        font-weight: var(--f-semi-bold);
        letter-spacing: 0.1rem;
        color: var(--c-nutrition-2);
        text-align: left;
      }
    }

    .card-footer {
      display: grid;
      grid-template-columns: 1fr 1.5fr;
      align-items: center;
      justify-items: center;

      .points {
        font-weight: var(--f-semi-bold);
      }

      a {
        width: unset;
        margin: unset;
        margin-left: auto;
        background: var(--c-la-vanda-200);
        color: var(--c-white);
        font-size: 1.3rem;
        letter-spacing: 0.1rem;
      }
    }
  }
`;
