import { ServicesData } from 'types';

export const services: ServicesData[] = [
  // SAÚDE
  {
    id: '1',
    area: 'saúde',
    slug: '/saude/inicio',
    label: 'Início',
    card: {
      show: false,
      home_highlight: false,
      title: '',
      description: '',
      backgroundImage: '',
      backgroundImage_2x: '',
      hasGradientBackground: true
    },
    cover: {
      title: 'saúde',
      description: 'sente-te bem',
      image: '/assets/img/avatar/avatar-health.jpg',
      image_2x: '/assets/img/avatar/avatar-health@2x.jpg'
    }
  },
  {
    id: '2',
    area: 'saúde',
    slug: '/saude/cartao',
    label: 'Cartão virtual',
    card: {
      show: true,
      home_highlight: true,
      title: 'Use o seu cartão de cliente',
      description: 'Use o seu cartão de cliente',
      backgroundImage: '/media/img/services/health-virtual-card.jpg',
      backgroundImage_2x: '/media/img/services/health-virtual-card@2x.jpg',
      hasGradientBackground: true
    },
    cover: {
      title: 'Cartão Virtual',
      description: 'seguros',
      image: '',
      image_2x: '',
      hasCard: true
    }
  },
  {
    id: '3',
    area: 'saúde',
    slug: '/saude/consulta',
    label: 'Marcar consulta',
    card: {
      show: true,
      home_highlight: true,
      title: 'Marque<br />uma<br />consulta',
      description: 'Uma abrangente rede de médicos está à sua espera',
      backgroundImage: '/media/img/services/health-appointment.jpg',
      backgroundImage_2x: '/media/img/services/health-appointment@2x.jpg',
      hasGradientBackground: true
    },
    cover: {
      title: 'Médico Online',
      description: 'serviço',
      image: '/assets/img/avatar/avatar-health-doctor.jpg',
      image_2x: '/assets/img/avatar/avatar-health-doctor@2x.jpg'
    }
  },
  {
    id: '4',
    area: 'saúde',
    slug: '/saude/check-up',
    label: 'Faça um check-up',
    card: {
      show: true,
      home_highlight: false,
      title: 'Faça um<br />check-up',
      description: 'Monitorize os seus sinais vitais e a condição do seu corpo',
      backgroundImage: '/media/img/services/health-check-up.jpg',
      backgroundImage_2x: '/media/img/services/health-check-up@2x.jpg',
      hasGradientBackground: true
    },
    cover: {
      title: 'Saúde',
      description: 'Faça um check-up',
      image: '/assets/img/avatar/avatar-health-check-up.jpg',
      image_2x: '/assets/img/avatar/avatar-health-check-up@2x.jpg'
    }
  },
  {
    id: '5',
    area: 'saúde',
    slug: '/saude/sintomas',
    label: 'Avaliar sintomas',
    card: {
      show: true,
      home_highlight: false,
      title: 'Avalie<br />os seus<br />sintomas',
      description: 'Desconfia de algo? Comece com um auto-diagnóstico.',
      backgroundImage: '/media/img/services/health-symptoms-test.jpg',
      backgroundImage_2x: '/media/img/services/health-symptoms-test@2x.jpg',
      hasGradientBackground: true
    },
    cover: {
      title: 'Avaliar sintomas',
      description: 'Saúde',
      image: '/assets/img/avatar/avatar-health-symptoms-check.jpg',
      image_2x: '/assets/img/avatar/avatar-health-symptoms-check@2x.jpg'
    }
  },
  // MOVIMENTO
  {
    id: '6',
    area: 'movimento',
    slug: '/movimento/inicio',
    label: 'Início',
    card: {
      show: false,
      home_highlight: false,
      title: '',
      description: '',
      backgroundImage: '',
      backgroundImage_2x: '',
      hasGradientBackground: true
    },
    cover: {
      title: 'movimento',
      description: 'mexe com o teu dia',
      image: '/assets/img/avatar/avatar-movement.jpg',
      image_2x: '/assets/img/avatar/avatar-movement@2x.jpg'
    }
  },
  {
    id: '7',
    area: 'movimento',
    slug: '/movimento/pt',
    label: 'Agendar um PT',
    card: {
      show: true,
      home_highlight: true,
      title: 'Marque<br />uma sessão<br />de PT',
      description: 'Melhore a sua performance com um treino personalizado',
      backgroundImage: '/media/img/services/movement-pt-booking.jpg',
      backgroundImage_2x: '/media/img/services/movement-pt-booking@2x.jpg',
      hasGradientBackground: true
    },
    cover: {
      title: 'Agendar um PT',
      description: 'Treino Pessoal',
      image: '/assets/img/avatar/avatar-movement-pt.jpg',
      image_2x: '/assets/img/avatar/avatar-movement-pt@2x.jpg'
    }
  },
  {
    id: '8',
    area: 'movimento',
    slug: '/movimento/aulas/direto',
    label: 'Aulas em direto',
    card: {
      show: true,
      home_highlight: false,
      title: 'Participe<br />numa aula<br />em direto',
      description: 'Treine com o/a instrutor/a do outro lado do ecrã',
      backgroundImage: '/media/img/services/movement-live-classes.jpg',
      backgroundImage_2x: '/media/img/services/movement-live-classes@2x.jpg',
      hasGradientBackground: true
    },
    cover: {
      title: 'Vamos treinar?',
      description: 'aulas em direto',
      image: '',
      image_2x: ''
    }
  },
  {
    id: '9',
    area: 'movimento',
    slug: '/movimento/videos',
    label: 'Aulas on-demand',
    card: {
      show: true,
      home_highlight: true,
      title: 'Treine<br />com aulas<br />on-demand',
      description: 'Onde estiver, basta escolher um vídeo e começar a treinar',
      backgroundImage: '/media/img/services/movement-on-demand-classes.jpg',
      backgroundImage_2x: '/media/img/services/movement-on-demand-classes@2x.jpg',
      hasGradientBackground: true
    },
    cover: {
      title: 'Treino do dia',
      description: 'sessão online',
      image: '/assets/img/avatar/avatar-movement.jpg',
      image_2x: '/assets/img/avatar/avatar-movement@2x.jpg'
    }
  },
  {
    id: '10',
    area: 'movimento',
    slug: '/movimento/treino',
    label: 'Treino',
    card: {
      show: true,
      home_highlight: false,
      title: 'Treine e registe o seu movimento',
      description: 'Faça check-in no seu clube e registe treinos',
      backgroundImage: '/media/img/services/movement-club-usage.jpg',
      backgroundImage_2x: '/media/img/services/movement-club-usage@2x.jpg',
      hasGradientBackground: true
    },
    cover: {
      title: 'treino',
      description: 'Gym check in',
      image: '',
      image_2x: '',
      hasQRCode: true
    }
  },
  {
    id: '11',
    area: 'movimento',
    slug: '/movimento/guru',
    label: 'Pergunte ao Fit Guru',
    card: {
      show: true,
      home_highlight: false,
      title: 'Dúvidas?<br />Pergunte ao<br />Fit Guru',
      description: 'Uma equipa de experts pronta para responder.',
      backgroundImage: '/media/img/services/movement-fit-guru.jpg',
      backgroundImage_2x: '/media/img/services/movement-fit-guru@2x.jpg',
      hasGradientBackground: true
    },
    cover: {
      title: 'Pergunte ao Guru',
      description: 'dúvidas',
      image: '/assets/img/avatar/avatar-movement-guru.jpg',
      image_2x: '/assets/img/avatar/avatar-movement-guru@2x.jpg'
    }
  },
  // NUTRIÇÃO
  {
    id: '12',
    area: 'nutrição',
    slug: '/nutricao/inicio',
    label: 'Início',
    card: {
      show: false,
      home_highlight: false,
      title: '',
      description: '',
      backgroundImage: '',
      backgroundImage_2x: '',
      hasGradientBackground: true
    },
    cover: {
      title: 'nutrição',
      description: 'coma saudável',
      image: '/assets/img/avatar/avatar-nutrition.jpg',
      image_2x: '/assets/img/avatar/avatar-nutrition@2x.jpg'
    }
  },
  {
    id: '13',
    area: 'nutrição',
    slug: '/nutricao/consultas',
    label: 'Marcar consulta',
    card: {
      show: true,
      home_highlight: true,
      title: 'Marque uma<br />consulta',
      description: 'Personalize a sua dieta com a ajuda de um/a nutricionista',
      backgroundImage: '/media/img/services/nutrition-appointment.jpg',
      backgroundImage_2x: '/media/img/services/nutrition-appointment@2x.jpg',
      hasGradientBackground: true
    },
    cover: {
      title: 'Nutrição',
      description: 'Marque uma consulta',
      image: '/assets/img/avatar/avatar-nutrition-booking.jpg',
      image_2x: '/assets/img/avatar/avatar-nutrition-booking@2x.jpg'
    }
  },
  {
    id: '14',
    area: 'nutrição',
    slug: '/nutricao/plano',
    label: 'Aceder ao plano',
    card: {
      show: true,
      home_highlight: false,
      title: 'Aceda ao<br />seu plano',
      description: 'O guia diário para alcançar a sua silhueta perfeita.',
      backgroundImage: '/media/img/services/nutrition-plan.jpg',
      backgroundImage_2x: '/media/img/services/nutrition-plan@2x.jpg',
      hasGradientBackground: true
    },
    cover: {
      title: 'Aceda ao seu plano',
      description: 'Nutrição',
      image: '/assets/img/avatar/avatar-nutrition-plan.jpg',
      image_2x: '/assets/img/avatar/avatar-nutrition-plan@2x.jpg'
    }
  },
  {
    id: '15',
    area: 'nutrição',
    slug: '/nutricao/guru',
    label: 'Pergunte ao Guru',
    card: {
      show: true,
      home_highlight: true,
      title: 'Dúvidas?<br />Pergunte ao<br />Guru',
      description: 'Uma equipa de experts pronta para responder.',
      backgroundImage: '/media/img/services/nutrition-guru.jpg',
      backgroundImage_2x: '/media/img/services/nutrition-guru@2x.jpg',
      hasGradientBackground: true
    },
    cover: {
      title: 'Pergunte ao Guru',
      description: 'dúvidas',
      image: '/assets/img/avatar/avatar-nutrition-guru.jpg',
      image_2x: '/assets/img/avatar/avatar-nutrition-guru@2x.jpg'
    }
  }
];
