import styled from 'styled-components';

export const ActionSection = styled.section`
  background: var(--c-silver-grey-100);
  padding: 4.6rem 2rem 4.6rem;

  svg {
    width: 2rem;
    height: 2rem;
    fill: var(--c-smoke-grey-400);
    margin: 0 auto 1.2rem;
    display: block;
  }

  h2 {
    color: var(--c-alaska-salmon-300);
    text-align: center;
    font-size: 2.8rem;
    font-weight: var(--f-light);
  }

  .question {
    margin: 0 auto;
  }
`;

export const GuruSection = styled.section`
  background: var(--c-silver-grey-100);
  padding: 4.6rem 2rem 4.6rem;

  h2 {
    text-align: center;
  }

  form {
    margin-top: 2rem;
    width: 100%;

    & > div + * {
      margin-top: 2rem;
    }

    label {
      font-size: 1.4rem;
      line-height: 1;
      margin-bottom: 1rem;
      display: block;
      letter-spacing: 0.04rem;

      a {
        color: var(--c-night-blue-900);
      }
    }

    textarea {
      border: none;
      border-radius: 1rem;
      width: 100%;
      height: 13rem;
      padding: 1rem;
      resize: none;
    }
    .char-count {
      font-size: 1.2rem;
      display: block;
      text-align: right;
      letter-spacing: 0.01rem;
    }

    .checkbox-field {
      display: flex;
      gap: 0.8rem;
    }

    button {
      display: block;
      margin: 1rem 0 0 auto;
    }
  }

  .submit-message {
    p {
      text-align: center;
      margin: 2rem 0;
    }
  }
`;
