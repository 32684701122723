import { useLocation } from 'hooks/useLocation';
import QRCode from 'qrcode.react';
import { useEffect, useState } from 'react';
import { animated, useSpring } from 'react-spring';
import { colors } from 'static';
import { CoverData } from 'types';

import { slowDropConfig } from './animations';
import { Section } from './styles';

interface CoverProps extends CoverData {
  QRCodeValue?: string | null;
}

export const Cover: React.FC<CoverProps> = ({
  description,
  title,
  hasCard,
  hasQRCode,
  image,
  image_2x,
  QRCodeValue
}: CoverProps) => {
  const { location } = useLocation();
  const { white, nightBlue900 } = colors;
  const [classArea, setClassArea] = useState('');
  const slowDropAnimation = useSpring(slowDropConfig);

  useEffect(() => {
    switch (location?.slice(1).split('/').shift()) {
      case 'movimento':
        setClassArea('movement');
        break;
      case 'nutricao':
        setClassArea('nutrition');
        break;
      case 'saude':
        setClassArea('health');
        break;
      case 'bonus':
        setClassArea('bonus');
        break;

      default:
        setClassArea('');
        break;
    }
  }, [location]);

  return (
    <Section className={`${classArea} ${hasCard ? 'card' : ''}`}>
      <span className="sub-title">{description}</span>
      <h1>{title}</h1>
      {image && (
        <animated.img
          srcSet={`${image_2x} 2x, ${image}`}
          src={image}
          alt={title}
          style={slowDropAnimation}
        />
      )}
      <animated.div
        className={`code-container ${!QRCodeValue ? 'no-value' : ''}`}
        style={slowDropAnimation}>
        {hasQRCode && QRCodeValue && (
          <QRCode
            value={QRCodeValue}
            size={160}
            bgColor={white}
            fgColor={nightBlue900}
            level="L"
            includeMargin={true}
            renderAs="svg"
          />
        )}
        {hasQRCode && !QRCodeValue && (
          <>
            <span>Indisponível</span>
            <QRCode
              value="fake"
              size={160}
              bgColor={white}
              fgColor={nightBlue900}
              level="L"
              includeMargin={true}
              renderAs="svg"
            />
          </>
        )}
      </animated.div>
    </Section>
  );
};
