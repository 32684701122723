import styled from 'styled-components';

export const Section = styled.section`
  background: var(--c-silver-grey-100);
  padding: 0 2rem 4.6rem;

  & > button {
    margin: -20rem auto 2rem;
  }

  .sub-title {
    color: var(--c-smoke-grey-400);
    font-weight: var(--f-semi-bold);
    display: block;
    text-align: center;
    margin-bottom: 1.4rem;
  }

  p {
    text-align: center;
    margin-bottom: 2rem;
  }
`;

export const SpecsSection = styled.section`
  background: var(--c-white);
  padding: 6.2rem 2rem;

  p + h2 {
    margin-top: 6.8rem;
  }
`;
