import styled from 'styled-components';

export const Section = styled.section`
  padding: 2rem 2rem 6rem 2rem;
  background: var(--c-silver-grey-100);

  &.search {
    padding: 0 2rem 3.6rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    p,
    .sub-title {
      color: var(--c-night-blue-900);
    }

    .sub-title {
      margin-bottom: 0.4rem;
    }

    p {
      font-weight: var(--f-semi-bold);
      margin-bottom: 3rem;
    }
  }

  h2 {
    font-size: 1.8rem;
    text-transform: uppercase;
    font-weight: var(--f-light);
    color: var(--c-ocean-blue-300);
    line-height: 0;
    display: inline-block;
    margin: 0;
    padding: 1.2rem 1rem;
    border: 1px solid var(--c-ocean-blue-300);
    border-radius: 0.6rem;
  }

  &.results {
    position: relative;

    button {
      position: absolute;
      top: 2rem;
      right: 1rem;
    }

    .result-card {
      background: var(--c-white);
      margin-top: 1.4rem;
      border-radius: 1rem;

      a {
        text-align: center;
        text-decoration: none;
        color: var(--c-night-blue-900);
        width: 100%;
        height: 100%;
        padding: 1rem;

        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
`;
