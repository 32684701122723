import styled from 'styled-components';

export const PlayerContainer = styled.div`
  padding-top: 8rem;
  background: var(--c-night-blue-900);
  height: 32rem;

  .play-button {
    border: none;
    position: relative;
    width: 100vw;
    height: 24rem;

    img {
      width: 100%;
      height: 24rem;
      object-fit: cover;
    }

    .icon {
      width: 8rem;
      height: 8rem;
      position: absolute;
      bottom: 50%;
      right: 50%;
      transform: translate(50%, 50%);

      fill: var(--c-silver-grey-100);
      stroke: var(--c-silver-grey-100);
      opacity: 0.9;
    }
  }
  .player {
    width: 100vw;
    height: 24rem;
    display: flex;
    align-items: center;
    justify-content: center;
    object-fit: cover;
    display: none;

    &.show {
      display: block;
    }
  }
`;

export const PrimaryData = styled.div`
  width: 100%;
  padding: 0.8rem;

  display: grid;
  grid-template-columns: 3rem repeat(4, 1fr);
  gap: 0.8rem;

  button {
    border: none;
    padding: 0;

    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 0;

    svg {
      background: var(--c-silver-grey-100);
      width: 3.6rem;
      height: 3.6rem;
      stroke: var(--c-phantom-grey-900);
    }
  }

  span {
    color: var(--c-phantom-grey-900);
    padding: 0.4rem;
    letter-spacing: 0.5px;
    font-size: 1.4rem;

    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      margin-right: 0.4rem;
      width: 1.8rem;
      height: 1.8rem;
    }
  }
`;

export const Details = styled.section`
  padding: 36px;
  height: 100%;
  overflow-y: scroll;

  .sub-title {
    color: var(--c-night-blue-900);
  }

  .description {
    margin: 1.6rem 0;
  }

  .details {
    padding: 1.6rem 0;

    line-height: 1.6;

    & + .details {
      margin-top: 0.4rem;
    }

    strong {
      letter-spacing: 0.5px;
      line-height: 2.4;
    }
  }

  h1 {
    font-size: 3.6rem;
    line-height: 1;
    text-transform: uppercase;
    margin: 0;
  }
`;

export const Tag = styled.span`
  font-size: 1.4rem;
  letter-spacing: 0.5px;
  background: var(--c-white);
  padding: 0.4rem 1.6rem;
  border-radius: 1.6rem;
  margin: 0.4rem;
  box-shadow: 1px 1px 0 #d8d8d8;
`;

export const Recommended = styled.section`
  margin-top: 3.6rem;

  h2 {
    font-size: 2.1rem;
    margin-bottom: 1rem;
  }

  .card-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0.8rem;

    .card {
      border-radius: 0.4rem;
      background: var(--c-white);
      overflow: hidden;

      display: grid;
      grid-template-rows: 82px 82px 36px;

      .card-header {
        width: 100%;
        height: 82px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .card-body {
        padding: 0.8rem;
      }

      .card-footer {
        button {
          height: 100%;
          padding: 0.4rem;
          text-decoration: none;
          color: var(--c-hi-blue);
          background: var(--c-white);
          border: none;
          width: 100%;

          display: flex;
          align-items: center;
          justify-content: center;

          &:hover {
            text-decoration: none;
          }
        }
      }
    }
  }
`;
