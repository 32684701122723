import styled from 'styled-components';

export const Card = styled.div`
  width: 100%;
  min-height: 35rem;
  border-radius: 2rem;
  background: var(--c-ocean-blue-300);
  margin-top: 3.4rem;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .card-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0.8rem;
    padding: 1.6rem;

    .current-date {
      text-align: center;
      font-size: 1.6rem;
      width: 100%;
      color: var(--c-white);
    }

    button {
      border: none;
      background: transparent;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 3rem;
      height: 3rem;

      &:disabled {
        opacity: 0;
      }

      svg {
        stroke: var(--c-white);
      }
    }
  }

  .card-body {
    padding: 2rem 1.6rem;

    .field-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      position: relative;

      & + .field-container {
        margin-top: 3rem;
      }

      label {
        position: absolute;
        top: -0.7rem;
        left: 50%;
        transform: translateX(-50%);
        background: var(--c-ocean-blue-300);
        font-size: 1.2rem;
        text-transform: uppercase;
        padding: 0 0.8rem;
        color: var(--c-white);
        letter-spacing: 0.1rem;
      }

      select {
        width: 100%;
        font-size: 1.6rem;
        line-height: 1;
        padding: 0.8rem;
        border: 1px solid var(--c-white);
        color: var(--c-white);
        background: transparent;
        height: 4rem;
        border-radius: 2rem;
        text-align: center;
        text-align-last: center;
        appearance: none;
        -webkit-appearance: none;

        option {
          text-align: left;
          letter-spacing: 0.05rem;
        }
      }

      input {
        width: 100%;
        padding: 1.6rem;
        background: transparent;
        border: none;
        text-align: center;
        font-size: 2.8rem;
        color: var(--c-white);

        &::placeholder {
          color: var(--c-white);
        }
      }
    }

    .btn-submit {
      display: block;
      margin: 0 auto;
      background: var(--c-nordic-blue-200);
      border: none;
      border-radius: 1rem;
      padding: 1rem 1.6rem;
      font-weight: var(--f-semi-bold);
      line-height: 1;
      color: var(--c-white);
      transition: background-color 300ms ease, color 300ms ease;

      &:hover {
        background: var(--c-white);
        color: var(--c-ocean-blue-300);
      }
    }
  }

  .card-success {
    padding: 2rem 1.6rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;

    .icon {
      background: var(--c-nordic-blue-200);
      stroke: var(--c-white);
      width: 10rem;
      height: 10rem;
      border-radius: 50%;
      padding: 2rem;
    }

    .title,
    .score {
      font-size: 2.8rem;
      color: var(--c-white);
    }
  }
`;
