import { LogoHi } from 'assets/vectors';
import React from 'react';

import { Message } from './styles';

export const DesktopMessage: React.FC = () => {
  return (
    <Message>
      <div className="container">
        <LogoHi className="logo" />
        <div>
          <h2>Bem-vindo/a ao Hi!</h2>
          <p>Por favor visite através do seu smartphone</p>
        </div>
      </div>
    </Message>
  );
};
