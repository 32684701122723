import React from 'react';
import { colors } from 'static';

import { Btn } from './styles';

interface ButtonComponentProps extends React.ComponentPropsWithoutRef<'button'> {
  size: 'xs' | 'md' | 'lg';
  textColor?: string;
  backgroundColor?: string;
}
const { white, nightBlue900 } = colors;

function ButtonComponent(
  {
    size,
    textColor = nightBlue900,
    backgroundColor = white,
    className,
    ...props
  }: ButtonComponentProps,
  ref: React.Ref<HTMLButtonElement>
) {
  return (
    <Btn
      ref={ref}
      {...props}
      className={`${className || ''} ${size}`}
      style={{ color: textColor, background: backgroundColor }}
    />
  );
}

export const Button = React.forwardRef(ButtonComponent);
