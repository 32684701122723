import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
`;

export const Button = styled.button`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;

  background: var(--c-night-blue-900);
  border: none;
  border-bottom: 1px solid var(--c-old-navy-blue-800);
  font-size: 3.6rem;
  color: var(--c-white);
  font-weight: var(--f-semi-bold);
  padding: 0.8rem;

  span {
    text-align: left;
    width: calc(100% - 2.8rem);
  }

  svg {
    width: 2.8rem;
    height: 2.8rem;
    transform: rotate(180deg);
    stroke: var(--c-old-navy-blue-800);
    transform: rotate(0);
    transition: transform 300ms ease;

    &.open {
      transform: rotate(180deg);
    }
  }
`;

export const Content = styled.div`
  overflow: hidden;
  padding: 0;
  border-bottom: 1px solid var(--c-old-navy-blue-800);
  transition: height 300ms ease, padding 300ms ease;

  &.open {
    padding: 1rem 0;
  }
`;
