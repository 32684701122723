import { VideoData } from 'pages/Private/Movement/VideoList/types';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { Slide } from 'types';

interface VideosContextData {
  hasVideos: boolean;
  allVideos: VideoData[];
  bootcampVideos: Slide[];
  strengthVideos: Slide[];
  cardioVideos: Slide[];
  stretchAndRecoverVideos: Slide[];
  boxAndFightVideos: Slide[];
  yogaVideos: Slide[];
  pilatesVideos: Slide[];
  cyclingVideos: Slide[];
  danceVideos: Slide[];
  meditationVideos: Slide[];
  setAllVideos: (slides: VideoData[]) => void;
  setBootcampVideos: (slides: Slide[]) => void;
  setStrengthVideos: (slides: Slide[]) => void;
  setCardioVideos: (slides: Slide[]) => void;
  setStretchAndRecoverVideos: (slides: Slide[]) => void;
  setBoxAndFightVideos: (slides: Slide[]) => void;
  setYogaVideos: (slides: Slide[]) => void;
  setPilatesVideos: (slides: Slide[]) => void;
  setCyclingVideos: (slides: Slide[]) => void;
  setDanceVideos: (slides: Slide[]) => void;
  setMeditationVideos: (slides: Slide[]) => void;
}

const VideosContext = createContext<VideosContextData>({} as VideosContextData);

const VideosProvider: React.FC = ({ children }: { children?: React.ReactNode }) => {
  const [hasVideos, setHasVideos] = useState(false);
  const [allVideos, setAllVideos] = useState<VideoData[]>([] as VideoData[]);
  const [bootcampVideos, setBootcampVideos] = useState<Slide[]>([] as Slide[]);
  const [strengthVideos, setStrengthVideos] = useState<Slide[]>([] as Slide[]);
  const [cardioVideos, setCardioVideos] = useState<Slide[]>([] as Slide[]);
  const [stretchAndRecoverVideos, setStretchAndRecoverVideos] = useState<Slide[]>([] as Slide[]);
  const [boxAndFightVideos, setBoxAndFightVideos] = useState<Slide[]>([] as Slide[]);
  const [yogaVideos, setYogaVideos] = useState<Slide[]>([] as Slide[]);
  const [pilatesVideos, setPilatesVideos] = useState<Slide[]>([] as Slide[]);
  const [cyclingVideos, setCyclingVideos] = useState<Slide[]>([] as Slide[]);
  const [danceVideos, setDanceVideos] = useState<Slide[]>([] as Slide[]);
  const [meditationVideos, setMeditationVideos] = useState<Slide[]>([] as Slide[]);

  useEffect(() => {
    if (allVideos.length > 0) setHasVideos(true);
  }, [allVideos]);

  return (
    <VideosContext.Provider
      value={{
        hasVideos,
        allVideos,
        bootcampVideos,
        strengthVideos,
        cardioVideos,
        stretchAndRecoverVideos,
        boxAndFightVideos,
        yogaVideos,
        pilatesVideos,
        cyclingVideos,
        danceVideos,
        meditationVideos,
        setAllVideos,
        setBootcampVideos,
        setStrengthVideos,
        setCardioVideos,
        setStretchAndRecoverVideos,
        setBoxAndFightVideos,
        setYogaVideos,
        setPilatesVideos,
        setCyclingVideos,
        setDanceVideos,
        setMeditationVideos
      }}>
      {children}
    </VideosContext.Provider>
  );
};

function useVideos(): VideosContextData {
  const context = useContext(VideosContext);
  if (!context) throw Error('the hook useVideos must be used inside a VideosProvider');
  return context;
}

export { useVideos, VideosProvider };
