import { animated } from 'react-spring';
import styled from 'styled-components';

export const Section = styled(animated.section)`
  padding: 10rem 0 2rem;
  width: 100%;
  position: relative;
  z-index: 1;
  overflow: hidden;
  border-bottom-left-radius: 2rem;
  border-bottom-right-radius: 2rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background: radial-gradient(var(--c-main-1), var(--c-main-2));
  background-position: 0% 50%;
  background-size: 400% 400%;

  animation: Gradient 10000ms ease infinite;
  animation-play-state: running;

  h1 {
    color: var(--c-white);
    margin: 0;
    font-size: 2.8rem;
  }

  .swiper-container {
    width: 100%;
    padding: 0;
    margin: 0;

    .slide {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 0.8rem;
      padding: 0 2rem;

      h2 {
        font-size: 2rem;
        text-align: center;
        color: var(--c-white);
        width: 90%;
        margin: 0;
      }

      img {
        width: 14rem;
        height: 14rem;
        object-fit: cover;
        border-radius: 50%;
        margin: 2rem 0;
      }

      .description {
        text-align: center;
        color: var(--c-silver-grey-100);
        line-height: 1.4;
        max-width: 26rem;
      }

      button {
        margin-top: 4rem;
      }
    }
  }

  .swiper-pagination-fraction,
  .swiper-pagination-custom,
  .swiper-container-horizontal > .swiper-pagination-bullets {
    bottom: 6rem;
  }

  .swiper-pagination-bullet {
    background: var(--c-white);
  }
`;
