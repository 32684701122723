import styled from 'styled-components';

export const Section = styled.section`
  background: var(--c-silver-grey-100);
  padding: 0 2rem 4.6rem;

  .sub-title {
    color: var(--c-smoke-grey-400);
    font-weight: var(--f-semi-bold);
    display: block;
    text-align: center;
    margin-bottom: 1.4rem;
  }

  p {
    text-align: center;
    margin-bottom: 2rem;
  }
`;

export const PhoneButton = styled.button`
  background: var(--c-ocean-blue-300);
  border: none;
  border-radius: 1rem;
  padding: 1.2rem 2rem;
  line-height: 1;
  margin: 0 auto;

  display: flex;
  align-items: center;
  justify-content: space-between;

  svg {
    width: 1.9rem;
    height: 1.9rem;
    fill: var(--c-white);
    margin-right: 1rem;
  }

  a {
    color: var(--c-white);
    font-weight: var(--f-light);
    font-size: 2.2rem;
    text-decoration: none;
  }
`;

export const SpecsSection = styled.section`
  background: var(--c-white);
  padding: 6.2rem 2rem;

  p + h2 {
    margin-top: 6.8rem;
  }
`;
