import { useCallback } from 'react';
import { ActivitiesData } from 'types';

import { Card } from './styles';

type ActivityCardProps = Omit<ActivitiesData, 'id'>;

export const ActivityCard: React.FC<ActivityCardProps> = ({
  area,
  title,
  backgroundImage,
  backgroundImage_2x,
  slug
}: ActivityCardProps) => {
  const handleClick = useCallback((slug) => {
    console.log(slug);
  }, []);

  return (
    <Card
      onClick={() => handleClick(slug)}
      style={{
        backgroundImage: `url(${
          window.devicePixelRatio === 1 ? backgroundImage : backgroundImage_2x
        })`
      }}>
      <span className="sub-title">{area}</span>
      <span className="title">{title}</span>
    </Card>
  );
};
