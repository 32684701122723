import { IconCircleQuestion } from 'assets/icons';
import { BsBell, BsPlus, BsThreeDots, BsX } from 'react-icons/bs';
import { FiChevronLeft, FiMenu, FiX } from 'react-icons/fi';

import { Button } from './styles';

type IconTypes = 'back' | 'notification' | 'menu' | 'plus' | 'three-dots' | 'close' | 'question';
interface IconButtonProps extends React.ComponentPropsWithoutRef<'button'> {
  icon: IconTypes;
  isOpen?: boolean;
  color: string;
  backgroundColor?: string;
}

export const IconButton: React.FC<IconButtonProps> = ({
  icon,
  isOpen = false,
  color,
  backgroundColor = 'transparent',
  className = '',
  ...props
}: IconButtonProps) => {
  return (
    <Button
      type="button"
      className={`${className} ${icon}`}
      style={{ background: backgroundColor }}
      {...props}>
      {icon === 'back' && <FiChevronLeft color={color} />}
      {icon === 'notification' && <BsBell color={color} />}
      {icon === 'plus' && <BsPlus color={color} />}
      {icon === 'three-dots' && <BsThreeDots color={color} />}
      {icon === 'close' && <BsX color={color} />}
      {icon === 'question' && <IconCircleQuestion color={color} />}
      {icon === 'menu' && (isOpen ? <FiX color={color} /> : <FiMenu color={color} />)}
    </Button>
  );
};
