import React, { useState } from 'react';
import { useEffect } from 'react';
import { AreaType } from 'types';
import { areaToEN } from 'utils';

import { Container } from './styles';

interface ScoreBadgeProps extends React.HTMLAttributes<HTMLDivElement> {
  area: AreaType;
  points: number;
}

export const ScoreBadge: React.FC<ScoreBadgeProps> = ({
  area,
  points,
  ...props
}: ScoreBadgeProps) => {
  const [currentPoints, setCurrentPoints] = useState(0);
  const [timeoutDuration, SetTimeoutDuration] = useState(50);

  useEffect(() => {
    switch (true) {
      case points >= 400:
        SetTimeoutDuration(2);
        break;
      case points >= 200:
        SetTimeoutDuration(4);
        break;
      case points >= 100:
        SetTimeoutDuration(6);
        break;
      case points >= 50:
        SetTimeoutDuration(12);
        break;
      case points >= 30:
        SetTimeoutDuration(24);
        break;
      case points >= 10:
        SetTimeoutDuration(48);
        break;
      case points >= 5:
        SetTimeoutDuration(120);
        break;

      default:
        SetTimeoutDuration(1);
        break;
    }
  }, [points]);

  useEffect(() => {
    setTimeout(() => {
      if (currentPoints < points) setCurrentPoints(currentPoints + 1);
    }, timeoutDuration);
  }, [currentPoints, timeoutDuration]);

  return (
    <Container {...props}>
      <div className="content">
        <span className="area">{area}</span>
        <span className="points">{currentPoints}</span>
        <span className="label">pontos</span>
      </div>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 105 121" className={areaToEN(area)}>
        <defs>
          <radialGradient id="gradient" cx="20" cy="40" r="200" gradientUnits="userSpaceOnUse">
            <stop className="stop-1" offset="0" />
            <stop className="stop-2" offset="1" />
          </radialGradient>
        </defs>
        <path
          className="badge"
          fill="url(#gradient)"
          fillRule="evenodd"
          d="M0 14A14 14 0 0114 0h76a14 14 0 0115 14v75a14 14 0 01-9 14l-38 16a18 18 0 01-14 0L8 103a14 14 0 01-8-13z"
        />
      </svg>
    </Container>
  );
};
