/* eslint-disable jsx-a11y/no-onchange */
import React, { RefObject, useRef, useState } from 'react';

import { Select } from './styles';

interface LogSelectProps {
  options: {
    label: string;
    value: string;
  }[];
  label: string;
  id: string;
  onChangeCallBack(): void;
  selectRef: RefObject<HTMLSelectElement>;
}

export const LogSelect: React.FC<LogSelectProps> = ({
  options,
  label,
  id,
  selectRef,
  onChangeCallBack
}: LogSelectProps) => {
  const optionHolderRef = useRef<HTMLSpanElement>(null);
  const [currentLabel, setCurrentLabel] = useState('selecionar');

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setCurrentLabel(
      event.target.value === ''
        ? 'selecionar'
        : options.filter(({ value }) => value === event.target.value).shift()?.label || ''
    );
    onChangeCallBack();
  };

  return (
    <Select>
      <label htmlFor={id}>{label}</label>
      <select ref={selectRef} id={id} onChange={handleSelectChange}>
        <option value="" hidden>
          Selecionar
        </option>
        {options.map(({ label, value }, i) => (
          <option value={value} key={i}>
            {label}
          </option>
        ))}
      </select>
      <span className="holder" ref={optionHolderRef}>
        {currentLabel}
      </span>
    </Select>
  );
};
