import { Button } from 'components';
import format from 'date-fns/format';
import PTLocale from 'date-fns/locale/pt';
import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { colors } from 'static';
import SwiperCore, { A11y, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Slide, SliderProps } from 'types';

import { CategoryCard, SliderContainer } from './styles';

SwiperCore.use([Pagination, A11y]);

export const VideosSlider: React.FC<SliderProps> = ({ slides, category }: SliderProps) => {
  const { oceanBlue300, white } = colors;
  const [slideList, setSlideList] = useState<Slide[]>([]);
  const history = useHistory();

  const swiperParams = {
    spaceBetween: 16,
    grabCursor: true,
    loop: false,
    speed: 600,
    pagination: { clickable: true },
    breakpoints: {
      320: {
        slidesPerView: 1,
        spaceBetween: 16
      },
      768: {
        slidesPerView: 3,
        spaceBetween: 16
      },
      1024: {
        slidesPerView: 5,
        spaceBetween: 16
      }
    },
    onSlideChange: (swiper: SwiperCore) => handleSlider(swiper)
  };

  useEffect(() => {
    setSlideList(slides);
  }, [slides]);

  const handleImageError = useCallback((evt) => {
    evt.target.src = '/assets/img/video-placeholder.svg';
  }, []);

  const handleClick = useCallback((tag) => {
    history.push(`/movimento/video/${tag}`);
  }, []);

  const handleSlider = useCallback((slider) => {
    console.log(slider);
  }, []);

  const showAllVideos = useCallback(() => {
    // TO DO
  }, []);

  return (
    <SliderContainer>
      <Swiper {...swiperParams}>
        {slideList.length > 0 ? (
          <>
            {slideList.map((slide, i) => {
              const { img, tag, title, description, level, minutes, publishedDate } = slide;
              return (
                <SwiperSlide key={i}>
                  <button onClick={() => handleClick(tag)}>
                    <img src={img} alt={title} onError={handleImageError} />
                    <span className="container">
                      <span>
                        <strong className="title">
                          {title.slice(0, 28)}
                          {title.length > 28 ? '...' : ''}
                        </strong>
                        <span className="description">
                          {description.slice(0, 56)}
                          {description.length > 56 ? '...' : ''}
                        </span>
                        <span className="details">
                          {minutes} min • {level} :{' '}
                          {format(new Date(publishedDate), 'd  MMMM', {
                            locale: PTLocale
                          })}
                        </span>
                      </span>
                      <span className="tag">novo</span>
                    </span>
                  </button>
                </SwiperSlide>
              );
            })}
            <SwiperSlide>
              <CategoryCard>
                <span>Veja todos os vídeos de {category}</span>
                <Button
                  size="xs"
                  backgroundColor={oceanBlue300}
                  textColor={white}
                  className="btn"
                  onClick={showAllVideos}>
                  Ver
                </Button>
              </CategoryCard>
            </SwiperSlide>
          </>
        ) : (
          <div className="loading">aguarde...</div>
        )}
      </Swiper>
    </SliderContainer>
  );
};
