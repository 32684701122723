export const colors = {
  hiBlue: '#00a0df',
  hiPurple: '#b11cab',
  white: '#fff',
  silverGrey100: '#F4F6FA',
  phantomGrey200: '#DBDDE1',
  smokeGrey400: '#9a9a9a',
  laVanda200: '#26213a',
  pinkCocktail200: '#DC7AC3',
  pinkPanther400: '#C62C9E',
  purpleFog200: '#A766DA',
  purpleMist500: '#5D4ADE',
  purpleTwilight600: '#6A00C6',
  cristalBlue100: '#9FE4E1',
  aquaBlue200: '#5AD1CE',
  nordicBlue200: '#80C1FC',
  oceanBlue300: '#0199FF',
  oldNavyBlue800: '#4C5980',
  nightBlue900: '#14233A',
  iceSalmon100: '#FEB3B4',
  alaskaSalmon300: '#FF8280',
  yellowSand100: '#FFDE86'
};

const {
  oceanBlue300,
  aquaBlue200,
  purpleMist500,
  purpleTwilight600,
  alaskaSalmon300,
  pinkPanther400,
  yellowSand100,
  iceSalmon100
} = colors;

export const areaColors = {
  movement: [oceanBlue300, aquaBlue200],
  health: [oceanBlue300, purpleTwilight600],
  nutrition: [alaskaSalmon300, pinkPanther400],
  bonus: [yellowSand100, iceSalmon100],
  main: [purpleMist500, purpleTwilight600]
};
