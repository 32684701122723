import {
  ActivityTrackerForm,
  Cover,
  Header,
  IconButton,
  Main,
  ScoreGraph,
  ServicesCards
} from 'components';
import { useAuth } from 'hooks/useAuth';
import React, { useEffect, useState } from 'react';
import { colors, graphs, services } from 'static';
import SwiperCore, { A11y, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { CoverData, GraphData, ServicesData } from 'types';

import { Card, DataTrackers, GraphSection, ManualLog, QRDetails } from './styles';

SwiperCore.use([Pagination, A11y]);

export const Treino: React.FC = () => {
  const { user } = useAuth();
  const { white, oceanBlue300 } = colors;
  const [coverContent, setCoverContent] = useState<CoverData>({} as CoverData);
  const [graph, setGraph] = useState<GraphData>({} as GraphData);
  const [movementServices, setMovementServices] = useState<ServicesData[]>([] as ServicesData[]);

  useEffect(() => {
    setCoverContent(
      services.filter(({ slug }) => slug === '/movimento/treino').shift()?.cover ||
        ({} as CoverData)
    );
  }, []);

  useEffect(() => {
    const currentGraph = graphs.filter(({ area }) => area === 'movimento').shift();
    if (currentGraph) setGraph(currentGraph);
  }, []);

  useEffect(() => {
    setMovementServices(services.filter(({ area, card }) => area === 'movimento' && card.show));
  }, []);

  return (
    <>
      <Header />
      <Main>
        <Cover {...coverContent} QRCodeValue={user.gym_access} />
        <QRDetails>
          <span className="sub-title">QR code de acesso</span>
          <p>Aponte o QRCode para o scanner à entrada do seu clube</p>
        </QRDetails>
        <ManualLog>
          <h2>Registo manual</h2>
          <p>Preencha os campos seguintes e acumule pontos de movimento</p>
          <ActivityTrackerForm />
        </ManualLog>
        <DataTrackers>
          <h2>Dispositivos</h2>
          <p>Adicione os seus dispositivos para ganhar pontos de movimento</p>
          <Swiper
            slidesPerView={2}
            spaceBetween={8}
            pagination={{ clickable: true }}
            className="slider">
            <SwiperSlide>
              <Card>
                <img src="/assets/logos/logo-apple-fitness.svg" alt="Apple Fitness Plus" />
                <IconButton icon="plus" color={white} backgroundColor={oceanBlue300} />
              </Card>
            </SwiperSlide>
            <SwiperSlide>
              <Card>
                <img src="/assets/logos/logo-google-fit.svg" alt="GoogleFit" />
                <IconButton icon="plus" color={white} backgroundColor={oceanBlue300} />
              </Card>
            </SwiperSlide>
          </Swiper>
        </DataTrackers>
        <GraphSection>
          {graph.area && <ScoreGraph className="graph" graph={graph} card_size="md" />}
        </GraphSection>
        <ServicesCards
          services={movementServices}
          title="A pensar em si"
          description="Selecione um serviço e prepare-se para conquistar mais pontos"
        />
      </Main>
    </>
  );
};
