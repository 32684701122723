import { NotificationsData } from 'types';

export const notifications: NotificationsData[] = [
  {
    id: '1',
    title: 'demo notification 1',
    description: 'some description here',
    date: '2021-06-07T12:02:00',
    status: 'read'
  },
  {
    id: '2',
    title: 'demo notification 2',
    description: 'some description here',
    date: '2021-07-08T12:02:00',
    status: 'unread'
  }
];
