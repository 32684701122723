import { ActivitiesData, ActivitiesData_v1 } from 'types';

export const activities: ActivitiesData[] = [
  {
    id: '1',
    area: 'movimento',
    title: 'Vamos treinar com amigos?',
    backgroundImage: '/media/img/activities/movement-1.jpg',
    backgroundImage_2x: '/media/img/activities/movement-1@2x.jpg',
    slug: '/movement/vamos-treinar-com-amigos'
  },
  {
    id: '2',
    area: 'movimento',
    title: 'Treino de resistência',
    backgroundImage: '/media/img/activities/movement-2.jpg',
    backgroundImage_2x: '/media/img/activities/movement-2@2x.jpg',
    slug: '/movement/treino-de-resistencia'
  },
  {
    id: '3',
    area: 'movimento',
    title: '5000 Pontos numa semana',
    backgroundImage: '/media/img/activities/movement-3.jpg',
    backgroundImage_2x: '/media/img/activities/movement-3@2x.jpg',
    slug: '/movement/5000-pontos-numa-semana'
  },
  {
    id: '4',
    area: 'movimento',
    title: 'Sessão de Cross Training',
    backgroundImage: '/media/img/activities/movement-4.jpg',
    backgroundImage_2x: '/media/img/activities/movement-4@2x.jpg',
    slug: '/movement/sessao-de-cross-training'
  },
  {
    id: '5',
    area: 'nutrição',
    title: 'Ideias para um lanche saudável',
    backgroundImage: '/media/img/activities/nutrition-1.jpg',
    backgroundImage_2x: '/media/img/activities/nutrition-1@2x.jpg',
    slug: '/movement/ideias-para-um-lanche-saudavel'
  },
  {
    id: '6',
    area: 'nutrição',
    title: 'Jantar fora? Sobre controlo!',
    backgroundImage: '/media/img/activities/nutrition-2.jpg',
    backgroundImage_2x: '/media/img/activities/nutrition-2@2x.jpg',
    slug: '/movement/jantar-fora-sobre-controlo!'
  },
  {
    id: '7',
    area: 'nutrição',
    title: 'Vinho? Porque não?',
    backgroundImage: '/media/img/activities/nutrition-3.jpg',
    backgroundImage_2x: '/media/img/activities/nutrition-3@2x.jpg',
    slug: '/movement/vinho-porque-nao'
  },
  {
    id: '8',
    area: 'nutrição',
    title: 'A energia de um snack com chia',
    backgroundImage: '/media/img/activities/nutrition-4.jpg',
    backgroundImage_2x: '/media/img/activities/nutrition-4@2x.jpg',
    slug: '/movement/a-energia-de-um-snack-com-chia'
  },
  {
    id: '9',
    area: 'saúde',
    title: 'Hora de dizer “What’s up doc?”',
    backgroundImage: '/media/img/activities/health-1.jpg',
    backgroundImage_2x: '/media/img/activities/health-1@2x.jpg',
    slug: '/movement/hora-de-dizer-whats-up-doc'
  },
  {
    id: '10',
    area: 'saúde',
    title: 'Verão, sol, praia, controlado!',
    backgroundImage: '/media/img/activities/health-2.jpg',
    backgroundImage_2x: '/media/img/activities/health-2@2x.jpg',
    slug: '/movement/verão-sol-praia-controlado'
  },
  {
    id: '11',
    area: 'saúde',
    title: 'Rumo ao “peso pluma”!',
    backgroundImage: '/media/img/activities/health-3.jpg',
    backgroundImage_2x: '/media/img/activities/health-3@2x.jpg',
    slug: '/movement/rumo-ao-peso-pluma'
  },
  {
    id: '12',
    area: 'saúde',
    title: 'Cole o seu coração partido',
    backgroundImage: '/media/img/activities/health-4.jpg',
    backgroundImage_2x: '/media/img/activities/health-4@2x.jpg',
    slug: '/movement/cole-o-seu-coracao-partido'
  }
];

export const activities_v1: ActivitiesData_v1[] = [
  {
    id: '1',
    area: 'saúde',
    title: 'Regista o teu movimento',
    description:
      'No final de cada dias não te esqueças de registar a tua atividade para ganhar pontos Hi!',
    points: 400,
    image:
      'https://images.unsplash.com/photo-1531140035644-4ce1e6e1b85f?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8YWN0aXZpdHklMjB0cmFja2VyfGVufDB8fDB8fA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=60',
    image_2x:
      'https://images.unsplash.com/photo-1531140035644-4ce1e6e1b85f?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8YWN0aXZpdHklMjB0cmFja2VyfGVufDB8fDB8fA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=60',
    slug: 'move/atividade/registar'
  },
  {
    id: '2',
    area: 'saúde',
    title: 'Reserva um Personal Trainer',
    description:
      'Alcança o teu melhor com um Personal Trainer! Faz uma sessão e conta-nos se valeu a pena!',
    points: 300,
    image:
      'https://images.unsplash.com/photo-1610026470365-48d91e7ba1ae?ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8cGVyc29uYWwlMjB0cmFpbmVyfGVufDB8fDB8fA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=60',
    image_2x:
      'https://images.unsplash.com/photo-1610026470365-48d91e7ba1ae?ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8cGVyc29uYWwlMjB0cmFpbmVyfGVufDB8fDB8fA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=60',
    slug: 'move/personal-trainer'
  },
  {
    id: '3',
    area: 'saúde',
    title: 'Vai patinar ou vai para casa!',
    description:
      'Está na hora de ser radical!! Sai com o teu skate e queima calorias com um "ollie"',
    points: 150,
    image:
      'https://images.unsplash.com/photo-1510414050375-8404bd196322?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTN8fHNuaWNrZXJzfGVufDB8fDB8fA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=60',
    image_2x:
      'https://images.unsplash.com/photo-1510414050375-8404bd196322?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTN8fHNuaWNrZXJzfGVufDB8fDB8fA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=60',
    slug: 'move/atividade/vai-patinar-ou-vai-para-casa'
  },
  {
    id: '4',
    area: 'saúde',
    title: 'Dá um passeio até ao pôr-do-sol',
    description: 'Nada é melhor do que uma boa caminhada no final de uma tarde quente de Verão',
    points: 400,
    image:
      'https://images.unsplash.com/photo-1476254737979-40c1226140e4?ixid=MnwxMjA3fDB8MHxzZWFyY2h8NzR8fHN1bnNldCUyMHdhbGt8ZW58MHx8MHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=60',
    image_2x:
      'https://images.unsplash.com/photo-1476254737979-40c1226140e4?ixid=MnwxMjA3fDB8MHxzZWFyY2h8NzR8fHN1bnNldCUyMHdhbGt8ZW58MHx8MHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=60',
    slug: 'move/atividade/passeio-por-do-sol'
  },
  {
    id: '5',
    area: 'movimento',
    title: 'Regista o teu movimento',
    description:
      'No final de cada dias não te esqueças de registar a tua atividade para ganhar pontos Hi!',
    points: 400,
    image:
      'https://images.unsplash.com/photo-1531140035644-4ce1e6e1b85f?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8YWN0aXZpdHklMjB0cmFja2VyfGVufDB8fDB8fA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=60',
    image_2x:
      'https://images.unsplash.com/photo-1531140035644-4ce1e6e1b85f?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8YWN0aXZpdHklMjB0cmFja2VyfGVufDB8fDB8fA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=60',
    slug: 'move/atividade/registar'
  },
  {
    id: '6',
    area: 'movimento',
    title: 'Reserva um Personal Trainer',
    description:
      'Alcança o teu melhor com um Personal Trainer! Faz uma sessão e conta-nos se valeu a pena!',
    points: 300,
    image:
      'https://images.unsplash.com/photo-1610026470365-48d91e7ba1ae?ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8cGVyc29uYWwlMjB0cmFpbmVyfGVufDB8fDB8fA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=60',
    image_2x:
      'https://images.unsplash.com/photo-1610026470365-48d91e7ba1ae?ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8cGVyc29uYWwlMjB0cmFpbmVyfGVufDB8fDB8fA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=60',
    slug: 'move/personal-trainer'
  },
  {
    id: '7',
    area: 'movimento',
    title: 'Vai patinar ou vai para casa!',
    description:
      'Está na hora de ser radical!! Sai com o teu skate e queima calorias com um "ollie"',
    points: 150,
    image:
      'https://images.unsplash.com/photo-1510414050375-8404bd196322?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTN8fHNuaWNrZXJzfGVufDB8fDB8fA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=60',
    image_2x:
      'https://images.unsplash.com/photo-1510414050375-8404bd196322?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTN8fHNuaWNrZXJzfGVufDB8fDB8fA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=60',
    slug: 'move/atividade/vai-patinar-ou-vai-para-casa'
  },
  {
    id: '8',
    area: 'movimento',
    title: 'Dá um passeio até ao pôr-do-sol',
    description: 'Nada é melhor do que uma boa caminhada no final de uma tarde quente de Verão',
    points: 400,
    image:
      'https://images.unsplash.com/photo-1476254737979-40c1226140e4?ixid=MnwxMjA3fDB8MHxzZWFyY2h8NzR8fHN1bnNldCUyMHdhbGt8ZW58MHx8MHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=60',
    image_2x:
      'https://images.unsplash.com/photo-1476254737979-40c1226140e4?ixid=MnwxMjA3fDB8MHxzZWFyY2h8NzR8fHN1bnNldCUyMHdhbGt8ZW58MHx8MHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=60',
    slug: 'move/atividade/passeio-por-do-sol'
  },
  {
    id: '9',
    area: 'nutrição',
    title: 'Objetivo 1500Kcal',
    description:
      'O teu alvo diário aumentou para 1500Kcal, devido ao aumento recente do teu movimento',
    points: 400,
    image:
      'https://images.unsplash.com/photo-1622090860720-c4a77e146284?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80',
    image_2x:
      'https://images.unsplash.com/photo-1622090860720-c4a77e146284?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80',
    slug: 'move/atividades/objetivo-1500kcal'
  },
  {
    id: '10',
    area: 'nutrição',
    title: 'online checkup',
    description:
      'Coma, rápido e eficaz! Marca o teu checkup mensal de nutrição online para ganhar 300pts',
    points: 300,
    image:
      'https://images.unsplash.com/photo-1485217988980-11786ced9454?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80',
    image_2x:
      'https://images.unsplash.com/photo-1485217988980-11786ced9454?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80',
    slug: 'online-checkup'
  },
  {
    id: '11',
    area: 'nutrição',
    title: 'eat, sleep, repeat',
    description:
      'Concentra-te na ingestão de calorias. O teu objetivo de hoje, 1500Kcal. Consegues? Claro que consegues!',
    points: 400,
    image:
      'https://images.unsplash.com/photo-1501959915551-4e8d30928317?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80',
    image_2x:
      'https://images.unsplash.com/photo-1501959915551-4e8d30928317?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80',
    slug: 'eat-sleep-repeat'
  }
];
