import { sub } from 'date-fns';
import { UserActivityData } from 'types';

const now = new Date();

export const userActivity: UserActivityData[] = [
  {
    userId: '1',
    date: sub(now, { days: 1 }).toISOString(),
    activity: 'bike',
    duration: '60',
    score: 20
  },
  {
    userId: '1',
    date: sub(now, { days: 3, hours: 2, minutes: 12 }).toISOString(),
    activity: 'run',
    duration: '30',
    score: 30
  },
  {
    userId: '1',
    date: sub(now, { days: 4, hours: 12, minutes: 32 }).toISOString(),
    activity: 'walk',
    duration: '45',
    score: 20
  },
  {
    userId: '1',
    date: sub(now, { days: 5, hours: 7, minutes: 6 }).toISOString(),
    activity: 'surf',
    duration: '45',
    score: 40
  },
  {
    userId: '1',
    date: sub(now, { days: 6, hours: 10, minutes: 40 }).toISOString(),
    activity: 'swim',
    duration: '30',
    score: 50
  },
  {
    userId: '2',
    date: sub(now, { days: 1, hours: 10, minutes: 40 }).toISOString(),
    activity: 'swim',
    duration: '30',
    score: 50
  },
  {
    userId: '2',
    date: sub(now, { days: 2, hours: 1, minutes: 1 }).toISOString(),
    activity: 'bike',
    duration: '75',
    score: 50
  },
  {
    userId: '2',
    date: sub(now, { days: 4, hours: 12, minutes: 56 }).toISOString(),
    activity: 'run',
    duration: '30',
    score: 40
  },
  {
    userId: '2',
    date: sub(now, { days: 5, hours: 1, minutes: 49 }).toISOString(),
    activity: 'walk',
    duration: '60',
    score: 30
  },
  {
    userId: '2',
    date: sub(now, { days: 6, hours: 18, minutes: 2 }).toISOString(),
    activity: 'surf',
    duration: '60',
    score: 50
  },
  {
    userId: '3',
    date: sub(now, { hours: 1, minutes: 29 }).toISOString(),
    activity: 'surf',
    duration: '60',
    score: 50
  },
  {
    userId: '3',
    date: sub(now, { days: 1, hours: 1, minutes: 30 }).toISOString(),
    activity: 'swim',
    duration: '45',
    score: 60
  },
  {
    userId: '3',
    date: sub(now, { days: 3, hours: 8, minutes: 9 }).toISOString(),
    activity: 'walk',
    duration: '60',
    score: 30
  },
  {
    userId: '3',
    date: sub(now, { days: 4, hours: 10, minutes: 12 }).toISOString(),
    activity: 'surf',
    duration: '30',
    score: 40
  },
  {
    userId: '3',
    date: sub(now, { days: 6, hours: 6, minutes: 12 }).toISOString(),
    activity: 'run',
    duration: '30',
    score: 30
  },
  {
    userId: '3',
    date: sub(now, { days: 7, hours: 12, minutes: 30 }).toISOString(),
    activity: 'swim',
    duration: '15',
    score: 20
  },
  {
    userId: '3',
    date: sub(now, { days: 8, hours: 2, minutes: 10 }).toISOString(),
    activity: 'run',
    duration: '30',
    score: 30
  },
  {
    userId: '4',
    date: sub(now, { hours: 2, minutes: 10 }).toISOString(),
    activity: 'run',
    duration: '30',
    score: 40
  },
  {
    userId: '4',
    date: sub(now, { days: 1, hours: 10, minutes: 59 }).toISOString(),
    activity: 'walk',
    duration: '60',
    score: 30
  },
  {
    userId: '4',
    date: sub(now, { days: 3, hours: 10, minutes: 59 }).toISOString(),
    activity: 'bike',
    duration: '75',
    score: 40
  },
  {
    userId: '4',
    date: sub(now, { days: 4, hours: 1, minutes: 32 }).toISOString(),
    activity: 'swim',
    duration: '30',
    score: 30
  },
  {
    userId: '4',
    date: sub(now, { days: 6, hours: 12, minutes: 20 }).toISOString(),
    activity: 'surf',
    duration: '45',
    score: 40
  },
  {
    userId: '5',
    date: sub(now, { hours: 2, minutes: 20 }).toISOString(),
    activity: 'bike',
    duration: '45',
    score: 30
  },
  {
    userId: '5',
    date: sub(now, { days: 1, hours: 10, minutes: 1 }).toISOString(),
    activity: 'run',
    duration: '30',
    score: 30
  },
  {
    userId: '5',
    date: sub(now, { days: 2, hours: 2, minutes: 17 }).toISOString(),
    activity: 'swim',
    duration: '60',
    score: 40
  },
  {
    userId: '5',
    date: sub(now, { days: 3, hours: 10, minutes: 45 }).toISOString(),
    activity: 'walk',
    duration: '60',
    score: 20
  },
  {
    userId: '5',
    date: sub(now, { days: 5, hours: 10, minutes: 45 }).toISOString(),
    activity: 'run',
    duration: '30',
    score: 30
  },
  {
    userId: '5',
    date: sub(now, { days: 6, hours: 20, minutes: 1 }).toISOString(),
    activity: 'bike',
    duration: '45',
    score: 30
  },
  {
    userId: '5',
    date: sub(now, { days: 7, hours: 4, minutes: 30 }).toISOString(),
    activity: 'walk',
    duration: '45',
    score: 20
  }
];
