import styled from 'styled-components';

export const Section = styled.section`
  background: var(--c-silver-grey-100);
  padding: 0 2rem 4.6rem;

  p {
    text-align: center;
    margin-bottom: 2rem;
  }
`;
