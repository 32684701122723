import { Accordion, Filter } from 'components';
import React, { useEffect, useState } from 'react';
import { countDuplicatesInArray } from 'utils';

import { Section } from './styles';

type QuestionsData = {
  label: string;
  description: string;
  category: string;
};

interface FAQsAccordionSectionProps {
  questions: QuestionsData[];
}

export const FAQsAccordionSection: React.FC<FAQsAccordionSectionProps> = ({
  questions
}: FAQsAccordionSectionProps) => {
  const [filterLabel, setFilterLabel] = useState('filtrar');
  const [filterOptions, setFilterOptions] = useState<string[]>([]);
  const [listItems, setListItems] = useState<QuestionsData[]>([] as QuestionsData[]);

  useEffect(() => {
    setListItems(questions);
  }, [questions]);

  useEffect(() => {
    const allCategories = questions.map(({ category }) => category);

    const countedDuplicatedCategories = countDuplicatesInArray(allCategories.flat().sort());

    // @see : https://medium.com/@gmcharmy/sort-objects-in-javascript-e-c-how-to-get-sorted-values-from-an-object-142a9ae7157c
    const orderedCategories: string[] = Object.entries(countedDuplicatedCategories)
      .sort((a, b) => b[1] - a[1])
      .map((el) => el[0]);

    setFilterOptions(orderedCategories);
  }, [questions]);

  const handleFilter = (selectedCategory: string) => {
    if (!selectedCategory) {
      setListItems(questions);
      setFilterLabel('filtrar');
    } else {
      setListItems(questions.filter(({ category }) => category === selectedCategory));
      setFilterLabel(
        `${selectedCategory.slice(0, 10)}${selectedCategory.length > 10 ? '...' : ''}`
      );
    }
  };

  return (
    <Section>
      <span className="section-header">
        <h2>{"FAQ's"}</h2>
        <Filter label={filterLabel} handleFilter={handleFilter} options={filterOptions} />
      </span>
      <div className="accordion-container">
        {listItems.map(({ label, description }, i) => (
          <Accordion
            icon="circle"
            buttonTitle={label}
            isOpen={false}
            key={i}
            className="accordion-item">
            <div className="content">{description}</div>
          </Accordion>
        ))}
      </div>
    </Section>
  );
};
