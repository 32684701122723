import styled from 'styled-components';

export const Card = styled.button`
  border: none;
  padding: 2rem 1.8rem;
  border-radius: 2rem;
  height: 24rem;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  background-size: cover;
  background-position: center;

  transition: background-position 6000ms ease;

  & > * {
    text-align: left;
  }

  .title {
    font-size: 1.8rem;
    font-weight: var(--f-bold);
    color: var(--c-white);
    margin-top: 0.8rem;
  }
`;
