import { PromosData } from 'types';

export const promos: PromosData[] = [
  {
    id: '1',
    area: 'saúde',
    title: 'Multi Vitaminas Minerais',
    category: 'multivitaminico',
    discount: 10,
    image: '/media/img/promos/health/promo-1.jpg',
    image_2x: '/media/img/promos/health/promo-1@2x.jpg',
    slug: '/store/multi-vitaminas-minerais'
  },
  {
    id: '2',
    area: 'saúde',
    title: 'Clara de Ovo Líquida',
    category: 'proteína',
    discount: 20,
    image: '/media/img/promos/health/promo-2.jpg',
    image_2x: '/media/img/promos/health/promo-2@2x.jpg',
    slug: '/store/clara-de-ovo-liquida'
  },
  {
    id: '3',
    area: 'saúde',
    title: 'Ashwagandha Ayurveda',
    category: 'super alimento',
    discount: 8,
    image: '/media/img/promos/health/promo-3.jpg',
    image_2x: '/media/img/promos/health/promo-3@2x.jpg',
    slug: '/store/ashwagandha-ayurveda'
  },
  {
    id: '4',
    area: 'saúde',
    title: 'High Protein Spread',
    category: 'proteína',
    discount: 20,
    image: '/media/img/promos/health/promo-4.jpg',
    image_2x: '/media/img/promos/health/promo-4@2x.jpg',
    slug: '/store/high-protein-spread'
  },
  {
    id: '5',
    area: 'movimento',
    title: 'Multi Vitaminas Minerais',
    category: 'multivitaminico',
    discount: 10,
    image: '/media/img/promos/health/promo-1.jpg',
    image_2x: '/media/img/promos/health/promo-1@2x.jpg',
    slug: '/store/multi-vitaminas-minerais'
  },
  {
    id: '6',
    area: 'movimento',
    title: 'Clara de Ovo Líquida',
    category: 'proteína',
    discount: 20,
    image: '/media/img/promos/health/promo-2.jpg',
    image_2x: '/media/img/promos/health/promo-2@2x.jpg',
    slug: '/store/clara-de-ovo-liquida'
  },
  {
    id: '7',
    area: 'movimento',
    title: 'Ashwagandha Ayurveda',
    category: 'super alimento',
    discount: 8,
    image: '/media/img/promos/health/promo-3.jpg',
    image_2x: '/media/img/promos/health/promo-3@2x.jpg',
    slug: '/store/ashwagandha-ayurveda'
  },
  {
    id: '8',
    area: 'movimento',
    title: 'High Protein Spread',
    category: 'proteína',
    discount: 20,
    image: '/media/img/promos/health/promo-4.jpg',
    image_2x: '/media/img/promos/health/promo-4@2x.jpg',
    slug: '/store/high-protein-spread'
  },
  {
    id: '9',
    area: 'nutrição',
    title: 'Multi Vitaminas Minerais',
    category: 'multivitaminico',
    discount: 10,
    image: '/media/img/promos/health/promo-1.jpg',
    image_2x: '/media/img/promos/health/promo-1@2x.jpg',
    slug: '/store/multi-vitaminas-minerais'
  },
  {
    id: '10',
    area: 'nutrição',
    title: 'Clara de Ovo Líquida',
    category: 'proteína',
    discount: 20,
    image: '/media/img/promos/health/promo-2.jpg',
    image_2x: '/media/img/promos/health/promo-2@2x.jpg',
    slug: '/store/clara-de-ovo-liquida'
  },
  {
    id: '11',
    area: 'nutrição',
    title: 'Ashwagandha Ayurveda',
    category: 'super alimento',
    discount: 8,
    image: '/media/img/promos/health/promo-3.jpg',
    image_2x: '/media/img/promos/health/promo-3@2x.jpg',
    slug: '/store/ashwagandha-ayurveda'
  },
  {
    id: '12',
    area: 'nutrição',
    title: 'High Protein Spread',
    category: 'proteína',
    discount: 20,
    image: '/media/img/promos/health/promo-4.jpg',
    image_2x: '/media/img/promos/health/promo-4@2x.jpg',
    slug: '/store/high-protein-spread'
  }
];
