import { Button, IconButton } from 'components';
import React, { useState } from 'react';
import Modal from 'react-modal';
import { Link } from 'react-router-dom';
import { colors } from 'static';

import { ActionSection, GuruSection } from './styles';

interface GuruFormSectionProps {
  button?: {
    textColor: string;
    backgroundColor: string;
  };
  title?: {
    color: string;
  };
}

export const GuruFormSection: React.FC<GuruFormSectionProps> = ({
  button,
  title
}: GuruFormSectionProps) => {
  const { white, smokeGrey400 } = colors;
  const textAreaCharLength = 130;
  const [charCount, setCharCount] = useState(textAreaCharLength);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [showForm, setShowForm] = useState(false);
  const [hasBeenSubmitted, setHasBeenSubmitted] = useState(false);

  const handleCharCount = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setCharCount(textAreaCharLength - event.target.value.length);
  };

  return (
    <>
      {showForm ? (
        <GuruSection>
          <h2 style={{ color: title?.color }}>Pergunte ao Guru</h2>
          {!hasBeenSubmitted ? (
            <form>
              <div className="form-field">
                <label htmlFor="guru-question">Escreva a sua pergunta</label>
                <textarea
                  id="guru-question"
                  name="guru-question"
                  wrap="hard"
                  maxLength={textAreaCharLength}
                  onChange={handleCharCount}></textarea>
                <span className="char-count">{charCount} caracteres disponíveis</span>
              </div>
              <div className="checkbox-field">
                <input type="checkbox" id="legal" name="legal" value="legal" />
                <label htmlFor="legal">
                  Li e concordo com os <Link to="/nutrition/guru">Termos e Condições</Link>
                </label>
              </div>
              <Button
                type="button"
                size="xs"
                textColor={button?.textColor}
                backgroundColor={button?.backgroundColor}
                onClick={() => setHasBeenSubmitted(true)}>
                Enviar
              </Button>
            </form>
          ) : (
            <div className="submit-message">
              <p>
                Obrigada por submeter a sua pergunta! O Guru irá responder dentro das próximas 24
                horas
              </p>
              <Button
                size="xs"
                textColor={button?.textColor}
                backgroundColor={button?.backgroundColor}
                onClick={() => setHasBeenSubmitted(false)}>
                Faça outra pergunta
              </Button>
            </div>
          )}
        </GuruSection>
      ) : (
        <ActionSection>
          <h2 style={{ color: title?.color }}>Ainda com dúvidas?</h2>
          <IconButton
            color={smokeGrey400}
            icon="question"
            className="help-btn"
            onClick={() => setIsModalOpen(true)}
          />
          <Button
            size="md"
            textColor={button?.textColor}
            backgroundColor={button?.backgroundColor}
            onClick={() => setShowForm(true)}>
            Pergunte ao Guru
          </Button>
        </ActionSection>
      )}
      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        aria={{
          labelledby: 'Guru description',
          describedby: 'um tutorial passo-a-passo sobre como contactar o serviço Pergunte a um Guru'
        }}
        contentLabel="Pergunta ao Guru"
        overlayClassName="modal-overlay"
        className="modal-content">
        <div className="header">
          <IconButton
            icon="close"
            className="close-btn"
            color={white}
            onClick={() => setIsModalOpen(false)}
          />
          <h2>Como Funciona</h2>
        </div>
        <div className="body">
          <ol>
            <li>
              <strong>Passou uma escova pelo bigode, e continuou falando para dentro</strong>
              <span>
                Pois, menino, eu agora o que necessito é o regímen da Quimera. Vou-me atirar outra
                vez às Memórias. Há de se fazer aí uma quantidade de arte colossal nesse quarto que
                me destinas, diante de Velasquez...
              </span>
            </li>
            <li>
              <strong>Já não tenho fantasia, Sr. Afonso da Maia!</strong>
              <span>
                Foram encontrar Afonso da Maia no escritório, na sua velha poltrona, com um antigo
                volume da Ilustração francesa aberto sobre os joelhos, mostrando as estampas a um
                pequeno bonito, muito moreno, de olho vivo, e cabelo encarapinhado.
              </span>
            </li>
            <li>
              <strong>
                Então esclarecê-lo com a tua clara razão, disse o velho rindo. Estamos cá precisando
                de ambas as coisas, John.
              </strong>
              <span>
                Está visto! exclamou o pequeno, esperto e desembaraçado, com as mãos cruzadas atrás
                das costas. Se mandou matar gente deviam-no ferrar na cadeia!
              </span>
            </li>
          </ol>
        </div>
      </Modal>
    </>
  );
};
