import styled from 'styled-components';

export const Button = styled.button`
  background: transparent;
  border: none;
  border-radius: 0.8rem;
  width: 2.8rem;
  height: 2.8rem;
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 100%;
    height: 100%;
    transition: fill 1000ms ease, stroke 1000ms ease;
  }

  &.plus {
    svg {
      width: 80%;
      height: 80%;
    }
  }
`;
