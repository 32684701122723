import { animated } from 'react-spring';
import styled from 'styled-components';

export const Main = styled.main`
  width: 100vw;
  height: calc(100 * var(--v-height)); // @see: utils/viewport-height.ts

  display: flex;
  align-items: center;
  justify-content: center;

  background: radial-gradient(var(--c-main-1), var(--c-main-2));
  background-position: 0% 50%;
  background-size: 400% 400%;

  animation: Gradient 10000ms ease infinite;
  animation-play-state: running;
`;

export const Section = styled(animated.section)`
  padding: 2.4rem 2.4rem;
  width: 100%;
  height: 100%;
  max-width: 425px;
  max-height: 660px;
  margin: 0 auto;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 2.1rem;

  @media screen and (min-height: 660px) {
    padding: 4.2rem 3.6rem;
  }

  .description {
    flex: 1;
  }

  .back-button {
    position: absolute;
    top: 2.4rem;
    left: 1.6rem;
  }

  .logo {
    width: 96px;
    height: 96px;
    display: block;
    margin: 0 auto;
    fill: var(--c-white);

    &.large {
      width: 200px;
      height: 200px;
    }
  }

  .btn-container button {
    margin-bottom: 1rem;
  }

  h1,
  p {
    color: var(--c-white);
    text-align: center;
    display: block;
  }

  h1 {
    font-size: 24px;
    letter-spacing: 0.5px;
    margin: 0 auto 0.8rem;

    @media (min-width: 375px) {
      font-size: 28px;
    }
  }

  p {
    font-size: 16px;

    @media (min-width: 375px) {
      font-size: 18px;
    }
  }

  a {
    color: var(--c-white);
    text-decoration: none;
    font-weight: var(--f-semi-bold);
    letter-spacing: 0.5px;
  }
`;

export const Form = styled(animated.form)`
  margin-top: auto;
  width: 100%;
`;
