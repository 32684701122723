import styled from 'styled-components';

export const Section = styled.section`
  background: var(--c-silver-grey-100);
  padding: 3.6rem 2rem 3.6rem;

  &.search {
    padding: 0 2rem 3.6rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    p,
    .sub-title {
      color: var(--c-night-blue-900);
    }

    .sub-title {
      margin-bottom: 0.4rem;
    }

    p {
      font-weight: var(--f-semi-bold);
      margin-bottom: 3rem;
    }
  }

  .section-header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
  }

  .slider {
    margin-top: 2rem;
    padding-bottom: 2rem;
  }

  .tag-container {
    width: 100%;
    overflow: scroll;
    padding-bottom: 2rem;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 0.4rem;
  }
`;
