import { IconAvatar } from 'assets/icons';
import { useAuth } from 'hooks/useAuth';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSpring } from 'react-spring';

import { Profile } from './styles';

interface UserProfileProps {
  menuOpen: boolean;
  hasUnreadNotifications: boolean;
}

export const UserProfile: React.FC<UserProfileProps> = ({
  menuOpen,
  hasUnreadNotifications
}: UserProfileProps) => {
  const { user } = useAuth();
  const { image, points, name } = user;
  const [hasImage, setHasProfileImage] = useState(false);
  const appearRight = useSpring({ left: menuOpen ? '0vw' : '100vw' });

  useEffect(() => {
    !image ? setHasProfileImage(false) : setHasProfileImage(true);
  }, [image]);

  return (
    <Profile style={appearRight} className={menuOpen ? 'open' : undefined}>
      <div className={`avatar-container ${hasUnreadNotifications ? 'new' : ''}`}>
        {hasImage ? (
          <img src={image || undefined} alt={name} />
        ) : (
          <IconAvatar className="avatar-icon" />
        )}
      </div>
      <div>
        <h2>{name}</h2>
        <Link to="/points" className="points">
          <strong>
            {points}
            <span>pts</span>
          </strong>
        </Link>
      </div>
    </Profile>
  );
};
