import styled from 'styled-components';

export const SliderContainer = styled.div`
  .swiper-container {
    padding: 4.2rem 0;
    margin-top: -2.4rem;

    .swiper-slide {
      button {
        width: 100%;
        text-decoration: none;
        font-size: 1.3rem;
        text-align: left;
        border: none;
        background: var(--c-silver-grey-100);

        img {
          width: 100%;
          height: 20rem;
          object-fit: cover;
          margin-bottom: 1rem;
          border-radius: 1.6rem;
          border: 3px solid var(--c-silver-grey-100);

          @media (min-width: 768px) {
            height: 140px;
          }

          @media (min-width: 1440px) {
            height: 160px;
          }
        }

        .container {
          display: grid;
          grid-template-columns: auto 4rem;
          gap: 0.8rem;

          .title,
          .description {
            font-size: 1.4rem;
            color: var(--c-night-blue-900);
            display: block;
          }

          .title {
            font-weight: var(--f-semi-bold);
          }

          .description {
            font-weight: var(--f-light);
          }

          .details {
            display: block;
            margin-top: 0.6rem;
            font-size: 1.2rem;
            text-transform: uppercase;
            font-weight: var(--f-semi-bold);
            color: var(--c-smoke-grey-400);
          }

          .tag {
            font-size: 1rem;
            line-height: 1;
            text-transform: uppercase;
            color: var(--c-white);
            background: var(--c-ocean-blue-300);
            font-weight: var(--f-semi-bold);
            padding: 0.2rem;
            height: 1.6rem;
            border-radius: 0.8rem;

            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }

    .swiper-pagination-fraction,
    .swiper-pagination-custom,
    .swiper-container-horizontal > .swiper-pagination-bullets {
      bottom: 0;
    }

    .swiper-pagination-bullet {
      width: 0.8rem;
      height: 0.8rem;
    }

    .swiper-button-prev,
    .swiper-button-next {
      top: 2.4rem;
      width: 2.4rem;
      height: 2.4rem;

      &::after {
        font-size: 1.8rem;
      }
    }

    .swiper-button-prev {
      left: unset;
      right: 4.8rem;
    }
  }

  .loading {
    width: 100%;
    height: 180px;

    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const CategoryCard = styled.div`
  background: var(--c-night-blue-900) !important;
  color: var(--c-white) !important;
  width: 100%;
  height: 20rem;
  border-radius: 1.6rem;
  font-size: 1.8rem;
  font-weight: var(--f-bold);
  letter-spacing: 0.05rem;
  text-align: center;

  padding: 2.4rem 4.2rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4rem;

  span {
    margin-top: 3rem;
  }

  .btn {
    text-align: center !important;
    width: unset !important;
  }
`;
