import { LogoBlueInsurance } from 'assets/vectors';
import { useEffect } from 'react';
import { useState } from 'react';

import { Card } from './styles';

interface ClientCardProps {
  name: string;
  card_number: string | null;
}

export const ClientCard: React.FC<ClientCardProps> = ({ name, card_number }: ClientCardProps) => {
  const [isFlipped, setIsFlipped] = useState(false);
  const [flipPreview, setFlipPreview] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setFlipPreview(true);
    }, 500);
    setTimeout(() => {
      setFlipPreview(false);
    }, 1500);
  }, []);

  return (
    <Card
      className={`${isFlipped ? 'flipped' : ''} ${flipPreview ? 'preview' : ''}`}
      onClick={() => setIsFlipped(!isFlipped)}>
      <div className="card-front card-face">
        <LogoBlueInsurance />
        <span className="sub-title">sports and nutrition</span>
        <span className="details">{name}</span>
        <span className="details">{card_number || ''} HK876</span>
        <a
          href="https/www.blueinsurance.com"
          target="_blank"
          rel="noreferrer noopener"
          className="url">
          www.blueinsurance.com
        </a>
      </div>

      <div className="card-back card-face">
        <div className="black-stripe"></div>
        <div className="card-back-content">
          <div className="card-secret">
            <p className="card-secret-last">420</p>
          </div>
          <LogoBlueInsurance />
        </div>
      </div>
    </Card>
  );
};
