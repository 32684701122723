type Count = {
  [key: string]: number;
};

// @see: https://dev.to/huyddo/find-duplicate-or-repeat-elements-in-js-array-3cl3
export const countDuplicatesInArray = (array: string[]): Count => {
  const count: { [key: string]: number } = {};
  const result = [];

  array.forEach((item) => {
    if (count[item]) {
      count[item] += 1;
      return;
    }
    count[item] = 1;
  });

  for (const prop in count) {
    if (count[prop] >= 2) result.push(prop);
  }

  return count;
};
