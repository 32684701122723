import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { animated, config, Spring } from 'react-spring';
import VisibilitySensor from 'react-visibility-sensor';
import { areaColors } from 'static';
import { AreaType, ServicesData } from 'types';
import { areaToEN } from 'utils';

import { ServicesSection } from './styles';

interface ServicesProps {
  services: ServicesData[];
  title: string;
  description: string;
}

export const ServicesCards: React.FC<ServicesProps> = ({
  services,
  title,
  description
}: ServicesProps) => {
  const history = useHistory();

  const handleServicesNav = useCallback((slug: string) => {
    history.push(slug);
  }, []);

  const handleColors = useCallback((area: AreaType) => {
    switch (area) {
      case 'movimento':
        return areaColors.movement;
      case 'saúde':
        return areaColors.health;
      case 'nutrição':
        return areaColors.nutrition;
      case 'bonus':
        return areaColors.bonus;
      default:
        return areaColors.main;
    }
  }, []);

  return (
    <ServicesSection>
      <h2>{title}</h2>
      <p>{description}</p>

      {services.map(({ slug, area, card }, i) => {
        const { backgroundImage, hasGradientBackground, backgroundImage_2x, title, description } =
          card;
        const currentAreaColors = handleColors(area);
        return (
          <VisibilitySensor partialVisibility key={i}>
            {({ isVisible }) => (
              <Spring
                delay={200}
                to={{
                  opacity: isVisible ? 1 : 0,
                  y: isVisible ? 0 : 20,
                  transform: isVisible ? 'scale(1)' : 'scale(0.9)'
                }}
                config={config.slow}>
                {({ opacity, y, transform }) => (
                  <animated.button
                    className={`card ${areaToEN(area)}`}
                    onClick={() => handleServicesNav(slug)}
                    style={{
                      opacity,
                      y,
                      transform,
                      backgroundImage: `url(${
                        window.devicePixelRatio === 1 ? backgroundImage : backgroundImage_2x
                      }) ${
                        hasGradientBackground
                          ? `, radial-gradient(farthest-corner at 0 150%, ${currentAreaColors[0]} 0%, ${currentAreaColors[1]} 100%)`
                          : ''
                      }`
                    }}>
                    <span className="sub-title">{area}</span>
                    <h3 dangerouslySetInnerHTML={{ __html: title }}></h3>
                    <span className="description">{description}</span>
                  </animated.button>
                )}
              </Spring>
            )}
          </VisibilitySensor>
        );
      })}
    </ServicesSection>
  );
};
