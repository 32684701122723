import { IconAvatar, IconStar } from 'assets/icons';
import format from 'date-fns/format';
import PTLocale from 'date-fns/locale/pt';
import React from 'react';
import { CommentData } from 'types';

import { Card } from './styles';

export const CommentCard: React.FC<CommentData> = ({
  comment,
  name,
  id,
  photo,
  rating,
  time
}: CommentData) => {
  return (
    <Card className={id}>
      {photo ? (
        <img src={photo} alt={name} className="avatar" />
      ) : (
        <IconAvatar className="avatar" />
      )}
      <span>
        <span className="rating">
          <IconStar className={rating > 1 ? 'on' : ''} />
          <IconStar className={rating > 2 ? 'on' : ''} />
          <IconStar className={rating > 3 ? 'on' : ''} />
          <IconStar className={rating > 4 ? 'on' : ''} />
          <IconStar className={rating > 5 ? 'on' : ''} />
        </span>
        <h3>{name}</h3>
        <time dateTime={time} className="date">
          {format(new Date(time), "d 'de' MMMM, yyyy", {
            locale: PTLocale
          })}
        </time>
        <span className="comment">{comment}</span>
      </span>
    </Card>
  );
};
