import 'react-toastify/dist/ReactToastify.css';

import { LogoHi } from 'assets/vectors';
import { Button, IconButton } from 'components';
import { Input } from 'components/elements/Form';
import { useFormik } from 'formik';
import React, { useCallback } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { animated, useSpring } from 'react-spring';
import { Slide, toast, ToastContainer } from 'react-toastify';
import * as Yup from 'yup';

import { fadeInConfig, fadeInWidthDelayConfig } from '../animations';
import { Form, Main, Section } from '../styles';

interface FormValues {
  email: string;
}

export const PasswordRecover: React.FC = () => {
  const history = useHistory();
  const initialValues = { email: '' };
  const fadeIn = useSpring(fadeInConfig);
  const fadeInWidthDelay = useSpring(fadeInWidthDelayConfig);

  const validationSchema = Yup.object().shape({
    email: Yup.string().required('email obrigatório').email('verifique email inserido')
  });

  const onSubmit = useCallback(({ email }: FormValues) => {
    toast.dark(`verifique o seu email: ${email}`, {
      position: 'bottom-center',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      delay: 500,
      pauseOnFocusLoss: true,
      transition: Slide,
      draggablePercent: 60,
      role: 'alert'
    });

    setTimeout(() => history.push('/reset'), 5000);
  }, []);

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema
  });

  return (
    <Main>
      <Section style={fadeIn}>
        <IconButton
          icon="back"
          color="#fff"
          className="back-button"
          onClick={() => history.goBack()}
        />
        <Link to="/">
          <LogoHi className="logo" />
        </Link>
        <div className="description">
          <h1>Recupere a sua password</h1>
          <p>Insira o seu endereço de email associado à conta Hi!</p>
        </div>
        <Form onSubmit={formik.handleSubmit} style={fadeInWidthDelay}>
          <Input
            type="email"
            id="email"
            placeholder="email"
            autoComplete="username"
            alert={formik.touched.email && formik.errors.email ? true : false}
            {...formik.getFieldProps('email')}
          />
          <Button size="lg" type="submit">
            Recuperar
          </Button>
        </Form>
        <animated.div style={fadeInWidthDelay}>
          <Link to="/login">Login</Link>
        </animated.div>
      </Section>
      <ToastContainer limit={1} newestOnTop={false} rtl={false} />
    </Main>
  );
};
