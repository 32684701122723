import styled from 'styled-components';

export const InputContainer = styled.div`
  position: relative;

  & + * {
    margin-top: 16px;
  }

  & + button {
    margin-top: 48px;
  }

  label {
    font-size: 20px;
    font-weight: var(--f-light);
    color: var(--c-white);
    letter-spacing: 0.5px;
    margin-bottom: 8px;
    display: inline-block;
  }

  input {
    width: 100%;
    height: 5.2rem;
    font-size: 1.6rem;
    line-height: 2rem;
    font-weight: var(--f-light);
    color: var(--c-white);
    background: transparent;
    border: 1px solid var(--c-la-vanda-200);
    border-radius: 1.6rem;
    padding: 1.2rem;

    transition: background-color 600ms ease, font-size 300ms ease, border 300ms ease;

    &::placeholder {
      font-size: 1.6rem;
      color: var(--c-la-vanda-200);
      letter-spacing: 0.5px;
      transition: color 300ms ease;
    }

    &:focus {
      border-color: var(--c-white);

      &::placeholder {
        color: var(--c-white);
      }
    }

    @media screen and (min-height: 660px) {
      font-size: 2rem;
      padding: 1.6rem;
      height: 6.2rem;

      &::placeholder {
        font-size: 2rem;
      }
    }
  }
`;

export const AlertIcon = styled.div`
  position: absolute;
  top: 50%;
  right: 1.6rem;
  transform: translateY(-50%);
  line-height: 0;
`;
