import { Home } from 'pages/Private/Home';
import { Switch } from 'react-router-dom';

import { Route } from './Route';

export const GeneralPrivateRoutes: React.FC = () => (
  <Switch>
    <Route path="/home" component={Home} isPrivate />
  </Switch>
);
