import styled from 'styled-components';

export const Section = styled.section`
  background: var(--c-silver-grey-100);
  padding: 9.6rem 2rem 1.2rem;

  .sub-title {
    color: var(--c-phantom-grey-900);
    display: block;
    margin-bottom: 1rem;
    text-align: left;
  }

  .swiper-container {
    padding-bottom: 4rem;

    .swiper-pagination-fraction,
    .swiper-pagination-custom,
    .swiper-container-horizontal > .swiper-pagination-bullets {
      bottom: 0;
    }
  }
`;
