import {
  CheckUp,
  HomeHealth,
  OnlineAppointment,
  SymptomsCheck,
  VirtualCard
} from 'pages/Private/Health';
import { Switch } from 'react-router-dom';

import { Route } from './Route';

export const HealthRoutes: React.FC = () => (
  <Switch>
    <Route path="/saude/sintomas" component={SymptomsCheck} isPrivate />
    <Route path="/saude/check-up" component={CheckUp} isPrivate />
    <Route path="/saude/cartao" component={VirtualCard} isPrivate />
    <Route path="/saude/consulta" component={OnlineAppointment} isPrivate />
    <Route path="/saude/inicio" component={HomeHealth} isPrivate />
  </Switch>
);
