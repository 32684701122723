import styled from 'styled-components';

export const Card = styled.div`
  background: var(--c-white);
  border-radius: 2rem;
  padding: 2rem;
  position: relative;
  margin-bottom: 2rem;
  width: 100%;
  height: 32rem;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  .card-body {
    flex: 1;
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    figure {
      position: relative;

      img {
        width: 9.2rem;
        height: 9.2rem;
        object-fit: cover;
        border-radius: 50%;
        margin: 1rem 0;
        filter: brightness(1.2);
      }

      .price {
        background: var(--c-movement-2);
        color: var(--c-white);
        font-weight: var(--f-semi-bold);
        letter-spacing: 0.1rem;
        line-height: 1;
        padding: 0.2rem 1rem;
        border-radius: 2rem;
        position: absolute;
        top: 1.6rem;
        right: -1.6rem;
        z-index: 1;
      }
    }

    .locations {
      font-size: 1.4rem;
    }

    h2 {
      line-height: 1;
      margin: 0;
      color: var(--c-movement-2);
    }

    .platforms {
      line-height: 1;

      span {
        text-transform: uppercase;
        font-size: 1.3rem;
      }
    }

    & > span {
      display: block;
    }

    .wrapper,
    .bio-content {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      gap: 0.2rem;
    }

    .rating {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      position: absolute;
      top: 2rem;
      left: 2rem;

      svg {
        width: 1.6rem;
        height: 1.6rem;
        fill: var(--c-silver-grey-100);

        &.on {
          fill: var(--c-movement-2);
        }

        & + svg {
          margin-left: 0.4rem;
        }
      }
    }

    .tags {
      width: 100%;
      margin-top: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      gap: 0.8rem;
    }
  }

  .card-footer {
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: space-between;

    .languages {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 0.4rem;

      span {
        color: var(--c-movement-2);
        border: 1px solid var(--c-movement-2);
        line-height: 0;
        width: 2.8rem;
        height: 2.8rem;
        border-radius: 0.8rem;
        font-size: 1.4rem;
        font-weight: var(--f-semi-bold);

        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  &.standard {
    height: unset;
    gap: 2rem;

    .wrapper {
      flex-direction: row;
      justify-content: space-between;

      figure {
        width: 9.2rem;

        .price {
          top: unset;
          bottom: 0;
          right: 50%;
          transform: translateX(50%);
        }
      }

      .bio-content {
        width: calc(100% - 10.2rem);
        align-items: flex-start;
        padding-top: 4rem;
      }
    }

    .tags {
      margin-top: 2rem;
    }

    .rating {
      top: 4rem;
      left: 12rem;
    }
  }

  &.dark {
    background: var(--c-night-blue-900);

    .platforms span,
    .locations span,
    .tags button {
      color: var(--c-phantom-grey-200);
      transition: color 300ms ease;
    }

    .tags button:hover {
      color: var(--c-white);
    }

    .card-body .rating svg {
      fill: var(--c-old-navy-blue-800);

      &.on {
        fill: var(--c-movement-2);
      }
    }

    .languages span {
      border-color: var(--c-old-navy-blue-800);
      color: var(--c-old-navy-blue-800);
      transition: border 300ms ease, color 300ms ease;

      &:hover {
        background: var(--c-old-navy-blue-800);
        color: var(--c-white);
      }
    }
  }
`;
