interface SvgEl extends React.HTMLAttributes<HTMLOrSVGElement> {
  height: string;
  width: string;
}

export const IconsGraphs: React.FC<SvgEl> = ({ height, width, ...props }: SvgEl) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height={height} width={width} {...props}>
      <defs>
        <clipPath id="i-nutrition">
          <path d="M59.3 22.1a24.4 24.4 0 00-20 3.6c1-19.6 7.9-23.2 8-23.3a1.3 1.3 0 00-1-2.3c-.2.1-4.2 2-7 10.5A14.5 14.5 0 0036 5.3a14.7 14.7 0 00-14.3-3.8 1.3 1.3 0 00-1 .9 14.8 14.8 0 003.9 14.3A14.7 14.7 0 0035 21a15.4 15.4 0 002.3-.2l-.4 5a24.4 24.4 0 00-20-3.7C3.2 26-3.5 44.6 1.8 63a40 40 0 0017.9 24 21.1 21.1 0 0018.3 1.4l1.8.7a19.4 19.4 0 005.6.8 22.8 22.8 0 0010.8-3c8-4.4 14.8-13.3 17.8-24 5.4-18.4-1.3-36.8-14.8-40.9zm-47 25a1.3 1.3 0 01-2.6 0v-.5a1.3 1.3 0 012.6 0zm13.2-14.4c-.4 0-9.6.5-12.3 10.9a1.3 1.3 0 01-1.3 1 1.2 1.2 0 01-.3 0 1.3 1.3 0 01-.9-1.6 16.5 16.5 0 0114.8-12.8 1.3 1.3 0 010 2.6z" />
        </clipPath>
        <clipPath id="i-bonus">
          <path d="M95 34.4l-32.8-4.8L47.5 0 32.8 29.6 0 34.4l23.8 23L18 90l29.4-15.4L76.9 90l-5.7-32.6zm-15.6 3.9a.9.9 0 01.3-.2 1 1 0 011 .2 1 1 0 01.4.7 1 1 0 01-.1.4 1 1 0 01-.2.3 1 1 0 01-.7.3 1 1 0 01-.4 0 1 1 0 01-.6-.6 1 1 0 010-.4 1 1 0 01.3-.7zm-31-17.8A1 1 0 0149 19a1 1 0 011.4.5l7.4 15 19.4 3a1 1 0 01-.1 2h-.2l-20.4-3z" />
        </clipPath>
        <clipPath id="i-movement">
          <path d="M90.3 60.4C86.9 46 79 35.9 48.6 26 19.6 16.7 2.6.4 2.4.3a1 1 0 00-1-.3 1 1 0 00-.7.7c-.4.8-7 20.2 28.4 38.8-2.9-.3-8.3-1.6-18.2-6.9a1 1 0 00-1 0 1 1 0 00-.5 1 25 25 0 005.4 12.7c4.3 5.4 12.7 12 29.1 14-2.7.5-7.2.7-14.5-1.2a1 1 0 00-.9.2 1 1 0 00-.4.9 16.6 16.6 0 002.4 7.3c2 3.4 6.3 7.8 15 9 3 .5 5.6.8 8.2 1 12 1.4 20.6 2.4 26.4 12a1 1 0 00.8.4 1 1 0 00.9-.3c.4-.5 12.1-14 8.5-29.2zm-5.1 13.2l-.1.3a1 1 0 01-1 .8 1.1 1.1 0 01-.3 0 1 1 0 01-.7-1.3v-.3a1 1 0 011.3-.7 1 1 0 01.8 1.2zm.5-2.8a1 1 0 01-1 1h-.1a1 1 0 01-1-1.3 21.8 21.8 0 00-.3-7.8c-2.9-12-9.6-20.5-35.6-29a1 1 0 01-.7-1.3 1 1 0 011.3-.6c27 8.7 34 17.8 37 30.4a23.8 23.8 0 01.4 8.6z" />
        </clipPath>
        <clipPath id="i-health" viewBox="0 0 108 90">
          <path d="M87.2 8.8A30 30 0 0048 6 30 30 0 006.5 48.7l40.6 41a1.2 1.2 0 00.9.3 1.2 1.2 0 00.9-.4L89.5 49a1.3 1.3 0 00.4-.8 30 30 0 00-2.7-39.3zM11 33a1.2 1.2 0 01-1.2 1.2A1.2 1.2 0 018.6 33V32a1.2 1.2 0 012.4 0V33zm19.4-22.3a22.2 22.2 0 00-18.7 16.7 1.2 1.2 0 01-1.2 1 1 1 0 01-.3 0 1.2 1.2 0 01-1-1.5 24.8 24.8 0 0121-18.6 1.2 1.2 0 01.2 2.4z" />
        </clipPath>
      </defs>
    </svg>
  );
};
