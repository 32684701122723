import styled from 'styled-components';

export const ServicesSection = styled.section`
  background: var(--c-silver-grey-100);
  padding: 3.6rem 2rem;

  h2 {
    font-weight: var(--f-bold);
    line-height: 1;
  }

  p {
    max-width: 300px;
  }

  .card {
    width: 100%;
    height: 33.6rem;
    margin-top: 3rem;
    padding: 4.4rem 2.4rem;
    border-radius: 1.4rem;
    border: none;

    background-size: 100% 100%;
    background-size: cover;
    background-position: center;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;

    transition: background-position 3000ms ease;

    span,
    h3 {
      color: var(--c-white);
      text-align: left;
      width: 80%;
      max-width: 22rem;
    }

    h3 {
      font-size: 3.2rem;
      flex: 1;
    }

    .description {
      max-width: 15rem;
    }

    &:hover {
      background-position: top right;
    }
  }

  .swiper-pagination-bullet-active {
    background: var(--c-white);
  }
`;
