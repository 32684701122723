import styled from 'styled-components';

export const TagButton = styled.button`
  font-size: 1.2rem;
  letter-spacing: 0.1rem;
  border: 1px solid var(--c-smoke-grey-400);
  background: transparent;
  padding: 1rem;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 1.4rem;
  border-radius: 1rem;
  white-space: nowrap;
`;
