import { AuthProvider } from './useAuth';
import { LocationProvider } from './useLocation';
import { VideosProvider } from './useVideos';

export const AppProvider: React.FC = ({ children }: { children?: React.ReactNode }) => {
  return (
    <AuthProvider>
      <LocationProvider>
        <VideosProvider>{children}</VideosProvider>
      </LocationProvider>
    </AuthProvider>
  );
};
