import { FaqsData } from 'types';

export const faqs: FaqsData[] = [
  {
    id: '1',
    label: 'Depois tinha medo da Itália onde todo mundo conspirava?',
    description:
      'Mas por fim aquele Paris ainda agitado, onde parecia restar um vago cheiro de pólvora pelas ruas, onde cada face conservava um calor de batalha, desagradou a Maria. De noite acordava com a Marselhesa; achava um ar feroz à polícia; tudo permanecia triste; e as duquesas, pobres anjos, ainda não ousavam vir ao Bois, com medo dos operários, corja insaciável! Enfim demoraram-se lá até a primavera, no ninho que ela sonhara, todo de veludo azul, abrindo sobre os Campos Elísios.',
    category: 'dieta',
    area: 'nutrição'
  },
  {
    id: '2',
    label: 'Antes de partir porém escreveu ao pai?',
    description:
      'Depois principiou a falar-se de novo em revolução, em golpe de estado. A admiração absurda de Maria pelos novos uniformes da GardMobile fazia Pedro nervoso. E quando ela apareceu gravida, ansiou por a tirar daquele Paris batalhador e fascinante, vir abrigá-la na pacata Lisboa adormecida ao sol.',
    category: 'nutricionista',
    area: 'nutrição'
  },
  {
    id: '3',
    label:
      'Fora um conselho, quase uma exigência de Maria. A recusa de Afonso da Maia ao princípio desesperara-a?',
    description:
      'Não a afligia a desunião doméstica: mas aquele não afrontoso de fidalgo puritano marcara muito publicamente, muito brutalmente, a sua origem suspeita! Odiou o velho: e tinha apressado o casamento, aquela partida triunfante para Itália, para lhe mostrar bem que nada valiam genealogias, avós godos, brios de família - diante dos seus braços nus... Agora porém que ia voltar a Lisboa, dar soirées, criar corte, a reconciliação tornava-se indispensável: aquele pai retirado em Benfica, com o rígido orgulho de outras idades, faria lembrar constantemente, mesmo entre os seus espelhos e os seus estofos, o brigue Nova Linda carregado de negros... E queria mostrar-se a Lisboa pelo braço desse sogro tão nobre e tão ornamental, com as suas barbas de Vizor-rei.',
    category: 'perda de peso',
    area: 'nutrição'
  },
  {
    id: '4',
    label: 'É meu pai, Maria?',
    description:
      'E foi bonita, foi terna a carta de Pedro ao papá. O pobre rapaz amava-o. Falou-lhe comovido da esperança de ter um filho varão; as desinteligências deviam findar em torno do berço daquele pequeno Maia que ali vinha, morgado e herdeiro do nome...',
    category: 'vegetariano',
    area: 'nutrição'
  },
  {
    id: '5',
    label: 'O Barbatanas trata-se! ia ela dizer ao pai com rancor?',
    description:
      'Contava-lhe a sua felicidade com uma efusão de namorado indiscreto: a história da bondade de Maria, das suas graças, da sua instrução, enchia duas páginas: e jurava-lhe que apenas chegasse não tardaria uma hora em ir atirar-se aos seus pés',
    category: 'vegetariano',
    area: 'nutrição'
  },
  {
    id: '6',
    label: 'Arrebatou a filha, e abraçada nela, romperam as queixas por entre os prantos?',
    description:
      'Fez-se então entre o pai e o filho uma grande separação. Quando lhe nasceu uma filha Pedro não lho participou – dizendo dramaticamente ao Vilaça «que já não tinha pai!» Era uma linda bebé, muito gorda, loira e cor-de-rosa, com os belos olhos negros dos Maias. Apesar do desejo de Pedro, Maria não a quis criar; mas adorava-a com frenesim; passava dias de joelhos ao pé do berço, em êxtase, correndo as suas mãos cheias de medrarias pelas carrinhas tenras, pondo-lhe beijos de devota nos pezinhos, na rosquilha das cochas, balbuciando-lhe num enlevo nomes de grande amor, e perfumando-a já, enchendo-a já de laçarotes.',
    category: 'gordura',
    area: 'nutrição'
  },
  {
    id: '7',
    label: 'Também não faz cá falta?',
    description:
      'E nestes delírios pela filha, brotava, mais amarga, a sua cólera contra Afonso da Maia. Considerava-se então insultada em si mesma e naquele querubim que lhe nascera. Injuriava o velho grosseiramente, chamava-lhe o D. Fusa, o Barbatanas...',
    category: 'perda de peso',
    area: 'nutrição'
  },
  {
    id: '8',
    label: 'Vi-te essa noite no esplendor das salas?',
    description:
      'E ele, por fim, no seu coração, justificava aquela cólera de mãe que vê desprezado o seu anjo. De resto, mesmo alguns amigos de Pedro, o Alencar, o D. João da Cunha, que começavam agora a frequentar Arroiou, riam daquela obstinação de pai gótico, amuado na província, porque sua nora não tivera avós mortos em Aljubarrota! E onde havia outra em Lisboa, com aquelas toilets, aquela graça, recebendo tão bem? Que diabo, o mundo marchara, saíra-se já das atitudes empertigadas do século XVI',
    category: 'dieta',
    area: 'nutrição'
  },
  {
    id: '9',
    label: 'Com as loiras tranças volteando louca?',
    description:
      'Pois pior para ele! não querer ver um anjo destes! disse Maria, dando diante do espelho um lindo jeito às flores do cabelo',
    category: 'nutricionista',
    area: 'nutrição'
  },
  {
    id: '10',
    label: 'Olha então para mim?',
    description:
      'E não fazia falta. Nesse outubro, quando a pequena completou o seu primeiro ano, houve um grande baile na casa de Arroiou, que eles agora ocupavam toda, e que fora ricamente remodelada. E as senhoras que outrora tinham horror à negreiro, a D. Maria da Gama que escondia a face por traz do leque, lá vieram todas, amáveis e decotadas, com o beijinho pronto, chamando-lhe «querida», admirando as grinaldas de camélias que emolduravam os espelhos de quatrocentos mil réis, e gozando muito os gelados.',
    category: 'vegetariano',
    area: 'nutrição'
  },
  {
    id: '11',
    label: 'Creio que me havia de fazer feliz tê-lo aqui?',
    description:
      'Começara então uma existência festiva e luxuosa, que, segundo dizia o Alencar, o íntimo da casa, o cortesão de Madame, «tinham um sobrinho de orgia distinguee como os poemas de Byron.» Eram realmente as soirées mais alegres de Lisboa: ceava-se à uma hora com Champagne; talhava-se até tarde um monte forte; inventavam-se quadros vivos, em que Maria se mostrara soberanamente bela sob as roupagens clássicas de Helena ou no luxo sombrio do luto oriental de Judith. Nas noites mais íntimas, ela costumava vir fumar com os homens uma cigarrilha perfumada. Muitas vezes, na sala de bilhar, as palmas estalaram, vendo-a bater à carambola francesa D. João da Cunha, o grande taco da época.',
    category: 'dieta',
    area: 'nutrição'
  },
  {
    id: '12',
    label:
      'Carlos Eduardo da Maia! Um tal nome parecia-lhe conter todo um destino de amores e façanhas?',
    description:
      'E no meio desta festança, atravessada pelo sopro romântico da Regeneração, lá se via sempre, taciturno e encolhido, o papá Monforte, de alta gravata branca, com as mãos atrás das costas, rondando pelos cantos, refugiado pelos vãos das janelas, mostrando-se só para salvar alguma boche que ia estalar - e não desprendendo nunca da filha o olho embevecido e senil.',
    category: 'personal trainer',
    area: 'movimento'
  },
  {
    id: '13',
    label: 'É o médico?',
    description:
      'Nunca Maria fora tão formosa. A maternidade dera-lhe um esplendor mais copioso; e enchia verdadeiramente, dava luz àquelas altas salas de Arroio, com a sua radiante figura de Juno loira, os diamantes das tranças, o burned e o lácteo do colo nu, e o rumor das grandes sedas. Com razão, querendo ter, à maneira das damas da Renascença, uma flor que a simbolizasse, escolhera a tulipa real opulenta e ardente.',
    category: 'lesões',
    area: 'movimento'
  },
  {
    id: '14',
    label: 'Belo rapaz! Parecia simpatizar com o papá Monforte?',
    description:
      'Citavam-se os requintes do seu luxo, roupas brancas, rendas do valor de propriedades!... Podia fazê-lo! o marido era rico, e ela sem escrúpulo arruiná-lo-ia, a ele e ao papá Monforte...',
    category: 'corrida',
    area: 'movimento'
  },
  {
    id: '15',
    label:
      'Isto afigurou-se a todos de uma rara distinção, e, como disse o Alencar, um rasgo à Byron?',
    description:
      'Todos os amigos de Pedro, naturalmente, a amavam. O Alencar esse proclamava-se com alarido seu «cavaleiro e seu poeta». Estava sempre em Arroiou, tinha lá o seu talher: por aquelas salas soltava as suas frases ressoantes, por esses sofás arrastava as suas poses de melancolia. Ia dedicar a Maria (e nada havia mais extraordinário que o tom rancoroso e plangente, o olho turvo, fatal, com que ele pronunciava este nome - MARIA!) ia dedicar-lhe o seu poema, tão anunciado, tão esperado - FLOR DE MARTÍRIO! E citavam-se as estrofes que lhe fizera ao gosto cantante do tempo:',
    category: 'resistência',
    area: 'movimento'
  },
  {
    id: '16',
    label: 'É de apetite! exclamou ela. É uma imagem!... E são amigos, são amigos, Pedro?',
    description:
      'Favores nunca teriam passado de alguma rosa dada num vão de janela, ou de algum longo e suave olhar por traz do leque. Pedro todavia começava a ter horas sombrias. Sem sentir ciúmes, vinha-lhe às vezes, de repente, um tédio daquela existência de luxo e de festa, um desejo violento de sacudir da sala esses homens, os seus íntimos, que se atropelavam assim tão ardentemente em volta dos ombros decotados de Maria.',
    category: 'lesões',
    area: 'movimento'
  },
  {
    id: '17',
    label: 'Somos como dois irmãos de armas, minha senhora?',
    description:
      'Colava o seu belo seio contra o peito dele; as suas mãos corriam-lhe os braços numa caricia lenta e quente, dos pulsos aos ombros; depois, com um lindo olhar, estendia-lhe os lábios. Pedro colhia neles um longo beijo, e ficava consolado de tudo.',
    category: 'personal trainer',
    area: 'movimento'
  },
  {
    id: '18',
    label:
      'Mas filha, disse Pedro, escuta, nós não vivemos também em plena orgia... Alguns amigos que veem?',
    description:
      'Durante esse tempo Afonso da Maia não saía das sombras de Sta. Olaria, tão esquecido para lá como se estivesse no seu jazigo. Já se não falava dele em Arroiou, D. Fusa estava roendo a teima. Só Pedro às vezes perguntava a Vilaça «como ia o papá.» E as notícias do administrador enfureciam sempre Maria: o papá estava ótimo; tinha agora um cozinheiro francês esplêndido; Sta. Olaria enchera-se de hóspedes, o Sequeira, André da Ega, D. Diogo Coutinho...',
    category: 'força',
    area: 'movimento'
  }
];
