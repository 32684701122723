import styled from 'styled-components';

export const Card = styled.div`
  width: 100%;
  min-height: 30rem;
  perspective: 60rem;
  margin: 2rem 0;

  .card-face {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 9999;

    border-radius: 2rem;

    transition: transform 1000ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
    transform-style: preserve-3d;
    backface-visibility: hidden;

    &.card-front {
      transform: rotateY(0);
      background: var(--c-silver-grey-100);
      padding: 1rem;

      .card-body {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 2rem;
        height: 26rem;

        button {
          width: 12rem;
          height: 12rem;
          background: var(--c-silver-grey-100);
          border: 6px solid var(--c-nordic-blue-200);
          border-radius: 50%;
          padding: 2rem;

          svg {
            width: 100%;
            height: 100%;
            stroke: var(--c-nordic-blue-200);
          }
        }

        span {
          margin-top: 1.6rem;
        }

        .score {
          font-size: 2rem;
          font-weight: var(--f-semi-bold);
          margin-bottom: 1rem;
        }

        .icon {
          width: 12rem;
          height: 12rem;
          object-fit: cover;
          border-radius: 50%;
        }

        .activity {
          font-weight: var(--f-semi-bold);
        }

        .duration {
          margin: 0;
        }
      }

      .card-footer {
        display: grid;
        grid-template-columns: 1fr 6fr 1fr;
        padding-top: 2rem;

        & > button {
          border: none;
          background: var(--c-silver-grey-100);
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;

          svg {
            width: 100%;
            height: 4rem;
            stroke: var(--c-ocean-blue-300);
          }

          &:disabled {
            cursor: default;

            svg {
              opacity: 0.2;
              stroke: var(--c-smoke-grey-400);
            }
          }
        }

        .date-container {
          & > span {
            display: block;
            text-align: center;
            color: var(--c-night-blue-900);

            &.weekday {
              font-size: 2rem;
              font-weight: var(--f-semi-bold);
            }

            &.date {
              font-size: 1.4rem;
            }
          }
        }
      }
    }

    &.card-back {
      transform: rotateY(-180deg);
      background: var(--c-night-blue-900);
      padding: 1rem;

      .card-body {
        padding: 3.6rem 2rem 2rem 2rem;
        height: 30rem;

        .field-container {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
          position: relative;

          & + .field-container {
            margin-top: 3rem;
          }

          label {
            background: var(--c-night-blue-900);
            font-size: 1.2rem;
            text-transform: uppercase;
            text-align: center;
            width: 100%;
            padding: 0 0.8rem;
            color: var(--c-nordic-blue-200);
            letter-spacing: 0.1rem;
          }

          input {
            width: 100%;
            padding: 1rem;
            background: transparent;
            border: none;
            text-align: center;
            font-size: 2.8rem;
            color: var(--c-white);

            &::placeholder {
              color: var(--c-white);
            }
          }
        }
      }

      .card-footer {
      }
    }
  }

  &.flipped {
    animation-play-state: running;
    .card-front {
      transform: rotateY(180deg);
    }

    .card-back {
      transform: rotateY(0);
    }
  }
`;
