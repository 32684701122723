import { useAuth } from 'hooks/useAuth';
import React from 'react';
import {
  Redirect,
  Route as ReactDOMRoute,
  RouteProps as ReactDOMRouteProps
} from 'react-router-dom';

interface RouteProps extends ReactDOMRouteProps {
  isPrivate?: boolean;
  component: React.ComponentType;
}

export const Route: React.FC<RouteProps> = ({
  isPrivate = false,
  component: Component,
  ...props
}: RouteProps) => {
  const { user } = useAuth();

  // isPrivate && isLogged => OK
  // isPrivate && !isLogged => '/login'
  // !isPrivate && isLogged -> '/home'
  // !isPrivate && !isLogged => OK
  return (
    <ReactDOMRoute
      {...props}
      render={({ location }) => {
        return isPrivate === !!user ? (
          <Component />
        ) : (
          <Redirect to={{ pathname: isPrivate ? '/login' : '/home', state: { from: location } }} />
        );
      }}
    />
  );
};
