import { useSpring } from 'react-spring';

import { slowDropConfig } from './animations';
import { MainSection } from './styles';

type MainProps = React.HTMLProps<HTMLDivElement>;

export const Main: React.FC<MainProps> = ({ children }: MainProps) => {
  const slowDrop = useSpring(slowDropConfig);
  return <MainSection style={slowDrop}>{children}</MainSection>;
};
