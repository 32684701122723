import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  :root {
    --c-hi-blue: #00a0df;
    --c-hi-purple: #b11cab;
    --c-white: #fff;
    --c-silver-grey-100: #F4F6FA;
    --c-phantom-grey-200: #DBDDE1;
    --c-smoke-grey-400: #9a9a9a;
    --c-la-vanda-200: #A18FE8;
    --c-pink-cocktail-200: #DC7AC3;
    --c-pink-panther-400: #C62C9E;
    --c-purple-fog-200: #A766DA;
    --c-purple-mist-500: #5D4ADE;
    --c-purple-twilight-600: #6A00C6;
    --c-cristal-blue-100: #9FE4E1;
    --c-aqua-blue-200: #5AD1CE;
    --c-nordic-blue-200: #80C1FC;
    --c-ocean-blue-300: #0199FF;
    --c-old-navy-blue-800: #4C5980;
    --c-night-blue-900: #14233A;
    --c-ice-salmon-100: #FEB3B4;
    --c-alaska-salmon-300: #FF8280;
    --c-yellow-sand-100: #FFDE86;

    --c-main-1: var(--c-purple-mist-500);
    --c-main-2: var(--c-purple-twilight-600);
    --c-nutrition-1: var(--c-alaska-salmon-300);
    --c-nutrition-2: var(--c-pink-panther-400);
    --c-movement-1: var(--c-aqua-blue-200);
    --c-movement-2: var(--c-ocean-blue-300);
    --c-health-1: var(--c-ocean-blue-300);
    --c-health-2: var(--c-purple-twilight-600);
    --c-bonus-1: var(--c-yellow-sand-100);
    --c-bonus-2: var(--c-ice-salmon-100);

    --f-font-primary: 'Work Sans', sans-serif;
    --f-font-secondary: 'Source Code Pro', monospace;
    --f-light: 300;
    --f-regular: 400;
    --f-semi-bold: 600;
    --f-bold: 700;
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;
  }

  html, body { font-size: 10px; }

  body {
    background: var(--c-white);
    color: var(--c-night-blue-900);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: antialiased;
    
    &.purple-bg {
      background: var(--c-night-blue-900);
    }
  }

  body, input, button, textarea {
    font-family: var(--f-font-primary);
    font-weight: var(--f-light);
    font-size: 1.6rem;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
  }

  h1, h2, h3, h4, h5, h6, strong {
    font-weight: 900;
    color: var(--c-night-blue);
    line-height: 1.2;
    margin-bottom: .8rem;
  }

  h1 { 
    font-size: 2.1rem;
    @media (min-width: 1024px) { font-size: 3.2rem; }
  }

  p { line-height: 1.4; }

  .sub-title {
    font-size: 1.3rem;
    letter-spacing: 0.2rem;
    color: var(--c-white);
    font-weight: var(--f-light);
    text-transform: uppercase;
    text-align: center;
  }

  img {
    user-select: none;
    -webkit-user-select: none;
    -webkit-user-drag: none;
    -webkit-app-region: no-drag;
    max-width: 100%;
  	display: block;
  }

  button { cursor: pointer; }

  ::selection {
    background: var(--c-ocean-blue-300);
    color: var(--c-white);
  }

  // modal
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    z-index: 4;
    transform: translateX(-50);
    background-color: rgba(255, 255, 255, 0.75);
  }
  
  .modal-content {
    width: 90%;
    max-width: 45rem;
    padding: 2rem 0;
    border-radius: 2rem;
    background: var(--c-night-blue-900);
    position: relative;

    * {
      color: var(--c-white);
    }

    .header {
      padding: 1.6rem 2rem;

      .close-btn {
        position: absolute;
        top: 1rem;
        right: 1rem;
      }
    }

    .body {
      padding: 2rem;
      margin-bottom: 1rem;
      width: 100%;
      height: 48rem;
      overflow-y: auto;

      ol {
        padding-left: 2rem;

        li {
          font-size: 1.4rem;
          line-height: 1.6;

          strong,
          span {
            display: block;
            line-height: 1.4;
          }

          strong {
            font-weight: var(--f-semi-bold);
          }

          & + li {
            margin-top: 2rem;
          }
        }
      }
    }
  }


  @keyframes Gradient {
    0%,
    100% {
      background-position: 0% 50%;
      background-size: 400% 400%;
    }
    50% {
      background-position: 100% 50%;
      background-size: 800% 200%;
    }
  }

  //@see: https://newbedev.com/retina-displays-high-res-background-images
  @mixin background-2x($path, $ext: "png", $w: auto, $h: auto, $pos: left top, $repeat: no-repeat) {

    $at1x_path: "#{$path}.#{$ext}";
    $at2x_path: "#{$path}@2x.#{$ext}";

    background-image: url("#{$at1x_path}");
    background-size: $w $h;
    background-position: $pos;
    background-repeat: $repeat;

    @media all and (-webkit-min-device-pixel-ratio : 1.5),
    all and (-o-min-device-pixel-ratio: 3/2),
    all and (min--moz-device-pixel-ratio: 1.5),
    all and (min-device-pixel-ratio: 1.5) {
      background-image: url("#{$at2x_path}"); 
    }
  }
`;
