import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  padding: 2.4rem 1rem;
  border-radius: 0.8rem;
  position: relative;
  overflow: hidden;

  .label {
    font-weight: var(--f-semi-bold);
    font-size: 1.6rem;
    text-transform: uppercase;
    letter-spacing: 0.1rem;
  }

  .label,
  .status,
  .time {
    color: var(--c-white);
    display: block;
  }

  .status {
    font-weight: var(--f-semi-bold);
    font-size: 1.4rem;
    margin-bottom: 0.4rem;

    strong {
      color: var(--c-white);
      font-size: 2.4rem;
    }
  }

  .time {
    opacity: 0.8;
    font-size: 1.4rem;
    font-weight: var(--f-semi-bold);
  }

  .graph-outline {
    fill: none;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 9.6rem;
    height: 9.2rem;

    z-index: 1;

    .reflex {
      fill: var(--c-white);
    }

    .icon {
      stroke: var(--c-white);
      stroke-width: 2px;
    }

    &.movement {
      transform: translate(-50.5%, -76%);
    }

    &.nutrition {
      transform: translate(-53%, -75%);
    }

    &.health {
      transform: translate(-50%, -75%);
    }

    &.bonus {
      width: 9.8rem;
      height: 12rem;
      transform: translate(-49%, -78%);
    }
  }

  .graph {
    width: 9rem;
    height: 9rem;
    position: relative;
    margin: 2rem auto;
    overflow: hidden;

    &.health {
      width: 9.6rem;
    }

    &.movement {
      width: 9.2rem;
    }

    &.nutrition {
      width: 8.2rem;
    }

    &.bonus {
      width: 9.3rem;
    }

    .progress {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 2;
      background: var(--c-white);

      transition: height 300ms ease;
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      filter: brightness(0.85);
      z-index: 1;
    }
  }

  &.md {
    padding: 10rem 1rem;
    border-radius: 0;

    &::before {
      content: '';
      position: absolute;
      top: -310px;
      left: 50%;
      transform: translateX(-50%) skewY(352deg);
      width: calc(100% + 20%);
      height: 360px;
      background: var(--c-silver-grey-100);
    }

    &::after {
      content: '';
      position: absolute;
      bottom: -300px;
      left: 50%;
      transform: translateX(-50%) skewY(352deg);
      width: calc(100% + 20%);
      height: 360px;
      background: var(--c-silver-grey-100);
    }

    .graph-outline {
      width: 9.6rem;
      height: 9.2rem;

      &.movement {
        transform: translate(-50.7%, -70%) scale(1.5);
      }

      &.nutrition {
        transform: translate(-53%, -71%) scale(1.5);
      }

      &.health {
        transform: translate(-51%, -70%) scale(1.5);
      }

      &.bonus {
        width: 9.8rem;
        height: 12rem;
        transform: translate(-48%, -78%) scale(1.5);
      }
    }

    .graph {
      transform: scale(1.5);
      margin: 8rem auto 4rem;
    }

    .badge {
      position: absolute;
      top: 0;
      left: 0;
    }

    .caption {
      display: block;
      margin: 1rem auto 2rem;
      text-align: center;
      color: var(--c-white);
    }
  }

  &.nutrition {
    background: radial-gradient(
      farthest-corner at 150% 0%,
      var(--c-nutrition-2) 0%,
      var(--c-nutrition-1) 100%
    );

    .graph::after {
      background: radial-gradient(
        farthest-corner at 150% 0%,
        var(--c-nutrition-2) 0%,
        var(--c-nutrition-1) 100%
      );
    }
  }

  &.movement {
    background: radial-gradient(
      farthest-corner at -50% 150%,
      var(--c-movement-2) 0%,
      var(--c-movement-1) 100%
    );

    .graph::after {
      background: radial-gradient(
        farthest-corner at -50% 150%,
        var(--c-movement-2) 0%,
        var(--c-movement-1) 100%
      );
    }
  }

  &.health {
    background: radial-gradient(
      farthest-corner at -50% 150%,
      var(--c-health-2) 0%,
      var(--c-health-1) 100%
    );

    .graph::after {
      background: radial-gradient(
        farthest-corner at -50% 150%,
        var(--c-health-2) 0%,
        var(--c-health-1) 100%
      );
    }
  }

  &.bonus {
    background: radial-gradient(
      farthest-corner at 10% 80%,
      var(--c-bonus-1) 30%,
      var(--c-bonus-2) 100%
    );

    .graph::after {
      background: radial-gradient(
        farthest-corner at -50% 150%,
        var(--c-bonus-1) 0%,
        var(--c-bonus-2) 100%
      );
    }
  }
`;
