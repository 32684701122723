import { IconStar } from 'assets/icons';
import { IconButton, Tag } from 'components';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { colors } from 'static';

import { Card } from './styles';

interface PTCardProps {
  name: string;
  locations: string[];
  platforms: ('outdoor' | 'indoor' | 'online')[];
  images: {
    profile: string;
    profile_2x: string;
  };
  prices: number[];
  tags: string[];
  rating: number;
  slug: string;
  languages: ('PT' | 'EN' | 'ES' | 'FR')[];
  theme: 'light' | 'dark';
  size: 'highlight' | 'standard';
}

export const PTCard: React.FC<PTCardProps> = ({
  name,
  locations,
  platforms,
  images,
  prices,
  tags,
  rating,
  languages,
  slug,
  theme,
  size
}: PTCardProps) => {
  const { push } = useHistory();
  const { white, oceanBlue300 } = colors;
  const { profile, profile_2x } = images;

  const handleClick = useCallback((slug) => {
    push(`/movimento/pt/${slug}`);
  }, []);

  return (
    <Card className={`${theme} ${size}`}>
      <div className="card-body">
        <span className="wrapper">
          <figure>
            <span className="price">€{Math.min.apply(Math, prices)}</span>
            <img srcSet={`${profile_2x} 2x, ${profile}`} src={profile} alt={name} />
          </figure>

          <span className="bio-content">
            <span className="locations">
              {locations.map((local, i) => (
                <span key={i}>
                  {local}
                  {i < locations.length - 1 ? ', ' : ''}
                </span>
              ))}
            </span>
            <h2>{name}</h2>
            <span className="platforms">
              {platforms.map((platform, i) => (
                <span key={i}>
                  {platform}
                  {i < platforms.length - 1 ? ' • ' : ''}
                </span>
              ))}
            </span>
          </span>
        </span>

        <span className="tags">
          {tags.map((tag, i) => (
            <Tag key={i}>{tag}</Tag>
          ))}
        </span>

        <span className="rating">
          <IconStar className={rating >= 1 ? 'on' : undefined} />
          <IconStar className={rating >= 2 ? 'on' : undefined} />
          <IconStar className={rating >= 3 ? 'on' : undefined} />
        </span>
      </div>

      <div className="card-footer">
        <span className="languages">
          {languages.map((lang, i) => (
            <span key={i}>{lang}</span>
          ))}
        </span>
        <IconButton
          icon="plus"
          onClick={() => handleClick(slug)}
          color={white}
          backgroundColor={oceanBlue300}
        />
      </div>
    </Card>
  );
};
