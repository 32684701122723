import { GraphData } from 'types';

const now = new Date();

export const graphs: GraphData[] = [
  {
    id: '1',
    area: 'saúde',
    current_points: 8,
    total_points: 18,
    update_time: new Date(now.setHours(now.getHours() - 10)).toISOString(),
    last_achievement: {
      name: 'consulta',
      points: 300
    }
  },
  {
    id: '2',
    area: 'movimento',
    current_points: 600,
    total_points: 1600,
    update_time: new Date(now.setHours(now.getHours() - 6)).toISOString(),
    last_achievement: {
      name: 'natação',
      points: 120
    }
  },
  {
    id: '3',
    area: 'nutrição',
    current_points: 200,
    total_points: 1800,
    update_time: new Date(now.setHours(now.getHours() - 24)).toISOString(),
    last_achievement: {
      name: 'pesagem',
      points: 60
    }
  },
  {
    id: '4',
    area: 'bonus',
    current_points: 530,
    total_points: 2000,
    update_time: new Date(now.setHours(now.getHours() - 32)).toISOString(),
    last_achievement: {
      name: 'compra',
      points: 400
    }
  }
];
