import { HighlightData } from 'types';

export const highlights: HighlightData[] = [
  {
    id: '1',
    image: '/media/img/highlights/1.jpg',
    title: 'Escolhas do dia',
    lead: 'A próxima refeição? Encontre-a na área de nutrição',
    url: ''
  },
  {
    id: '2',
    image: '/media/img/highlights/2.jpg',
    title: 'Suplementos',
    lead: 'Complemente o seu treino na área de nutrição',
    url: ''
  },
  {
    id: '3',
    image: '/media/img/highlights/3.jpg',
    title: 'Treino pessoal',
    lead: 'Prepare-se para o melhor treino com o seu próximo PT',
    url: ''
  },
  {
    id: '4',
    image: '/media/img/highlights/4.jpg',
    title: 'Planos de saúde',
    lead: 'A maior rede de médicos para a sua próxima consulta',
    url: ''
  }
];
