import styled from 'styled-components';

export const SliderContainer = styled.div`
  .swiper-container {
    padding-bottom: 3.6rem !important;
    margin-top: 2rem;
    transition: padding 300ms ease;

    .swiper-pagination-bullet-active {
      background: var(--c-white) !important;
    }

    .card {
      background-size: cover;
      background-position: center right;
      width: 100%;
      height: 28rem;

      border-radius: 2rem;
      padding: 1.4rem;

      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      gap: 0.8rem;

      @media screen and (min-width: 375px) {
        padding: 2.6rem 3rem;
      }

      & > * {
        color: var(--c-night-blue-900);
      }

      .category {
        text-transform: uppercase;
        letter-spacing: 0.2rem;
        font-size: 1.3rem;
      }

      .title {
        font-size: 2.6rem;
        font-weight: var(--f-bold);
        line-height: 1.2;
        width: 70%;
      }

      .discount {
        font-size: 3rem;
        font-weight: var(--f-bold);
        flex: 1;
      }

      .label {
        color: var(--c-night-blue-900);
        font-size: 1.6rem;
        letter-spacing: 0.05rem;
      }

      img {
        width: 100%;
        height: 12rem;
        object-fit: contain;
        margin-top: 1rem;

        transition: transform 300ms ease;
      }

      a {
        color: var(--c-white);
        background: var(--c-night-blue-900);
        font-size: 1.3rem;
        margin: 0;
        border-radius: 0.8rem;
        width: unset;
        padding: 1rem 2rem;
      }

      &.movement a {
        background: var(--c-movement-2);
      }

      &.nutrition a {
        background: var(--c-nutrition-2);
      }

      &.health a {
        background: var(--c-health-2);
      }
    }
  }
`;
