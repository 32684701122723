import { Cover, Filter, Header, Main, PTCard, SearchForm, Tag } from 'components';
import React, { useEffect, useState } from 'react';
import { personalTrainers, services } from 'static';
import SwiperCore, { A11y, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { CoverData, PersonalTrainerData, TagData } from 'types';
import { countDuplicatesInArray } from 'utils';

import { Section } from './styles';

SwiperCore.use([Pagination, A11y]);

export const PTList: React.FC = () => {
  const [highlightedPTs, setHighlightedPTs] = useState<PersonalTrainerData[]>(
    [] as PersonalTrainerData[]
  );
  const [remainingPTs, setRemainingPTs] = useState<PersonalTrainerData[]>(
    [] as PersonalTrainerData[]
  );
  const [filteredPTs, setFilteredPTs] = useState<PersonalTrainerData[]>(
    [] as PersonalTrainerData[]
  );
  const [searchedPTs, setSearchedPTs] = useState<{ pts?: PersonalTrainerData[] }>({
    pts: [] as PersonalTrainerData[]
  });
  const [coverContent, setCoverContent] = useState<CoverData>({} as CoverData);
  const [topTags, setTopTags] = useState<TagData[]>([]);
  const [filterLabel, setFilterLabel] = useState('filtrar');

  useEffect(() => {
    setHighlightedPTs(personalTrainers.filter(({ highlight }) => highlight));
    setRemainingPTs(personalTrainers.filter(({ highlight }) => !highlight));
  }, []);

  useEffect(() => {
    setCoverContent(
      services.filter(({ slug }) => slug === '/movimento/pt').shift()?.cover || ({} as CoverData)
    );
  }, []);

  useEffect(() => {
    const allTags = personalTrainers.map(({ tags }) => tags.map((tag) => tag));

    const countedDuplicatedTags = countDuplicatesInArray(allTags.flat().sort());

    // @see : https://medium.com/@gmcharmy/sort-objects-in-javascript-e-c-how-to-get-sorted-values-from-an-object-142a9ae7157c
    const orderedTags: TagData[] = Object.entries(countedDuplicatedTags)
      .sort((a, b) => b[1] - a[1])
      .map((el) => el[0]);

    setTopTags(orderedTags);
  }, []);

  const handleFilter = (tag: TagData) => {
    if (!tag) {
      setFilteredPTs(remainingPTs);
      setFilterLabel('filtrar');
    } else {
      setFilteredPTs(remainingPTs.filter((pt) => pt.tags.includes(tag)));
      setFilterLabel(`${tag.slice(0, 10)}${tag.length > 10 ? '...' : ''}`);
    }
  };

  return (
    <>
      <Header />
      <Main>
        <Cover {...coverContent} />
        <Section className="search">
          <span className="sub-title">comece agora</span>
          <p>Encontre os melhores PT’s</p>
          <SearchForm
            label="pesquisar PT's"
            content={{ pts: personalTrainers }}
            setCurrentContent={setSearchedPTs}
            area="pts"
          />
        </Section>

        {searchedPTs.pts && searchedPTs.pts.length === 0 ? (
          <>
            <Section>
              <h2>Em Destaque</h2>
              <Swiper
                loop
                slidesPerView={1}
                spaceBetween={8}
                pagination={{ clickable: true }}
                onSlideChange={(x) => console.log(x)}
                className="slider">
                {highlightedPTs.map((pt, i) => (
                  <SwiperSlide key={i}>
                    <PTCard theme="dark" size="highlight" {...pt} />
                  </SwiperSlide>
                ))}
              </Swiper>
            </Section>
            <Section>
              <span className="section-header">
                <h2>Top Keywords</h2>
                <Filter label={filterLabel} handleFilter={handleFilter} options={topTags} />
              </span>
              <div className="tag-container">
                {topTags.map((tag, i) => (
                  <Tag key={i}>{tag}</Tag>
                ))}
              </div>
              {filteredPTs.length === 0 ? (
                <div>
                  {remainingPTs.map((pt, i) => (
                    <PTCard theme="light" size="standard" {...pt} key={i} />
                  ))}
                </div>
              ) : (
                <div>
                  {filteredPTs.map((pt, i) => (
                    <PTCard theme="light" size="standard" {...pt} key={i} />
                  ))}
                </div>
              )}
            </Section>
          </>
        ) : (
          <Section>
            <div>
              {searchedPTs.pts &&
                searchedPTs.pts.map((pt, i) => (
                  <PTCard theme="light" size="standard" {...pt} key={i} />
                ))}
            </div>
          </Section>
        )}
      </Main>
    </>
  );
};
