import { Button } from 'components';
import React, { useCallback } from 'react';
import { animated, useSpring } from 'react-spring';
import { highlights } from 'static';
import { colors } from 'static';
import SwiperCore, { A11y, Autoplay, Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { bigDropConfig, slowDropConfig } from './animations';
import { Section } from './styles';

SwiperCore.use([Navigation, Pagination, A11y, Autoplay]);

export const HighlightsSection: React.FC = () => {
  const { purpleMist500 } = colors;
  const slowDropAnimation = useSpring(slowDropConfig);
  const bigDropAnimation = useSpring(bigDropConfig);

  const handleHighlightsSliderClick = useCallback((url) => {
    console.log({ url });
    // history.push(url);
  }, []);

  return (
    <Section style={bigDropAnimation}>
      <Swiper
        pagination={{ clickable: true }}
        slidesPerView={1}
        className="slider-container"
        autoplay={{
          delay: 7000,
          disableOnInteraction: true
        }}
        loop={true}>
        {highlights.map(({ image, title, lead, url }, i) => (
          <SwiperSlide key={i} className="slide">
            <animated.img src={image} alt={title} style={slowDropAnimation} />
            <span className="sub-title">{title}</span>
            <span className="description">
              {lead.slice(0, 56)}
              {lead.length > 56 ? '...' : ''}
            </span>
            <Button
              textColor={purpleMist500}
              size="xs"
              onClick={() => handleHighlightsSliderClick(url)}>
              Saiba mais
            </Button>
          </SwiperSlide>
        ))}
      </Swiper>
    </Section>
  );
};
