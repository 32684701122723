import { CommentData } from 'types';

export const comments: CommentData[] = [
  {
    id: '1',
    name: 'Carla Capucha',
    time: '2021-06-07T12:02:00',
    photo: 'https://randomuser.me/api/portraits/women/10.jpg',
    rating: 4,
    comment:
      'Foram encontrar Afonso da Maia no escritório, na sua velha poltrona, com um antigo volume da Ilustração francesa aberto sobre os joelhos, mostrando as estampas a um pequeno bonito, muito moreno, de olho vivo, e cabelo encarapinhado. O velho ficou contentíssimo ao saber que o Ega vinha por algum tempo alegrar o Ramalhete com a sua bela fantasia.',
    pt_id: '1'
  },
  {
    id: '2',
    name: 'Afonso Duarte',
    time: '2021-07-12T20:25:00',
    photo: 'https://randomuser.me/api/portraits/men/32.jpg',
    rating: 4,
    comment:
      'Depois apresentou-lhe aquele pequeno cavalheiro, o Sr. Manuelino, rapazinho amável da vizinhança, filho do Vicente, mestre de obras; o Manuelino vinha às vezes animar a solidão de Afonso - e ali folheavam ambos livros destampas e tinham conversas filosóficas. Agora, justamente, estava ele muito embaraçado por não lhe saber explicar como é que o general Canzonet (de quem estavam admirando o garbo sobre o seu cavalo empinado) tendo mandado matar gente, muita gente, em batalhas, não era metido na cadeia...',
    pt_id: '1'
  },
  {
    id: '3',
    name: 'Alexandre Fernandes',
    time: '2021-07-18T21:14:00',
    photo: null,
    rating: 1,
    comment:
      'Hei, amigo Ega! dizia Afonso rindo. Que se há de responder a esta bela lógica? Olha, filho, agora que estão aqui estes dois senhores que são formados em Coimbra, eu vou estudar esse caso... Vai tu ver os bonecos ali para cima da mesa... E depois vão sendo horas de ires lá dentro à Joana, para merendares.',
    pt_id: '1'
  },
  {
    id: '4',
    name: 'Ana Cerca',
    time: '2021-07-18T21:14:00',
    photo: 'https://randomuser.me/api/portraits/women/28.jpg',
    rating: 3,
    comment:
      'Quando acabaria então o bravo John de fazer bocados incompletos de obras-primas?... - Ega queixou-se do país, da sua indiferença pela arte. Que espírito original não esmoreceria, vendo em torno de si esta espessa massa de burgueses, modorrada e crasso, desdenhando a inteligência, incapaz de se interessar por uma ideia nobre, por uma frase bem-feita?',
    pt_id: '2'
  },
  {
    id: '5',
    name: 'Ana Dias',
    time: '2021-07-14T18:02:00',
    photo: 'https://randomuser.me/api/portraits/women/49.jpg',
    rating: 1,
    comment:
      'A única coisa a fazer em Portugal, dizia ele, é plantar legumes, enquanto não há uma revolução que faça subir à superfície alguns dos elementos originais, fortes, vivos, que isto ainda cerre lá no fundo. E se se vir então que não encerra nada, demitamos-nos logo voluntariamente da nossa posição de país para que não temos elementos, passemos a ser uma fértil e estúpida província espanhola, e plantemos mais legumes!',
    pt_id: '2'
  },
  {
    id: '6',
    name: 'Ana Filipa Reis',
    time: '2020-12-14T11:02:00',
    photo: 'https://randomuser.me/api/portraits/women/31.jpg',
    rating: 2,
    comment:
      'O velho escutava com melancolia estas palavras do neto em que sentia como uma decomposição da vontade, e que lhe pareciam ser apenas a glorificação da sua inercia. Terminou por dizer.',
    pt_id: '2'
  },
  {
    id: '7',
    name: 'André Alves',
    time: '2020-10-01T10:02:00',
    photo: null,
    rating: 4,
    comment:
      'É horroroso, mas então? É a filha do padre Correia, filha conhecida como tal; além disso casada com um proprietário rico da vizinhança, reacionário odioso...',
    pt_id: '2'
  },
  {
    id: '8',
    name: 'Ana Rita Bernardo',
    time: '2020-07-10T11:11:00',
    photo: null,
    rating: 4,
    comment:
      'Na segunda-feira seguinte chuviscava quando Carlos e Ega, no coupé fechado, partiram para o jantar dos Gemou. Desde a chegada da condessa Carlos vira-a só uma vez, em casa dela; e fora uma meia hora desagradável, cheia de mal-estar, com um ou outro',
    pt_id: '3'
  },
  {
    id: '9',
    name: 'Ana Sofia Antunes',
    time: '2020-10-01T10:02:00',
    photo: 'https://randomuser.me/api/portraits/women/29.jpg',
    rating: 5,
    comment:
      'beijo frio, e recriminações infindáveis. Ela queixara-se das cartas dele, tão raras, tão secas. Não se puderam entender sobre os planos desse verão, ela devendo ir para Sintra onde já alugara casa, Carlos falando no dever de acompanhar o avô a Santa Olávia. A condessa achava-o distraído: ele achou-a exigente. Depois ela sentou-se um instante sobre os seus joelhos e aquele leve e delicado corpo pareceu a Carlos de um fastidioso peso de bronze.',
    pt_id: '3'
  },
  {
    id: '10',
    name: 'André Domingues',
    time: '2021-02-01T10:22:00',
    photo: 'https://randomuser.me/api/portraits/men/72.jpg',
    rating: 4,
    comment:
      'De repente o Ega, que fumava em silêncio, abotoado no seu paletó de verão, bateu no joelho de Carlos, e entre risonho e sério',
    pt_id: '4'
  },
  {
    id: '11',
    name: 'André F. Maneiro',
    time: '2020-10-01T10:02:00',
    photo: null,
    rating: 4,
    comment:
      'Por fim a condessa arrancara-lhe a promessa de a ir encontrar, justamente nessa segunda-feira de manhã, a casa da titi, que estava em Santarém; - porque tinha sempre o apetite perverso e requintado de o apertar nos braços nus, em dias que o devesse receber na sua sala, mais tarde, e com cerimónia. Mas Carlos faltara, - e agora, rodando para casa dela, impacientavam-no já as queixas que teria de ouvir nos vãos de janela, e as mentiras chochas que teria de balbuciar...',
    pt_id: '6'
  },
  {
    id: '12',
    name: 'Andreia Coelho',
    time: '2020-10-01T10:02:00',
    photo: 'https://randomuser.me/api/portraits/women/19.jpg',
    rating: 5,
    comment:
      'Um silêncio, até casa da Mourinho, Carlos foi ruminando a sua cólera contra o Dâmaso. Aí estava pois rasgada por aquele imbecil a penumbra suave e favorável em que se abrigara o seu amor! Agora já se pronunciava o nome de Maria Eduarda no Grémio: o que o Dâmaso dissera ao Ega, repeti-lo-ia a outros, na Casa Havana, no restaurante Silva, talvez nos lunares: e assim o interesse supremo da sua vida seria daí por diante constantemente perturbado, estragado, sujo pela tagarelice reles do Dâmaso!',
    pt_id: '6'
  },
  {
    id: '13',
    name: 'Cristiano Santos',
    time: '2021-08-01T10:02:00',
    photo: 'https://randomuser.me/api/portraits/men/46.jpg',
    rating: 3,
    comment:
      'Parece-me que temos cá mais gente, disse o Ega, ao penetrarem na antecâmara dos Gamou, vendo sobre o canapé um paletó cinzento e capas de sonhem.',
    pt_id: '7'
  },
  {
    id: '14',
    name: 'David Dias',
    time: '2021-01-19T20:11:00',
    photo: 'https://randomuser.me/api/portraits/men/34.jpg',
    rating: 4,
    comment:
      'A condessa esperava-os na salina ao fundo, chamada «do busto», vestida de preto, com uma tira de veludo em volta do pescoço picada de três estrelas de diamantes. Uma cesta de esplêndidas flores quase enchia a mesa, onde se acumulavam também romances ingleses, e uma Revista dos Dois Mundos em evidência, com a faca de marfim entre as folhas. Além da boa D. Maria da Cunha e da baronesa alvim, havia uma outra senhora, que nem Carlos nem Ega conheciam, gorda e vestida de escarlate; e de pé, conversando baixo com o conde, de mãos atrás das costas, um cavalheiro alto, escudeirado, grave, com uma barba rala, e a comenda da Conceição.',
    pt_id: '7'
  },
  {
    id: '15',
    name: 'Flávia Canossa',
    time: '2020-05-05T15:05:00',
    photo: 'https://randomuser.me/api/portraits/women/64.jpg',
    rating: 4,
    comment:
      'O Ega, entre a condessa e D. Maria, enterrado no divã, mostrando as estrelinhas bordadas das meias, fazia-as rir com a história do seu exílio em Celorico, onde se distraía compondo sermões para o abade: o abade recitava-os; e os sermões, sob uma forma mística, eram de facto afirmações revolucionarias que o santo varão lançava com fervor, esmurrando o púlpito... A senhora de vermelho, sentada defronte, de mãos no regaço, escutava o Ega, com o olhar espantado.',
    pt_id: '7'
  },
  {
    id: '16',
    name: '',
    time: '2020-10-01T10:02:00',
    photo: null,
    rating: 4,
    comment: '',
    pt_id: '7'
  },
  {
    id: '17',
    name: 'Gonçalo Sá',
    time: '2021-01-09T11:11:00',
    photo: null,
    rating: 2,
    comment:
      'O escudeiro anunciara o jantar. A condessa tomou o braço de Carlos, - e, ao atravessar o salão, entre o frouxo murmúrio de vozes e o rumor lento das caudas de seda, pôde dizer-lhe asperamente:',
    pt_id: '8'
  },
  {
    id: '18',
    name: 'Jorge Rodrigues',
    time: '2020-12-01T11:02:00',
    photo: 'https://randomuser.me/api/portraits/lego/3.jpg',
    rating: 4,
    comment:
      'Na sala de jantar, um pouco sombria, forrada de papel cor de vinho, escurecida ainda por dois antigos painéis de paisagem tristonha, a mesa oval, cercada de cadeiras de carvalho lavrado, ressaltava alva e fresca, com um esplêndido cesto de rosas entre duas serpentinas douradas. Carlos ficou à direita da condessa, tendo ao lado D. Maria da Cunha, que nesse dia parecia um pouco mais velha, e sorria com um ar cansado.',
    pt_id: '8'
  },
  {
    id: '19',
    name: 'José Sequeira',
    time: '2020-10-01T10:02:00',
    photo: null,
    rating: 3,
    comment:
      'Que tem feito todo este tempo, que ninguém o tem visto? Perguntou-lhe ela, desdobrando o guardanapo.',
    pt_id: '8'
  },
  {
    id: '20',
    name: 'Joana Loureiro',
    time: '2021-02-02T10:02:00',
    photo: 'https://randomuser.me/api/portraits/women/79.jpg',
    rating: 4,
    comment:
      'Carlos, no entanto, comendo em silêncio a sua sopa, ruminava as palavras da condessa. Também ela conhecia já a sua intimidade com a «brasileira». Era evidente pois que já andava ali, difamante e torpe, a tagarelice do Dâmaso. E quando o criado lhe ofereceu Sauterne, estava decidido a bater no Dâmaso.',
    pt_id: '10'
  }
];
