import { Guru, HomeNutrition, NutritionBooking, NutritionPlan } from 'pages/Private/Nutrition';
import { Switch } from 'react-router-dom';

import { Route } from './Route';

export const NutritionRoutes: React.FC = () => (
  <Switch>
    <Route path="/nutricao/consultas" component={NutritionBooking} isPrivate />
    <Route path="/nutricao/plano" component={NutritionPlan} isPrivate />
    <Route path="/nutricao/guru" component={Guru} isPrivate />
    <Route path="/nutricao/inicio" component={HomeNutrition} isPrivate />
  </Switch>
);
