import { GeneralPrivateRoutes } from './GeneralPrivateRoutes';
import { HealthRoutes } from './HealthRoutes';
import { MovementRoutes } from './MovementRoutes';
import { NutritionRoutes } from './NutritionRoutes';
import { PublicRoutes } from './PublicRoutes';

const Routes: React.FC = () => (
  <>
    <PublicRoutes />
    <GeneralPrivateRoutes />
    <MovementRoutes />
    <HealthRoutes />
    <NutritionRoutes />
  </>
);

export default Routes;
