export type SvgEl = React.HTMLAttributes<HTMLOrSVGElement>;

export const IconHealthOutline: React.FC<SvgEl> = (props: SvgEl) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 98 92" {...props}>
      <path
        className="reflex"
        d="M10.8 31.7a1.2 1.2 0 00-1.2 1.1V34a1.2 1.2 0 001.2 1.2A1.2 1.2 0 0012 34v-1a1.2 1.2 0 00-1.2-1.3zM31.1 9.2a24.4 24.4 0 00-14.3 7 24.5 24.5 0 00-6.5 11.5 1.2 1.2 0 00.9 1.5 1.4 1.4 0 00.3 0 1.2 1.2 0 001.1-1 22.3 22.3 0 0118.8-16.7 1.2 1.2 0 00-.3-2.4z"
      />
      <path
        className="icon"
        d="M88.2 9.8A30 30 0 0049 7 30 30 0 007.5 49.7h0l40.6 41a1.2 1.2 0 00.9.3h0a1.2 1.2 0 00.9-.4L90.5 50a1.3 1.3 0 00.4-.7 30 30 0 00-2.7-39.4z"
      />
    </svg>
  );
};
