export type SvgEl = React.HTMLAttributes<HTMLOrSVGElement>;

export const IconNutritionOutline: React.FC<SvgEl> = (props: SvgEl) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 78 92" {...props}>
      <path
        className="reflex"
        d="M12.05 46.34a1.27 1.27 0 00-1.28 1.27v.42a1.28 1.28 0 002.55 0v-.42a1.27 1.27 0 00-1.27-1.27zm14.44-15.2a16.5 16.5 0 00-14.71 12.78 1.29 1.29 0 00.91 1.56 1.87 1.87 0 00.32 0 1.29 1.29 0 001.24-1A13.75 13.75 0 0126.61 33.6a1.28 1.28 0 00-.08-2.55z"
      />
      <path
        className="icon"
        d="M60.29 23.1c-6.19-1.88-13.76-.45-19.93 3.64.94-19.61 7.82-23.23 8-23.31a1.27 1.27 0 00-1-2.33c-.25.11-4.3 2-7 10.55a14.52 14.52 0 00-3.4-5.37A14.74 14.74 0 0022.68 2.5a1.23 1.23 0 00-.89.9 14.82 14.82 0 003.77 14.31 14.66 14.66 0 0010.4 4.3 14.22 14.22 0 002.34-.2c-.17 1.57-.3 3.24-.39 5-6.2-4.17-13.85-5.65-20.09-3.75-13.56 4.12-20.22 22.51-14.86 41 3.09 10.62 9.76 19.58 17.84 24 5.5 3 11.21 3.7 16.52 2.08a17.57 17.57 0 001.79-.64 17.41 17.41 0 001.78.64 19.13 19.13 0 005.66.85 22.78 22.78 0 0010.86-2.93c8.09-4.4 14.76-13.36 17.84-24 5.26-18.45-1.41-36.84-14.96-40.96z"
      />
    </svg>
  );
};
