import styled from 'styled-components';

export const Card = styled.div`
  display: grid;
  grid-template-columns: 6rem auto;
  grid-gap: 0.8rem;
  border-bottom: 1px solid var(--c-old-navy-blue-800);
  padding: 1.8rem 0;

  .avatar {
    width: 100%;
    fill: var(--c-old-navy-blue-800);
    border-radius: 50%;
  }

  span {
    width: 100%;

    .rating {
      display: block;
      width: 100%;
      margin-bottom: 1rem;

      svg {
        width: 1.6rem;
        height: 1.6rem;
        fill: var(--c-phantom-grey-200);

        & + svg {
          margin-left: 0.4rem;
        }

        &.on {
          fill: var(--c-ocean-blue-300);
        }
      }
    }

    h3 {
      font-size: 1.8rem;
      line-height: 1;
      font-weight: var(--f-light);
      color: var(--c-night-blue-900);
    }

    time {
      color: var(--c-old-navy-blue-800);
      font-size: 1.2rem;
      display: block;
      margin-bottom: 0.6rem;
    }

    .comment {
      color: var(--c-old-navy-blue-800);
      font-size: 1.4rem;
    }
  }
`;
