import { Section } from 'components/styles';
import React from 'react';
import { Link } from 'react-router-dom';
import SwiperCore, { A11y, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { PromosData } from 'types';

import { SliderContainer } from './styles';

SwiperCore.use([A11y, Pagination]);

interface PromosProps {
  promos: PromosData[];
}

export const Promos: React.FC<PromosProps> = ({ promos }: PromosProps) => {
  return (
    <Section>
      <h2>Promos</h2>
      <SliderContainer>
        <Swiper loop={true} slidesPerView={1} spaceBetween={8} pagination={{ clickable: true }}>
          {promos.map(({ image, title, category, discount, image_2x, slug, area }, i) => (
            <SwiperSlide
              key={i}
              className={`card ${area}`}
              style={{
                backgroundImage: `url(${window.devicePixelRatio === 1 ? image : image_2x})`
              }}>
              <span className="category">{category}</span>
              <strong className="title">{title}</strong>
              <span className="discount">-{discount}%</span>
              <Link to={slug}>comprar</Link>
            </SwiperSlide>
          ))}
        </Swiper>
      </SliderContainer>
    </Section>
  );
};
