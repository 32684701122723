import {
  ActivitiesSection,
  Cover,
  Header,
  Main,
  Promos,
  ScoreGraph,
  ServicesCards
} from 'components';
import React, { useEffect, useState } from 'react';
import { activities, graphs, promos, services } from 'static';
import { ActivitiesData, CoverData, GraphData, PromosData, ServicesData } from 'types';

import { Section } from './styles';

export const HomeNutrition: React.FC = () => {
  const [graph, setGraph] = useState<GraphData>({} as GraphData);
  const [nutritionServices, setNutritionServices] = useState<ServicesData[]>([] as ServicesData[]);
  const [nutritionPromos, setNutritionPromos] = useState<PromosData[]>([] as PromosData[]);
  const [coverContent, setCoverContent] = useState<CoverData>({} as CoverData);
  const [activitiesContent, setActivitiesContent] = useState<ActivitiesData[]>(
    [] as ActivitiesData[]
  );

  useEffect(() => {
    setNutritionServices(services.filter(({ area, card }) => area === 'nutrição' && card.show));
    setNutritionPromos(promos.filter(({ area }) => area === 'nutrição'));
    setActivitiesContent(activities.filter(({ area }) => area === 'nutrição'));
    setCoverContent(
      services.filter(({ slug }) => slug === '/nutricao/inicio').shift()?.cover || ({} as CoverData)
    );
  }, []);

  useEffect(() => {
    const currentGraph = graphs.filter(({ area }) => area === 'nutrição').shift();
    if (currentGraph) setGraph(currentGraph);
  }, []);

  return (
    <>
      <Header />
      <Main>
        <Cover {...coverContent} />
        <Promos promos={nutritionPromos} />
        <Section>
          <div className="intro">
            <h2>Pontos</h2>
            <p>Conquista objetivos e ganha pontos!</p>
          </div>
          {graph.area && <ScoreGraph graph={graph} card_size="md" />}
        </Section>
        <ActivitiesSection activities={activitiesContent} />
        <ServicesCards
          services={nutritionServices}
          title="A pensar em si"
          description="Selecione um serviço e prepare-se para conquistar mais pontos"
        />
      </Main>
    </>
  );
};
