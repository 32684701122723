import { Cover, Header, Main } from 'components';
import React, { useEffect, useState } from 'react';
import { services } from 'static';
import { CoverData } from 'types';

import { Section } from './styles';

export const NutritionBooking: React.FC = () => {
  const [coverContent, setCoverContent] = useState<CoverData>({} as CoverData);

  useEffect(() => {
    setCoverContent(
      services.filter(({ slug }) => slug === '/nutricao/consultas').shift()?.cover ||
        ({} as CoverData)
    );
  }, []);

  return (
    <>
      <Header />
      <Main>
        <Cover {...coverContent} />
        <Section>
          <p>A equipa Hi! está a desenvolver esta área. Muito brevemente estará disponível.</p>
        </Section>
      </Main>
    </>
  );
};
