import { LogoHi } from 'assets/vectors';
import { Button } from 'components';
import { Input } from 'components/elements/Form';
import { useFormik } from 'formik';
import React, { useCallback } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { animated, useSpring } from 'react-spring';
import * as Yup from 'yup';

import { fadeInConfig, fadeInWidthDelayConfig } from '../animations';
import { Form, Main, Section } from '../styles';

interface FormValues {
  password: string;
  confirm: string;
}

export const PasswordReset: React.FC = () => {
  const history = useHistory();
  const initialValues = { password: '', confirm: '' };
  const fadeIn = useSpring(fadeInConfig);
  const fadeInWidthDelay = useSpring(fadeInWidthDelayConfig);

  const validationSchema = Yup.object().shape({
    password: Yup.string().required('password obrigatória').min(6),
    confirm: Yup.string().oneOf([Yup.ref('password'), null], 'Password não coincide')
  });

  const onSubmit = useCallback((values: FormValues) => {
    console.log(JSON.stringify(values, null, 2));
    history.push('/login');
  }, []);

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema
  });

  return (
    <Main>
      <Section style={fadeIn}>
        <Link to="/">
          <LogoHi className="logo" />
        </Link>
        <div className="description">
          <h1>Nova Password</h1>
          <p>Adicione uma nova password à sua conta Hi!</p>
        </div>
        <Form onSubmit={formik.handleSubmit} style={fadeInWidthDelay}>
          <Input
            type="password"
            id="password"
            placeholder="nova password"
            autoComplete="off"
            alert={formik.touched.password && formik.errors.password ? true : false}
            {...formik.getFieldProps('password')}
          />
          <Input
            type="password"
            id="confirm"
            placeholder="confirme password"
            autoComplete="off"
            alert={formik.touched.confirm && formik.errors.confirm ? true : false}
            {...formik.getFieldProps('confirm')}
          />
          <Button size="lg" type="submit">
            Entrar
          </Button>
        </Form>
        <animated.div style={fadeInWidthDelay}>
          <Link to="/login">Login</Link>
        </animated.div>
      </Section>
    </Main>
  );
};
