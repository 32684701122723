import styled from 'styled-components';

export const Section = styled.section`
  padding: 8rem 2rem 2rem;
  position: relative;
  overflow: hidden;
  min-height: 32rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &.home {
    background: radial-gradient(var(--c-night-blue-900), var(--c-purple-twilight-600));
  }
  &.nutrition {
    background: radial-gradient(var(--c-nutrition-1), var(--c-nutrition-2));
  }
  &.movement {
    background: radial-gradient(var(--c-movement-1), var(--c-movement-2));
  }
  &.health {
    background: radial-gradient(var(--c-health-1), var(--c-health-2));
  }

  background-position: 0% 50%;
  background-size: 400% 400%;

  animation: Gradient 10000ms ease infinite;
  animation-play-state: running;

  &.card {
    justify-content: flex-start;
    min-height: 36rem;
  }

  &::after {
    content: '';
    position: absolute;
    bottom: -260px;
    left: 50%;
    transform: translateX(-50%) skewY(352deg);
    width: calc(100% + 20%);
    height: 360px;
    background: var(--c-silver-grey-100);
  }

  h1 {
    text-align: center;
    color: var(--c-white);
    font-size: 2.8rem;
    line-height: 1;
    width: 80%;

    @media screen and (min-width: 375px) {
      font-size: 3.2rem;
    }

    @media screen and (min-width: 425px) {
      font-size: 3.6rem;
    }
  }

  img {
    width: 16rem;
    height: 16rem;
    border-radius: 50%;
    margin: 2rem auto;
    object-fit: cover;
    z-index: 1;
  }

  .code-container {
    z-index: 1;
    position: relative;
    background: var(--c-white);
    overflow: hidden;
    border-radius: 1.6rem;

    &.no-value {
      span {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-weight: bold;
        font-size: 2.2rem;
      }

      svg {
        opacity: 0.1;
      }
    }
  }
`;
