import styled from 'styled-components';

export const Form = styled.form`
  position: relative;
  height: 4rem;
  width: 100%;

  input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    font-size: 1.6rem;
    padding: 1.2rem 1.6rem;
    border-radius: 3rem;
    border: none;
    box-shadow: 0 0 0 var(--c-phantom-grey-200);

    transition: box-shadow 300ms ease;

    &::placeholder {
      letter-spacing: 0.1rem;
    }

    &:focus {
      box-shadow: 0 0 30px var(--c-phantom-grey-200);

      & + button svg {
        stroke: var(--c-old-navy-blue-800);
      }
    }
  }

  span {
    position: absolute;
    top: 50%;
    right: 1.6rem;
    transform: translateY(-50%);
    border: none;
    background: transparent;
    line-height: 1;

    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      stroke: var(--c-phantom-grey-200);
      width: 2rem;
      height: 2rem;
      transition: stroke 200ms ease;
    }
  }
`;
