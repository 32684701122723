import { Accordion, IconButton } from 'components';
import { useAuth } from 'hooks/useAuth';
import React, { useCallback, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useSpring } from 'react-spring';
import { colors, services } from 'static';
import { AreaType } from 'types';
import { areaToSlug } from 'utils';

import { NavContainer, SecondaryLinks } from './styles';
import { UserProfile } from './UserProfile';

interface NavProps {
  menuOpen: boolean;
  location: string | null;
  setMenuOpen(menuOpen: boolean): void;
  hasUnreadNotifications: boolean;
}

interface LinkProps {
  label: string;
  slug: string;
}
interface AreaLink {
  area: Exclude<AreaType, 'bonus'>;
  links: LinkProps[];
}

export const Nav: React.FC<NavProps> = ({
  menuOpen,
  setMenuOpen,
  location,
  hasUnreadNotifications
}: NavProps) => {
  const history = useHistory();
  const { signOut } = useAuth();
  const { oldNavyBlue800 } = colors;
  const [areasLinks, setAreasLinks] = useState<AreaLink[]>([] as AreaLink[]);
  const appearRight = useSpring({ left: menuOpen ? '0vw' : '100vw' });

  const handleNavClick = useCallback((slug) => {
    setMenuOpen(false);
    setTimeout(() => history.push(slug), 60);
  }, []);

  const filterLinks = useCallback((currentArea: Exclude<AreaType, 'bonus'>) => {
    return services
      .filter(({ area }) => area === currentArea)
      .map(({ label, slug }) => ({ label, slug }));
  }, []);

  useEffect(() => {
    setAreasLinks([
      {
        area: 'movimento',
        links: filterLinks('movimento')
      },
      {
        area: 'nutrição',
        links: filterLinks('nutrição')
      },
      {
        area: 'saúde',
        links: filterLinks('saúde')
      }
    ]);
  }, []);

  return (
    <NavContainer style={appearRight} className={menuOpen ? 'open' : undefined}>
      <UserProfile menuOpen={menuOpen} hasUnreadNotifications={hasUnreadNotifications} />
      {areasLinks.map(({ area, links }, i) => (
        <Accordion
          icon="arrow"
          key={i}
          buttonTitle={area}
          className="accordion"
          isOpen={location?.slice(1).split('/').shift() === areaToSlug(area)}>
          {links.map(({ slug, label }, i) => (
            <button
              onClick={() => handleNavClick(slug)}
              className={`${label} ${location?.includes(slug) ? 'selected' : ''}`}
              key={i}>
              {label}
            </button>
          ))}
        </Accordion>
      ))}

      <SecondaryLinks>
        <Link to="/settings">preferências</Link>
        <Link to="/about">acerca</Link>
        <button
          onClick={() => {
            signOut();
            history.push('/');
          }}>
          sair
        </button>
      </SecondaryLinks>
      <IconButton
        icon="menu"
        color={oldNavyBlue800}
        isOpen={menuOpen}
        className="menu-button"
        onClick={() => setMenuOpen(!menuOpen)}
      />
    </NavContainer>
  );
};
