import { animated } from 'react-spring';
import styled from 'styled-components';

export const NavContainer = styled(animated.nav)`
  position: fixed;
  top: 0;
  /* right: -120%; */
  width: 101%;
  height: 100vh;
  overflow-y: scroll;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1.6rem;

  background: var(--c-night-blue-900);
  padding: 3.2rem 3.2rem 9.2rem 3.2rem;
  z-index: 4;

  transition: right 200ms ease;

  &.open {
    /* right: 0; */

    .menu-button svg {
      stroke: var(--c-old-navy-blue-800);
    }
  }

  .menu-button {
    position: fixed;
    top: calc(1.6rem + 1.2rem);
    right: 1.6rem;
    z-index: 3;

    svg {
      stroke: var(--c-white);
    }
  }

  .accordion {
    margin-top: 8rem;

    & > div {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;

      button {
        color: var(--c-white);
        background: transparent;
        border: none;
        text-decoration: none;
        font-size: 1.8rem;
        line-height: 2;
        padding: 0.4rem 0.8rem;
        position: relative;
        left: 0;

        transition: left 300ms ease;

        &::before {
          content: '';
          width: 0;
          height: 0;
          position: absolute;
          left: -0.2rem;
          top: 50%;
          opacity: 0;
          transform: translate(-50%, -50%);
          transition: left 200ms ease, opacity 200ms ease;

          border-style: solid;
          border-width: 0.6rem 0 0.6rem 0.8rem;
          border-color: transparent transparent transparent var(--c-white);
        }

        transition: opacity 300ms ease, left 300ms ease;

        &:hover,
        &.selected {
          font-weight: var(--f-semi-bold);
          left: 1.6rem;

          &::before {
            left: -0.2rem;
            opacity: 1;
          }
        }
      }
    }

    & + .accordion {
      margin-top: 1rem;
    }
  }
`;

// export const PrimaryLinks = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: flex-start;
//   justify-content: flex-start;

//   a {
//     text-decoration: none;
//     font-weight: var(--f-bold);
//     font-size: 3.6rem;
//     color: var(--c-white);
//     line-height: 2;
//     position: relative;
//     border-bottom: 1px solid var(--c-old-navy-blue-800);
//     display: block;
//     width: 100%;

//     &::after {
//       content: '';
//       width: 0;
//       height: 0;
//       position: absolute;
//       left: -3rem;
//       top: 44%;
//       opacity: 0;
//       transform: translateX(-50%);
//       transition: left 200ms ease, opacity 200ms ease;

//       border-style: solid;
//       border-width: 0.8rem 0 0.8rem 1rem;
//       border-color: transparent transparent transparent var(--c-hi-blue);
//     }

//     &.movement {
//       &::after {
//         border-color: transparent transparent transparent var(--c-movement-1);
//       }
//     }

//     &.nutrition {
//       &::after {
//         border-color: transparent transparent transparent var(--c-nutrition-1);
//       }
//     }

//     &.health {
//       &::after {
//         border-color: transparent transparent transparent var(--c-health-1);
//       }
//     }

//     &:hover,
//     &.selected {
//       &.movement {
//         color: var(--c-movement-1);
//       }

//       &.nutrition {
//         color: var(--c-nutrition-1);
//       }

//       &.health {
//         color: var(--c-health-1);
//       }

//       &::after {
//         left: -1rem;
//         opacity: 1;
//       }
//     }
//   }
// `;

export const SecondaryLinks = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  a,
  button {
    text-decoration: none;
    font-weight: var(--f-semi-bold);
    font-size: 1.6rem;
    color: var(--c-old-navy-blue-800);
    line-height: 2;
    border: none;
    background: transparent;
  }
`;
