import styled from 'styled-components';

export const Card = styled.button`
  border: none;
  background: transparent;
  width: 100%;
  max-width: 33rem;
  height: 20rem;
  perspective: 60rem;
  text-align: left;
  display: block;

  .card-face {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 19rem;
    z-index: 9999;

    border-radius: 1.2rem;
    /* background: var(--c-night-blue-900); */
    background: radial-gradient(
      farthest-corner at -50% 150%,
      var(--c-night-blue-900) 0%,
      #2e3f5a 100%
    );

    transition: transform 1000ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
    transform-style: preserve-3d;
    backface-visibility: hidden;

    &.card-front {
      transform: rotateY(0);
      padding: 1.8rem;

      svg {
        width: 20.6rem;
        height: 3.6rem;
        fill: var(--c-ocean-blue-300);
      }

      span {
        display: block;
      }

      .sub-title {
        text-align: left;
        font-size: 1.2rem;
        margin-bottom: 2rem;
      }

      .details {
        font-family: var(--f-font-secondary);
        color: var(--c-white);
        font-size: 1.2rem;
        letter-spacing: 0.1rem;
        line-height: 1.8;
      }

      .url {
        font-size: 1.2rem;
        text-decoration: none;
        color: var(--c-smoke-grey-400);
        margin-top: 2rem;
        display: inline-block;
      }
    }

    &.card-back {
      padding: 18px 0;
      transform: rotateY(-180deg);

      .black-stripe {
        margin-top: 5px;
        height: 38px;
        background-color: #303030;
      }

      .card-back-content {
        padding: 1.6rem;

        .card-secret {
          padding: 0.6rem 1.2rem;
          background-color: var(--c-white);
          position: relative;

          &:before {
            content: '';
            position: absolute;
            top: -0.3rem;
            left: -0.3rem;
            height: calc(100% + 0.6rem);
            width: calc(100% - 4.2rem);
            border-radius: 0.4rem;
            background: repeating-linear-gradient(
              45deg,
              #ededed,
              #ededed 5px,
              #f9f9f9 5px,
              #f9f9f9 10px
            );
          }

          .card-secret-last {
            color: var(--c-old-navy-blue-800);
            text-align: right;
            margin: 0;
            font-size: 1.4rem;
          }
        }

        svg {
          fill: var(--c-ocean-blue-300);
          width: 18rem;
          height: 3rem;
          margin-top: 4rem;
        }
      }
    }
  }

  &.flipped {
    animation-play-state: running;
    .card-front {
      transform: rotateY(180deg);
    }

    .card-back {
      transform: rotateY(0);
    }
  }

  &.preview {
    .card-front {
      transform: rotateY(10deg);
    }

    .card-back {
      transform: rotateY(-170deg);
    }
  }
`;
