import { ActivityCard } from 'components';
import React from 'react';
import SwiperCore, { A11y, Autoplay, Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { ActivitiesData } from 'types';

import { Section } from './styles';

interface ActivitiesSectionProps {
  activities: ActivitiesData[];
}

SwiperCore.use([Navigation, Pagination, A11y, Autoplay]);

export const ActivitiesSection: React.FC<ActivitiesSectionProps> = ({
  activities
}: ActivitiesSectionProps) => {
  return (
    <Section>
      <h2>Atividades sugeridas</h2>
      <p>Pronto/a para o próximo desafio?</p>
      <Swiper
        loop
        slidesPerView={2}
        spaceBetween={8}
        pagination={{ clickable: true }}
        onSlideChange={(x) => console.log(x)}
        className="slider">
        {activities.map(({ title, area, backgroundImage, backgroundImage_2x, slug }, i) => (
          <SwiperSlide key={i}>
            <ActivityCard
              area={area}
              title={title}
              backgroundImage={backgroundImage}
              backgroundImage_2x={backgroundImage_2x}
              slug={slug}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </Section>
  );
};
