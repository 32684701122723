import styled from 'styled-components';

export const HeaderContainer = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 1.6rem;
  overflow: hidden;
  z-index: 4;

  display: flex;
  align-items: center;
  justify-content: space-between;

  background: transparent;

  //dev
  max-width: 425px;
  @media screen and (min-width: 425px) {
    left: 50%;
    transform: translateX(-50%);
  }

  // bg effect
  &::after {
    content: '';
    position: absolute;
    left: 50%;
    bottom: -440px;
    width: calc(100% + 20%);
    height: 360px;
    z-index: -1;

    background: var(--c-white);

    transform: translateX(-50%) skewY(352deg);
    transition: bottom 600ms ease;
  }

  &.home::after {
    background: radial-gradient(var(--c-main-1), var(--c-main-2));
  }

  &.movimento::after {
    background: radial-gradient(var(--c-movement-1), var(--c-movement-2));
  }

  &.nutricao::after {
    background: radial-gradient(var(--c-nutrition-1), var(--c-nutrition-2));
  }

  &.saude::after {
    background: radial-gradient(var(--c-health-1), var(--c-health-2));
  }

  &.bonus::after {
    background: radial-gradient(var(--c-bonus-1), var(--c-bonus-2));
  }

  .notification-btn {
    // notification badge
    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      width: 1rem;
      height: 1rem;
      border-radius: 50%;
      background: red;
      border: 2px solid var(--c-purple-twilight-600);
      transform: scale(0);
      transition: transform 200ms ease 1000ms, border 600ms ease;
    }

    &.new {
      &::after {
        transform: scale(1);
      }
    }
  }

  &.scrolled::after {
    bottom: -180px;
  }

  .logo {
    width: 5rem;
    fill: var(--c-white);
    transition: fill 1000ms ease;
  }

  & > span {
    width: 2.8rem;
    height: 2.8rem;
  }
`;
