import styled from 'styled-components';

export const Section = styled.section`
  background: var(--c-silver-grey-100);
  padding: 3.6rem 2rem;

  .slider {
    margin-top: 2rem;
    padding-bottom: 2rem;

    .swiper-slide-active {
      & > button {
        background-position: left center;
      }
    }

    .swiper-pagination {
      bottom: 0;
    }
  }
`;
