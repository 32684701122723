import { Cover, FAQsAccordionSection, GuruFormSection, Header, Main, SearchForm } from 'components';
import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { colors, faqs, services } from 'static';
import { CoverData, FaqsData } from 'types';

import { Section } from './styles';

Modal.setAppElement('#root');

export const Guru: React.FC = () => {
  const { alaskaSalmon300, white } = colors;
  const [coverContent, setCoverContent] = useState<CoverData>({} as CoverData);
  const [allNutritionFAQs, setAllNutritionFAQs] = useState<FaqsData[]>([] as FaqsData[]);
  const [nutritionFAQs, setNutritionFAQs] = useState<{ faqs?: FaqsData[] | undefined }>({
    faqs: []
  });

  useEffect(() => {
    setCoverContent(
      services.filter(({ slug }) => slug === '/nutricao/guru').shift()?.cover || ({} as CoverData)
    );
  }, []);

  useEffect(() => {
    setAllNutritionFAQs(faqs.filter(({ area }) => area === 'nutrição'));
    setNutritionFAQs({ faqs: faqs.filter(({ area }) => area === 'nutrição') });
  }, []);

  return (
    <>
      <Header />
      <Main>
        <Cover {...coverContent} />
        <Section>
          <p>Hidratos de carbono, dieta do paleolítico, LSD, ácidos gordos... o guru ajuda</p>
          <SearchForm
            label="Pesquisar FAQ’s"
            content={{ faqs: allNutritionFAQs }}
            setCurrentContent={setNutritionFAQs}
            area="faqs"
          />
        </Section>
        <FAQsAccordionSection questions={nutritionFAQs.faqs || []} />
        <GuruFormSection
          button={{ backgroundColor: alaskaSalmon300, textColor: white }}
          title={{ color: alaskaSalmon300 }}
        />
      </Main>
    </>
  );
};
