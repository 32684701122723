import { Header, Main } from 'components';
import { useVideos } from 'hooks/useVideos';
import { VideoData } from 'pages/Private/Movement/VideoList/types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import ReactHlsPlayer from 'react-hls-player';
import { FiBarChart, FiChevronLeft, FiClock, FiPlay } from 'react-icons/fi';
import { useHistory, useParams } from 'react-router-dom';
import { api } from 'services/api';
import { getRandomInt } from 'utils';

import { Details, PlayerContainer, PrimaryData, Recommended, Tag } from './styles';

interface Params {
  tag: string;
}

export const VideoDetail: React.FC = () => {
  const { tag } = useParams<Params>();
  const [videoLink, setVideoLink] = useState('');
  const [playVideo, setPlayVideo] = useState(false);
  const [videoDetails, setVideoDetails] = useState<VideoData>({} as VideoData);
  const [recommendedVideos, setRecommendedVideos] = useState<VideoData[]>([] as VideoData[]);
  const playerRef = useRef<HTMLVideoElement>(null);
  const detailsRef = useRef<HTMLDivElement>(null);
  const history = useHistory();
  const { allVideos } = useVideos();

  const getVideo = useCallback(
    async ({ tag }) => {
      const response = await api.get(`/videos/detail?tag=${tag}`);
      const { data } = response;
      const { results } = data;
      const { streamingUrl } = results;
      setVideoLink(streamingUrl);
    },
    [videoLink]
  );

  const handlePlay = useCallback(() => {
    setPlayVideo(true);
    if (playerRef.current) playerRef.current.play();
  }, []);

  const addDefaultSrc = useCallback((evt) => {
    evt.target.src = '/assets/img/video-placeholder.svg';
  }, []);

  const formatVideoTime = useCallback((time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time - minutes * 60;
    return `${minutes}:${seconds}`;
  }, []);

  const getRecommendedVideos = () => {
    const videos = allVideos.filter(
      (video) =>
        video.details.category === videoDetails.details.category && video.details.tag !== tag
    );
    const index = getRandomInt(0, videos.length - 2);
    return videos.splice(index, 2);
  };

  const handleRecommended = useCallback(({ tag }) => {
    history.push(`movimento/video/${tag}`);
    if (playerRef.current) playerRef.current.pause();
    setPlayVideo(false);
    detailsRef.current &&
      detailsRef.current.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
  }, []);

  useEffect(() => {
    getVideo({ tag });
  }, [getVideo, tag]);

  useEffect(() => {
    const selectedVideo = allVideos.filter(({ details }) => details.tag === tag).shift();
    if (!selectedVideo) {
      history.push('/movimento/videos');
      return;
    }
    setVideoDetails(selectedVideo);
  }, [tag]);

  useEffect(() => {
    if (!videoDetails.details) return;
    const selectedVideos = getRecommendedVideos();
    setRecommendedVideos(selectedVideos);
  }, [videoDetails]);

  return (
    <>
      <Header backButtonLink={'movimento/videos'} />
      <Main>
        <PlayerContainer>
          {videoDetails.details && !playVideo && (
            <button className="play-button" onClick={() => handlePlay()}>
              <img
                src={videoDetails.video.imageLink}
                alt={videoDetails.details.name}
                onError={addDefaultSrc}
              />
              <FiPlay className="icon" />
            </button>
          )}
          <ReactHlsPlayer
            playerRef={playerRef}
            src={videoLink}
            autoPlay={false}
            controls={true}
            height={240}
            playsInline
            className={`player ${videoLink !== '' && playVideo && 'show'}`}
          />
        </PlayerContainer>
        {videoDetails.details && (
          <>
            <PrimaryData>
              <button className="back-btn" onClick={() => history.push('/movement/videos')}>
                <FiChevronLeft />
              </button>
              <span>{videoDetails.specs.level.toLocaleLowerCase()}</span>
              <span>skill {videoDetails.specs.skill}</span>
              <span>
                <FiBarChart /> {videoDetails.specs.intensity}
              </span>
              <span>
                <FiClock />
                {formatVideoTime(videoDetails.video.durationInSeconds)}
              </span>
            </PrimaryData>
            <Details ref={detailsRef}>
              <strong className="sub-title">
                {`${videoDetails.details.category.toLocaleLowerCase()} ${
                  videoDetails.details.subCategory ?? ''
                }`}
              </strong>
              <h1>{videoDetails.details.name}</h1>
              <strong className="sub-title">with {videoDetails.details.instructor}</strong>
              <p className="description">{videoDetails.details.description}</p>
              {videoDetails.details.equipments && (
                <div className="details">
                  <strong>equipments</strong>
                  <br />
                  {videoDetails.details.equipments.map((equipment, i) => (
                    <Tag key={i}>{equipment.toLocaleLowerCase()}</Tag>
                  ))}
                </div>
              )}
              {videoDetails.details.labels && (
                <div className="details">
                  <strong>labels</strong>
                  <br />
                  {videoDetails.details.labels.map((label, i) => (
                    <Tag key={i}>{label.toLocaleLowerCase()} </Tag>
                  ))}
                </div>
              )}
              <Recommended>
                <h2>You may also like</h2>
                <div className="card-container">
                  {recommendedVideos.map(({ details, video }, i) => {
                    const { name, tag } = details;
                    const { imageLink } = video;
                    return (
                      <div className="card" key={i}>
                        <div className="card-header">
                          <img src={imageLink} alt={name} onError={addDefaultSrc} />
                        </div>
                        <div className="card-body">
                          <span>{name}</span>
                        </div>
                        <div className="card-footer">
                          <button onClick={() => handleRecommended({ tag })}>show</button>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </Recommended>
            </Details>
          </>
        )}
      </Main>
    </>
  );
};
