import {
  FitGuru,
  HomeMovement,
  PTList,
  PTProfile,
  Treino,
  VideoDetail,
  VideoList
} from 'pages/Private/Movement';
import { Switch } from 'react-router-dom';

import { Route } from './Route';

export const MovementRoutes: React.FC = () => (
  <Switch>
    <Route path="/movimento/guru" component={FitGuru} isPrivate />
    <Route path="/movimento/treino" component={Treino} isPrivate />
    <Route path="/movimento/pt/:slug" component={PTProfile} isPrivate />
    <Route path="/movimento/pt" component={PTList} isPrivate />
    <Route path="/movimento/video/:tag" component={VideoDetail} isPrivate />
    <Route path="/movimento/videos" component={VideoList} isPrivate />
    <Route path="/movimento/aulas/direto" component={VideoList} isPrivate />
    <Route path="/movimento/inicio" component={HomeMovement} isPrivate />
  </Switch>
);
