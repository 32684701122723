import { PersonalTrainerData } from 'types';

export const personalTrainers: PersonalTrainerData[] = [
  {
    id: '1',
    name: 'Nádia Antunes',
    bio: 'Maria Eduarda e Carlos, que ficara essa noite nos Olivais na sua casinhola, acabavam de almoçar. O Domingos servira o café, e antes de sair deixara ao lado de Carlos a caixa de cigaretes e o Figaro. As duas janelas estavam abertas. Nem uma folha se movia no ar pesado da manhã encoberta, entristecida ainda por um dobre lento de sinos que morria ao longe nos campos.',
    images: {
      profile: '/media/img/pts/nadia-antunes.jpg',
      profile_2x: '/media/img/pts/nadia-antunes@2x.jpg',
      cover:
        'https://images.unsplash.com/photo-1610026470365-48d91e7ba1ae?ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8cGVyc29uYWwlMjB0cmFpbmVyfGVufDB8fDB8fA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=600&q=60',
      cover_2x:
        'https://images.unsplash.com/photo-1610026470365-48d91e7ba1ae?ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8cGVyc29uYWwlMjB0cmFpbmVyfGVufDB8fDB8fA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80'
    },
    social: {
      instagram: 'https://www.instagram.com/',
      facebook: null,
      you_tube: 'https://www.youtube.com/'
    },
    services: [
      {
        id: '1',
        title: 'Fitness',
        price: 20,
        stages: ['aquecimento', 'treino', 'alongamentos']
      },
      {
        id: '2',
        title: 'Yoga',
        price: 30,
        stages: ['aquecimento', 'treino', 'alongamentos']
      },
      {
        id: '3',
        title: 'Cardio',
        price: 40,
        stages: ['aquecimento', 'treino', 'alongamentos']
      },
      {
        id: '4',
        title: 'Lesões',
        price: 50,
        stages: ['aquecimento', 'treino', 'alongamentos']
      }
    ],
    rating: 2.5,
    locations: ['Cascais', 'Oeiras'],
    platforms: ['indoor', 'online'],
    tags: ['mind and body', 'recuperação de lesões', 'cardio'],
    languages: ['PT', 'EN'],
    slug: 'nadia-antunes',
    prices: [30, 23, 50],
    highlight: true
  },
  {
    id: '2',
    name: 'Pedro Santos',
    bio: 'Nessa noite, entre os seus primeiros beijos de noiva, ela mostrara o desejo enternecido de não alterar o plano da Itália e de um ninho romântico entre as flores de Isola-bela: somente agora não iam esconder a inquietação de uma felicidade culpada, mas gozar o repouso de uma felicidade legítima. E, depois de todas as incertezas e tormentos que o tinham agitado desde o dia em que cruzara Maria Eduarda no Aterro, Carlos anelava também pelo momento de se instalar enfim no conforto de um amor sem dúvidas e sem sobressaltos.',
    images: {
      profile: '/media/img/pts/pedro-santos.jpg',
      profile_2x: '/media/img/pts/pedro-santos@2x.jpg',
      cover:
        'https://images.unsplash.com/photo-1593234270120-189e191918a5?ixid=MnwxMjA3fDB8MHxzZWFyY2h8ODl8fHBlcnNvbmFsJTIwdHJhaW5lcnxlbnwwfHwwfHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=600&q=60',
      cover_2x:
        'https://images.unsplash.com/photo-1593234270120-189e191918a5?ixid=MnwxMjA3fDB8MHxzZWFyY2h8ODl8fHBlcnNvbmFsJTIwdHJhaW5lcnxlbnwwfHwwfHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80'
    },
    social: {
      instagram: 'https://www.instagram.com/',
      facebook: 'https://www.facebook.com/',
      you_tube: null
    },
    services: [
      {
        id: '1',
        title: 'Fitness',
        price: 20,
        stages: ['aquecimento', 'treino', 'alongamentos']
      },
      {
        id: '2',
        title: 'Yoga',
        price: 30,
        stages: ['aquecimento', 'treino', 'alongamentos']
      },
      {
        id: '3',
        title: 'Cardio',
        price: 40,
        stages: ['aquecimento', 'treino', 'alongamentos']
      },
      {
        id: '4',
        title: 'Lesões',
        price: 50,
        stages: ['aquecimento', 'treino', 'alongamentos']
      }
    ],
    rating: 3,
    locations: ['Oeiras', 'Linda-a-Velha'],
    platforms: ['indoor', 'outdoor', 'online'],
    tags: ['recuperação de lesões', 'cardio'],
    languages: ['PT', 'EN', 'ES'],
    slug: 'pedro-santos',
    prices: [30, 20, 50],
    highlight: true
  },
  {
    id: '3',
    name: 'Sheila Fagundes',
    bio: 'Quando ela apareceu à porta, toda de branco, corada, com uma das últimas rosas de verão metida no cinto - Maria qui-la mais perto, entre eles, encostada aos seus joelhos. E, arranjando-lhe a fita solta do cabelo, perguntou, muito séria, muito comovida, se ela gostaria que Carlos viesse viver ver com elas de todo e ficar ali na Toca.',
    images: {
      profile: '/media/img/pts/sheila-fagundes.jpg',
      profile_2x: '/media/img/pts/sheila-fagundes@2x.jpg',
      cover:
        'https://images.unsplash.com/photo-1518459031867-a89b944bffe4?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTI3fHxneW0lMjBmaXRuZXNzfGVufDB8fDB8fA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=600&q=60',
      cover_2x:
        'https://images.unsplash.com/photo-1518459031867-a89b944bffe4?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTI3fHxneW0lMjBmaXRuZXNzfGVufDB8fDB8fA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80'
    },
    social: {
      instagram: 'https://www.instagram.com/',
      facebook: 'https://www.facebook.com/',
      you_tube: null
    },
    services: [
      {
        id: '1',
        title: 'Fitness',
        price: 20,
        stages: ['aquecimento', 'treino', 'alongamentos']
      },
      {
        id: '2',
        title: 'Yoga',
        price: 30,
        stages: ['aquecimento', 'treino', 'alongamentos']
      },
      {
        id: '3',
        title: 'Cardio',
        price: 40,
        stages: ['aquecimento', 'treino', 'alongamentos']
      },
      {
        id: '4',
        title: 'Lesões',
        price: 50,
        stages: ['aquecimento', 'treino', 'alongamentos']
      }
    ],
    rating: 1.5,
    locations: ['Oeiras'],
    platforms: ['online'],
    tags: ['recuperação de lesões', 'mind and body'],
    languages: ['PT'],
    slug: 'sheila-fagundes',
    prices: [30, 22, 50],
    highlight: true
  },
  {
    id: '4',
    name: 'João Pita',
    bio: 'Nos degraus do jardim, Carlos parou a olhar, a sentir a doçura velada do céu cinzento... E a vida pareceu-lhe adorável, de uma poesia fina e triste, assim envolta naquela névoa macia onde nada resplandecia e nada cantava, e que tão favorável era para que dois corações, desinteressados do mundo e em desarmonia com ele, se abandonassem juntos ao contínuo encanto de estremecerem juntos na mudez e na sombra.',
    images: {
      profile: '/media/img/pts/joao-pita.jpg',
      profile_2x: '/media/img/pts/joao-pita@2x.jpg',
      cover:
        'https://images.unsplash.com/photo-1536922246289-88c42f957773?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTQ4fHxneW0lMjBmaXRuZXNzfGVufDB8fDB8fA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=600&q=60',
      cover_2x:
        'https://images.unsplash.com/photo-1536922246289-88c42f957773?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTQ4fHxneW0lMjBmaXRuZXNzfGVufDB8fDB8fA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80'
    },
    social: {
      instagram: 'https://www.instagram.com/',
      facebook: 'https://www.facebook.com/',
      you_tube: 'https://www.youtube.com/'
    },
    services: [
      {
        id: '1',
        title: 'Fitness',
        price: 20,
        stages: ['aquecimento', 'treino', 'alongamentos']
      },
      {
        id: '2',
        title: 'Yoga',
        price: 30,
        stages: ['aquecimento', 'treino', 'alongamentos']
      },
      {
        id: '3',
        title: 'Cardio',
        price: 40,
        stages: ['aquecimento', 'treino', 'alongamentos']
      },
      {
        id: '4',
        title: 'Lesões',
        price: 50,
        stages: ['aquecimento', 'treino', 'alongamentos']
      }
    ],
    rating: 3,
    locations: ['Oeiras', 'Alfragide'],
    platforms: ['indoor', 'outdoor', 'online'],
    tags: ['recuperação de lesões', 'força'],
    languages: ['PT', 'FR'],
    slug: 'joao-pita',
    prices: [30, 24, 50],
    highlight: false
  },
  {
    id: '5',
    name: 'Liliana Ferreira',
    bio: 'E no seu desejo de ver todos em torno de si felizes como ele e como a terra sequiosa que ia ser consolada - Carlos meteu uma libra na mão do tio André, que ficou deslumbrado, sem ousar fechar os dedos sobre aquele ouro extraordinário que reluziu.',
    images: {
      profile: '/media/img/pts/liliana-ferreira.jpg',
      profile_2x: '/media/img/pts/liliana-ferreira@2x.jpg',
      cover:
        'https://images.unsplash.com/photo-1540206063137-4a88ca974d1a?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTF8fHBlcnNvbmFsJTIwdHJhaW5lcnxlbnwwfHwwfHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=600&q=60',
      cover_2x:
        'https://images.unsplash.com/photo-1540206063137-4a88ca974d1a?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTF8fHBlcnNvbmFsJTIwdHJhaW5lcnxlbnwwfHwwfHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80'
    },
    social: {
      instagram: 'https://www.instagram.com/',
      facebook: 'https://www.facebook.com/',
      you_tube: null
    },
    services: [
      {
        id: '1',
        title: 'Fitness',
        price: 20,
        stages: ['aquecimento', 'treino', 'alongamentos']
      },
      {
        id: '2',
        title: 'Yoga',
        price: 30,
        stages: ['aquecimento', 'treino', 'alongamentos']
      },
      {
        id: '3',
        title: 'Cardio',
        price: 40,
        stages: ['aquecimento', 'treino', 'alongamentos']
      },
      {
        id: '4',
        title: 'Lesões',
        price: 50,
        stages: ['aquecimento', 'treino', 'alongamentos']
      }
    ],
    rating: 2.8,
    locations: ['Oeiras', 'Linda-a-Velha'],
    platforms: ['indoor', 'outdoor'],
    tags: ['mind and body', 'força'],
    languages: ['PT', 'EN'],
    slug: 'liliana-ferreira',
    prices: [45, 20, 50],
    highlight: false
  },
  {
    id: '6',
    name: 'Luís Murteira',
    bio: 'Carlos esperava, comovido. Sabia que aqueles lábios amados iam fazer revelações pungentes para o seu coração e amargas para o seu orgulho. Mas a confidência da sua vida completava a posse da sua pessoa: quando a conhecesse toda no seu passado senti-la-ia mais sua inteiramente. E no fundo tinha uma curiosidade insaciável dessas coisas que o deviam pungir e que o deviam humilhar.',
    images: {
      profile: '/media/img/pts/luis-murteira.jpg',
      profile_2x: '/media/img/pts/luis-murteira@2x.jpg',
      cover:
        'https://images.unsplash.com/photo-1549060279-7e168fcee0c2?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTA1fHxneW0lMjBmaXRuZXNzfGVufDB8fDB8fA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=600&q=60',
      cover_2x:
        'https://images.unsplash.com/photo-1549060279-7e168fcee0c2?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTA1fHxneW0lMjBmaXRuZXNzfGVufDB8fDB8fA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80'
    },
    social: {
      instagram: 'https://www.instagram.com/',
      facebook: null,
      you_tube: 'https://www.youtube.com/'
    },
    services: [
      {
        id: '1',
        title: 'Fitness',
        price: 20,
        stages: ['aquecimento', 'treino', 'alongamentos']
      },
      {
        id: '2',
        title: 'Yoga',
        price: 30,
        stages: ['aquecimento', 'treino', 'alongamentos']
      },
      {
        id: '3',
        title: 'Cardio',
        price: 40,
        stages: ['aquecimento', 'treino', 'alongamentos']
      },
      {
        id: '4',
        title: 'Lesões',
        price: 50,
        stages: ['aquecimento', 'treino', 'alongamentos']
      }
    ],
    rating: 4.2,
    locations: ['Oeiras'],
    platforms: ['indoor', 'online'],
    tags: ['força', 'halterofilismo'],
    languages: ['PT', 'EN'],
    slug: 'luis-murteira',
    prices: [45, 30, 50],
    highlight: false
  },
  {
    id: '7',
    name: 'Juan Gonçalves',
    bio: 'Quando ela madrugava, com os seus hábitos saudáveis do convento, encontrava paletós de homens por cima dos sofás: no mármore das consoles restavam pontas de charuto entre nódoas de champagne; e nalgum quarto mais retirado ainda tinia o dinheiro de um bacará talhado à claridade do sol. Depois uma noite, estando deitada, sentira de repente gritos, uma debandada brusca na escada; veio encontrar a mamã estirada no tapete, desmaiada; ela dissera-lhe apenas mais tarde, alagada em lágrimas, «que tinha havido uma desgraça»...',
    images: {
      profile: '/media/img/pts/juan-goncalves.jpg',
      profile_2x: '/media/img/pts/juan-goncalves@2x.jpg',
      cover:
        'https://images.unsplash.com/photo-1599058917212-d750089bc07e?ixid=MnwxMjA3fDB8MHxzZWFyY2h8NDV8fGd5bSUyMGZpdG5lc3N8ZW58MHx8MHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=600&q=60',
      cover_2x:
        'https://images.unsplash.com/photo-1599058917212-d750089bc07e?ixid=MnwxMjA3fDB8MHxzZWFyY2h8NDV8fGd5bSUyMGZpdG5lc3N8ZW58MHx8MHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80'
    },
    social: {
      instagram: 'https://www.instagram.com/',
      facebook: null,
      you_tube: 'https://www.youtube.com/'
    },
    services: [
      {
        id: '1',
        title: 'Fitness',
        price: 20,
        stages: ['aquecimento', 'treino', 'alongamentos']
      },
      {
        id: '2',
        title: 'Yoga',
        price: 30,
        stages: ['aquecimento', 'treino', 'alongamentos']
      },
      {
        id: '3',
        title: 'Cardio',
        price: 40,
        stages: ['aquecimento', 'treino', 'alongamentos']
      },
      {
        id: '4',
        title: 'Lesões',
        price: 50,
        stages: ['aquecimento', 'treino', 'alongamentos']
      }
    ],
    rating: 1.2,
    locations: ['Oeiras', 'Olivais'],
    platforms: ['indoor', 'outdoor', 'online'],
    tags: ['força', 'halterofilismo', 'recuperação de lesões'],
    languages: ['PT', 'EN', 'ES'],
    slug: 'juan-goncalves',
    prices: [45, 32, 50],
    highlight: true
  },
  {
    id: '8',
    name: 'Catarina Jacinto',
    bio: 'E, como Carlos o acusava outra vez de trazer de Celorico uma língua imunda, o Ega, um pouco corado, arrependido talvez, lançou-se em considerações críticas, clamando pela necessidade social de dar às coisas o nome exato. Para que servia então o grande movimento naturalista do século? Se o vício se perpetuava, é porque a sociedade, indulgente e romanesca, lhe dava nomes que o embelezavam, que o idealizavam... Que escrúpulo pode ter uma mulher em beijocar um terceiro entre os lençóis conjugais, se o mundo chama a isso sentimentalmente um romance, e os poetas o cantam em estrofes de ouro?',
    images: {
      profile: '/media/img/pts/catarina-jacinto.jpg',
      profile_2x: '/media/img/pts/catarina-jacinto@2x.jpg',
      cover:
        'https://images.unsplash.com/photo-1518644961665-ed172691aaa1?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTUwfHxneW0lMjBmaXRuZXNzfGVufDB8fDB8fA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=600&q=60',
      cover_2x:
        'https://images.unsplash.com/photo-1518644961665-ed172691aaa1?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTUwfHxneW0lMjBmaXRuZXNzfGVufDB8fDB8fA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80'
    },
    social: {
      instagram: 'https://www.instagram.com/',
      facebook: 'https://www.facebook.com/',
      you_tube: 'https://www.youtube.com/'
    },
    services: [
      {
        id: '1',
        title: 'Fitness',
        price: 20,
        stages: ['aquecimento', 'treino', 'alongamentos']
      },
      {
        id: '2',
        title: 'Yoga',
        price: 30,
        stages: ['aquecimento', 'treino', 'alongamentos']
      },
      {
        id: '3',
        title: 'Cardio',
        price: 40,
        stages: ['aquecimento', 'treino', 'alongamentos']
      },
      {
        id: '4',
        title: 'Lesões',
        price: 50,
        stages: ['aquecimento', 'treino', 'alongamentos']
      }
    ],
    rating: 2.8,
    locations: ['Oeiras', 'Massamá'],
    platforms: ['indoor', 'outdoor', 'online'],
    tags: ['força', 'recuperação de lesões'],
    languages: ['PT', 'EN', 'ES'],
    slug: 'catarina-jacinto',
    prices: [45, 36, 50],
    highlight: true
  },
  {
    id: '9',
    name: 'Mafalda Macedo',
    bio: 'Foram encontrar Afonso da Maia no escritório, na sua velha poltrona, com um antigo volume da Ilustração francesa aberto sobre os joelhos, mostrando as estampas a um pequeno bonito, muito moreno, de olho vivo, e cabelo encarapinhado. O velho ficou contentíssimo ao saber que o Ega vinha por algum tempo alegrar o Ramalhete com a sua bela fantasia.',
    images: {
      profile: '/media/img/pts/mafalda-macedo.jpg',
      profile_2x: '/media/img/pts/mafalda-macedo@2x.jpg',
      cover:
        'https://images.unsplash.com/photo-1608138404239-d2f557515ecb?ixid=MnwxMjA3fDB8MHxzZWFyY2h8Nnx8b3V0ZG9vciUyMGZpdG5lc3N8ZW58MHx8MHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=600&q=60',
      cover_2x:
        'https://images.unsplash.com/photo-1608138404239-d2f557515ecb?ixid=MnwxMjA3fDB8MHxzZWFyY2h8Nnx8b3V0ZG9vciUyMGZpdG5lc3N8ZW58MHx8MHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80'
    },
    social: {
      instagram: 'https://www.instagram.com/',
      facebook: 'https://www.facebook.com/',
      you_tube: null
    },
    services: [
      {
        id: '1',
        title: 'Fitness',
        price: 20,
        stages: ['aquecimento', 'treino', 'alongamentos']
      },
      {
        id: '2',
        title: 'Yoga',
        price: 30,
        stages: ['aquecimento', 'treino', 'alongamentos']
      },
      {
        id: '3',
        title: 'Cardio',
        price: 40,
        stages: ['aquecimento', 'treino', 'alongamentos']
      },
      {
        id: '4',
        title: 'Lesões',
        price: 50,
        stages: ['aquecimento', 'treino', 'alongamentos']
      }
    ],
    rating: 1,
    locations: ['Oeiras', 'Massamá'],
    platforms: ['indoor', 'outdoor'],
    tags: ['cardio', 'resistência'],
    languages: ['PT', 'EN'],
    slug: 'mafalda-macedo',
    prices: [45, 26, 50],
    highlight: false
  },
  {
    id: '10',
    name: 'Jóni Vilela',
    bio: 'A única coisa a fazer em Portugal, dizia ele, é plantar legumes, enquanto não há uma revolução que faça subir à superfície alguns dos elementos originais, fortes, vivos, que isto ainda cerre lá no fundo. E se se vir então que não encerra nada, demitamos-nos logo voluntariamente da nossa posição de país para que não temos elementos, passemos a ser uma fértil e estúpida província espanhola, e plantemos mais legumes!',
    images: {
      profile: '/media/img/pts/joni-vilela.jpg',
      profile_2x: '/media/img/pts/joni-vilela@2x.jpg',
      cover:
        'https://images.unsplash.com/photo-1521804906057-1df8fdb718b7?ixid=MnwxMjA3fDB8MHxzZWFyY2h8OXx8Zml0bmVzcyUyQnxlbnwwfHwwfHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=600&q=60',
      cover_2x:
        'https://images.unsplash.com/photo-1521804906057-1df8fdb718b7?ixid=MnwxMjA3fDB8MHxzZWFyY2h8OXx8Zml0bmVzcyUyQnxlbnwwfHwwfHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80'
    },
    social: {
      instagram: 'https://www.instagram.com/',
      facebook: 'https://www.facebook.com/',
      you_tube: null
    },
    services: [
      {
        id: '1',
        title: 'Fitness',
        price: 20,
        stages: ['aquecimento', 'treino', 'alongamentos']
      },
      {
        id: '2',
        title: 'Yoga',
        price: 30,
        stages: ['aquecimento', 'treino', 'alongamentos']
      },
      {
        id: '3',
        title: 'Cardio',
        price: 40,
        stages: ['aquecimento', 'treino', 'alongamentos']
      },
      {
        id: '4',
        title: 'Lesões',
        price: 50,
        stages: ['aquecimento', 'treino', 'alongamentos']
      }
    ],
    rating: 1.2,
    locations: ['Amadora'],
    platforms: ['indoor', 'outdoor', 'online'],
    tags: ['cardio', 'resistência', 'musculação'],
    languages: ['PT', 'EN', 'FR'],
    slug: 'joni-vilela',
    prices: [45, 30, 50],
    highlight: false
  }
];
