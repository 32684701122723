import { VideoData } from 'pages/Private/Movement/VideoList/types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FiSearch } from 'react-icons/fi';
import { Slide, toast, ToastContainer } from 'react-toastify';
import { FaqsData, PersonalTrainerData } from 'types';

import { Form } from './styles';

export type SearchableContent = {
  faqs?: FaqsData[] | undefined;
  pts?: PersonalTrainerData[] | undefined;
  videos?: VideoData[] | undefined;
};

interface SearchFormProps {
  label: string;
  content: SearchableContent;
  setCurrentContent: React.Dispatch<React.SetStateAction<SearchableContent>>;
  area: 'faqs' | 'pts' | 'videos';
}

export const SearchForm: React.FC<SearchFormProps> = ({
  label,
  setCurrentContent,
  content,
  area
}: SearchFormProps) => {
  const searchInputRef = useRef<HTMLInputElement>(null);
  const [allContent, setAllContent] = useState<SearchableContent>([] as SearchableContent);
  const [notFound, setNotFound] = useState(false);

  useEffect(() => {
    setAllContent(content);
  }, [content]);

  useEffect(() => {
    if (notFound) useToast();
  }, [notFound]);

  const useToast = useCallback(() => {
    toast.dark('Nenhum resultado encontrado', {
      position: 'top-center',
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      delay: 0,
      pauseOnFocusLoss: true,
      transition: Slide,
      draggablePercent: 60,
      role: 'alert'
    });
  }, []);

  const handleInputFocus = useCallback(() => {
    if (searchInputRef.current)
      searchInputRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }, []);

  const handleSearchInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === '') {
      setCurrentContent(allContent);
      return;
    }

    if (area === 'faqs') {
      const results =
        allContent.faqs?.filter(
          ({ label, description }) =>
            label.toLocaleLowerCase().includes(event.target.value.toLocaleLowerCase()) ||
            description.toLocaleLowerCase().includes(event.target.value.toLocaleLowerCase())
        ) || [];

      if (results.length === 0) {
        setCurrentContent(allContent);
        setNotFound(true);

        setTimeout(() => {
          setNotFound(false);
        }, 2000);
        return;
      }

      setCurrentContent({ faqs: results });
    }

    if (area === 'pts') {
      const results =
        allContent.pts?.filter(({ name }) =>
          name.toLocaleLowerCase().includes(event.target.value.toLocaleLowerCase())
        ) || [];

      if (results.length === 0) {
        setCurrentContent(allContent);
        setNotFound(true);

        setTimeout(() => {
          setNotFound(false);
        }, 2000);
        return;
      }

      setCurrentContent({ pts: results });
    }

    if (area === 'videos') {
      const results = allContent.videos?.filter(
        ({ details }) =>
          details.name.toLocaleLowerCase().includes(event.target.value.toLocaleLowerCase()) ||
          details.description.toLocaleLowerCase().includes(event.target.value.toLocaleLowerCase())
      );

      if (results?.length === 0) {
        setCurrentContent(allContent);
        setNotFound(true);

        setTimeout(() => {
          setNotFound(false);
        }, 2000);
        return;
      }

      setCurrentContent({ videos: results });
    }
  };

  return (
    <>
      <Form>
        <input
          ref={searchInputRef}
          placeholder={label}
          type="text"
          onChange={handleSearchInput}
          onFocus={handleInputFocus}
          onSubmit={(event) => event.preventDefault()}
        />
        <span>
          <FiSearch />
        </span>
      </Form>
      <ToastContainer limit={1} newestOnTop={false} rtl={false} />
    </>
  );
};
