import { ActivityDuration } from 'types';

export function convertActivityDuration(time: ActivityDuration): string | undefined {
  switch (time) {
    case '15':
      return '5 a 15 min';
    case '30':
      return '15 a 30 min';
    case '45':
      return '30 a 45 min';
    case '60':
      return '45 a 60 min';
    case '75':
      return '60 a 75 min';

    default:
      break;
  }
}
