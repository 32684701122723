import { LogIn, PasswordRecover, PasswordReset, Welcome } from 'pages/Public';
import { Switch } from 'react-router-dom';

import { Route } from './Route';

export const PublicRoutes: React.FC = () => (
  <Switch>
    <Route path="/" exact component={Welcome} />
    <Route path="/login" component={LogIn} />
    <Route path="/recuperar" component={PasswordRecover} />
    <Route path="/reset" component={PasswordReset} />
  </Switch>
);
