import { LogoHi } from 'assets/vectors';
import { IconButton } from 'components';
import { useLocation } from 'hooks/useLocation';
import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { colors } from 'static';

import { Nav } from './Nav';
import { HeaderContainer } from './styles';

interface HeaderProps {
  hasUnreadNotifications?: boolean;
  backButtonLink?: string;
}

export const Header: React.FC<HeaderProps> = ({
  hasUnreadNotifications,
  backButtonLink = '/home'
}: HeaderProps) => {
  const history = useHistory();
  const { white } = colors;
  const { setLocation, location } = useLocation();
  const [scrolled, setScrolled] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    setLocation(window.location.pathname || '');
  }, []);

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', () =>
      document.body.getBoundingClientRect().y < 0 ? setScrolled(true) : setScrolled(false)
    );
  }, []);

  return (
    <>
      <HeaderContainer
        className={`${location?.slice(1).split('/').shift()} ${scrolled ? 'scrolled' : ''}`}>
        {location === '/home' ? (
          <IconButton
            icon="notification"
            color={white}
            onClick={() => console.log('open notifications')}
            className={`notification-btn ${hasUnreadNotifications ? 'new' : ''} ${
              scrolled ? 'scrolled' : ''
            }`}
          />
        ) : (
          <IconButton
            icon="back"
            color={white}
            className={scrolled ? 'scrolled' : undefined}
            onClick={() => history.push(backButtonLink)}
          />
        )}
        <Link to="/home">
          <LogoHi className="logo" />
        </Link>
        <IconButton
          icon="menu"
          color="#fff"
          isOpen={menuOpen}
          className={scrolled ? 'scrolled' : undefined}
          onClick={() => setMenuOpen(!menuOpen)}
        />
      </HeaderContainer>
      <Nav
        menuOpen={menuOpen}
        setMenuOpen={setMenuOpen}
        location={location}
        hasUnreadNotifications={hasUnreadNotifications || false}
      />
    </>
  );
};
