import { ActivityName } from 'types';

export function convertActivityName(name: ActivityName): string {
  switch (name) {
    case 'bike':
      return 'bicicleta';
    case 'run':
      return 'corrida';
    case 'surf':
      return 'surf';
    case 'swim':
      return 'natação';
    case 'walk':
      return 'caminhada';
  }
}
