import styled from 'styled-components';

export const Btn = styled.button`
  font-size: 1.6rem;
  letter-spacing: 0.01rem;
  line-height: 0;
  font-weight: var(--f-semi-bold);
  text-transform: capitalize;
  color: var(--c-night-blue-900);

  background: var(--c-white);
  border: none;
  border-radius: 1.6rem;

  width: 100%;
  height: 5.2rem;
  display: block;

  margin-left: auto;
  margin-right: auto;

  transition: filter 300ms ease-in-out;

  &:hover {
    filter: brightness(0.9);
  }

  &.md {
    width: unset;
    padding: 1rem 1.6rem;
    font-size: 1.6rem;
    height: 4.2rem;
    border-radius: 1rem;
  }

  &.xs {
    width: unset;
    padding: 1.1rem 2rem;
    font-size: 1.4rem;
    height: 3.6rem;
    border-radius: 1rem;
  }

  @media screen and (min-height: 660px) {
    font-size: 1.8rem;
    height: 5.6rem;
  }
`;
