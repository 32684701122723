import styled from 'styled-components';

export const Select = styled.label`
  display: block;
  position: relative;
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  margin-bottom: 3rem;

  & > * {
    color: inherit;
    font-family: inherit;
    font-size: inherit;
  }

  label {
    position: absolute;
    top: -0.7rem;
    left: 50%;
    transform: translateX(-50%);
    background: var(--c-ocean-blue-300);
    font-size: 1.2rem;
    text-transform: uppercase;
    padding: 0 0.8rem;
    color: var(--c-white);
    letter-spacing: 0.1rem;
    z-index: 1;
  }

  select {
    width: 100%;
    font-size: 1.6rem;
    line-height: 1;
    padding: 0.8rem;
    border: 1px solid var(--c-white);
    color: var(--c-white);
    background: transparent;
    height: 4rem;
    border-radius: 2rem;
    text-align: center;
    text-align-last: center;
    appearance: none;
    -webkit-appearance: none;
  }

  .holder {
    pointer-events: none;
    color: var(--c-white);
    background: var(--c-ocean-blue-300);
    border: 1px solid var(--c-white);
    line-height: 2rem;
    text-align: center;
    height: 4rem;
    border-radius: 2rem;

    display: flex;
    align-items: center;
    justify-content: center;

    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
  }
`;
