import { ScoreGraph } from 'components';
import React from 'react';
import { graphs } from 'static';
import SwiperCore, { A11y, Autoplay, Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { Section } from './styles';

SwiperCore.use([Navigation, Pagination, A11y, Autoplay]);

export const ScoreSection: React.FC = () => {
  return (
    <Section>
      {/* <span className="sub-title">bate recordes</span> */}
      <h2>Os teus pontos</h2>
      <p>Conquista os teus objetivos!</p>
      <Swiper
        slidesPerView={2}
        spaceBetween={8}
        pagination={{ clickable: true }}
        className="slider">
        {graphs.map((graph, i) => (
          <SwiperSlide key={i}>
            <ScoreGraph graph={graph} card_size="xs" />
          </SwiperSlide>
        ))}
      </Swiper>
    </Section>
  );
};
