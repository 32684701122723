import { config } from 'react-spring';

export const fadeInConfig = {
  from: { opacity: 0, y: 10 },
  to: { opacity: 1, y: 0 },
  delay: 200,
  config: config.slow
};

export const fadeInWidthDelayConfig = {
  from: { opacity: 0, y: 10 },
  to: { opacity: 1, y: 0 },
  delay: 1200,
  config: config.slow
};

export const appearFromBottomConfig = {
  from: { y: 200 },
  to: { y: 0 },
  delay: 2000,
  config: config.slow
};
