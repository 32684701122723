import {
  IconFacebook,
  IconInstagram,
  IconPhone,
  IconSkype,
  IconStar,
  IconWhatsapp,
  IconYouTube
} from 'assets/icons';
import { CommentCard, Header, IconButton, Main, Tag } from 'components';
import React, { useCallback } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { colors, comments, personalTrainers } from 'static';
import SwiperCore, { A11y, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { CommentData, PersonalTrainerData } from 'types';

import {
  CommentsList,
  ContactList,
  Cover,
  CoverImage,
  Languages,
  PriceList,
  Profile,
  ProfileHeader,
  ProfileImage,
  Rating,
  SocialMedia,
  Tags
} from './styles';

interface Params {
  slug: string;
}

SwiperCore.use([A11y, Pagination]);

export const PTProfile: React.FC = () => {
  const { slug } = useParams<Params>();
  const { push } = useHistory();
  const { oldNavyBlue800 } = colors;
  const [personalTrainer, setPersonalTrainer] = useState<PersonalTrainerData | undefined>(
    {} as PersonalTrainerData
  );
  const [clientsComments, setClientsComments] = useState<CommentData[]>([] as CommentData[]);

  useEffect(() => {
    setPersonalTrainer(personalTrainers.filter((pt) => pt.slug === slug).shift());
  }, [slug]);

  useEffect(() => {
    setClientsComments(comments.filter((comment) => comment.pt_id === personalTrainer?.id).sort());
  }, [personalTrainer]);

  if (!personalTrainer) {
    push('/movimento/pt');
    return <h1>Nenhum Personal Trainer encontrado</h1>;
  }

  const handleBooking = useCallback((id) => {
    // TO DO
    console.log({ id });
  }, []);

  return (
    <>
      <Header backButtonLink="/movimento/pt" />
      <Main>
        {personalTrainer.name && (
          <>
            <Cover>
              <span className="sub-title">PT profile</span>
              <h1>{personalTrainer.name}</h1>
              <Rating>
                <IconStar className={personalTrainer.rating >= 1 ? 'on' : undefined} />
                <IconStar className={personalTrainer.rating >= 2 ? 'on' : undefined} />
                <IconStar className={personalTrainer.rating >= 3 ? 'on' : undefined} />
              </Rating>
              <CoverImage
                srcSet={`${personalTrainer.images.cover_2x} 2x, ${personalTrainer.images.cover}`}
                src={personalTrainer.images.cover}
                alt={personalTrainer.name}
              />
              <ProfileHeader>
                <Languages>
                  {personalTrainer.languages.map((lang, i) => (
                    <span key={i}>{lang}</span>
                  ))}
                </Languages>
                <ProfileImage
                  srcSet={`${personalTrainer.images.profile_2x} 2x, ${personalTrainer.images.profile}`}
                  src={personalTrainer.images.profile}
                  alt={personalTrainer.name}
                />
                <SocialMedia>
                  {personalTrainer.social.facebook && (
                    <Link
                      to={personalTrainer.social.facebook}
                      target="_blank"
                      rel="noreferrer noopener">
                      <IconFacebook />
                    </Link>
                  )}
                  {personalTrainer.social.instagram && (
                    <Link
                      to={personalTrainer.social.instagram}
                      target="_blank"
                      rel="noreferrer noopener">
                      <IconInstagram />
                    </Link>
                  )}
                  {personalTrainer.social.you_tube && (
                    <Link
                      to={personalTrainer.social.you_tube}
                      target="_blank"
                      rel="noreferrer noopener">
                      <IconYouTube />
                    </Link>
                  )}
                </SocialMedia>
              </ProfileHeader>
            </Cover>
            <Profile>
              <div className="locations">
                {personalTrainer.locations.map((local, i) => (
                  <span key={i}>
                    {local}
                    {i < personalTrainer.locations.length - 1 ? ', ' : ''}
                  </span>
                ))}
              </div>
              <div className="platforms">
                {personalTrainer.platforms.map((platform, i) => (
                  <span key={i}>
                    {platform} {i < personalTrainer.platforms.length - 1 ? ' • ' : ''}
                  </span>
                ))}
              </div>
              <p className="bio">{personalTrainer.bio}</p>
              <Tags>
                <h2>Especialidades</h2>
                <div className="tag-container">
                  {personalTrainer.tags.map((tag, i) => (
                    <Tag key={i}>{tag}</Tag>
                  ))}
                </div>
              </Tags>
            </Profile>
            <PriceList>
              <div className="header">
                <h2>Agendar</h2>
                <IconButton icon="three-dots" color={oldNavyBlue800} />
              </div>
              <Swiper
                slidesPerView={2}
                spaceBetween={16}
                pagination={{ clickable: true }}
                className="slider">
                {personalTrainer.services.map(({ price, id, stages, title }, i) => (
                  <SwiperSlide key={i} className="card">
                    <h3>
                      €<span>{price}</span>
                    </h3>
                    <strong>{title}</strong>
                    <ul>
                      {stages.map((stage, i) => (
                        <li key={i}>{stage}</li>
                      ))}
                    </ul>
                    <button onClick={() => handleBooking(id)}>Agendar</button>
                  </SwiperSlide>
                ))}
              </Swiper>
              <p className="conditions">
                Válido para maiores de 16 anos de 21 a 31/08/2021. Não acumulável com outras
                campanhas em vigor.
              </p>
            </PriceList>
            <ContactList>
              <h2>Contactos</h2>
              <div className="container">
                <button>
                  <IconPhone />
                </button>
                <button>
                  <IconSkype />
                </button>
                <button>
                  <IconWhatsapp />
                </button>
              </div>
            </ContactList>
            <CommentsList>
              <h2>Opiniões</h2>
              <div className="container">
                {clientsComments.length > 0 ? (
                  clientsComments.map((comment, i) => <CommentCard {...comment} key={i} />)
                ) : (
                  <p>Não há comentários disponíveis</p>
                )}
              </div>
            </CommentsList>
          </>
        )}
      </Main>
    </>
  );
};
