import { config } from 'react-spring';

export const slowDropConfig = {
  from: { y: -10 },
  to: { y: 0 },
  delay: 200,
  config: config.slow
};

export const bigDropConfig = {
  from: { y: -50 },
  to: { y: 0 },
  delay: 200,
  config: config.molasses
};
