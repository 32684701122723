import { InputHTMLAttributes } from 'react';
import { FiAlertCircle } from 'react-icons/fi';
import { stringToSlug } from 'utils';

import { AlertIcon, InputContainer } from './styles';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  label?: string;
  alert?: boolean;
}

export const Input: React.FC<InputProps> = ({ name, label, alert = true, ...rest }: InputProps) => {
  return (
    <InputContainer>
      {label && <label htmlFor={stringToSlug(name)}>{label}</label>}
      <input id={stringToSlug(name)} name={stringToSlug(name)} {...rest} />
      {alert && (
        <AlertIcon>
          <FiAlertCircle fill="#fff" stroke="#5e4adf" size={24} />
        </AlertIcon>
      )}
    </InputContainer>
  );
};
