import React, { createContext, useCallback, useContext, useState } from 'react';
import { users } from 'static';
import { UserData } from 'types';

// import api from '../services/api';

interface AuthState {
  token: string;
  user: UserData;
}

interface SignInCredentials {
  email: string;
  password: string;
}

interface AuthContextData {
  user: UserData;
  signIn(credentials: SignInCredentials): Promise<void>;
  signOut(): void;
  updateUser(user: UserData): void;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

export const AuthProvider: React.FC = ({ children }: { children?: React.ReactNode }) => {
  const [data, setData] = useState<AuthState>(() => {
    const token = localStorage.getItem('@hi:token');
    const user = localStorage.getItem('@hi:user');

    if (token && user) {
      // api.defaults.headers.authorization = `Bearer ${token}`;
      return { token, user: JSON.parse(user) };
    }

    return {} as AuthState;
  });

  const signIn = useCallback(async ({ email, password }) => {
    // const response = await api.post('sessions', {
    //   email,
    //   password
    // });

    // dummy
    const response = {
      data: {
        user:
          users.filter((user) => user.email === email && user.password === password).shift() ||
          ({} as UserData),
        token: '7b96e636e4bd247fc6dfe3371a194766'
      }
    };

    const { token, user } = response.data;

    if (!user.name) {
      throw new Error('verifique os seus dados de acesso');
    }

    localStorage.setItem('@hi:token', token);
    localStorage.setItem('@hi:user', JSON.stringify(user));

    // api.defaults.headers.authorization = `Bearer ${token}`;

    setData({ token, user });
  }, []);

  const signOut = useCallback(() => {
    localStorage.removeItem('@hi:token');
    localStorage.removeItem('@hi:user');

    setData({} as AuthState);
  }, []);

  const updateUser = useCallback(
    (user: UserData) => {
      localStorage.setItem('@hi:user', JSON.stringify(user));

      setData({
        token: data.token,
        user
      });
    },
    [setData, data.token]
  );

  return (
    <AuthContext.Provider value={{ user: data.user, signIn, signOut, updateUser }}>
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth(): AuthContextData {
  const context = useContext(AuthContext);
  if (!context) throw new Error('useAuth must be used withing an AuthProvider');
  return context;
}
