import { UserData } from 'types';

export const users: UserData[] = [
  {
    id: '1',
    name: 'Bruno Lobato',
    image: 'https://avatars.githubusercontent.com/u/57861255?v=4',
    points: 216,
    email: 'wolf@hi.com',
    password: '000',
    gym_access: 'FIT16115',
    insurance_card: 'FIT16115'
  },
  {
    id: '2',
    name: 'Margot Robbie',
    image: 'https://br.web.img3.acsta.net/pictures/19/11/12/22/54/0812791.jpg',
    points: 182,
    email: 'mar@hi.com',
    password: '000',
    gym_access: 'AMO16115',
    insurance_card: 'FIT16115'
  },
  {
    id: '3',
    name: 'Elvis Presley',
    image: 'https://upload.wikimedia.org/wikipedia/commons/8/82/Elvis_Presley_1970.jpg',
    points: 666,
    email: 'elvis@hi.com',
    password: '000',
    gym_access: null,
    insurance_card: 'FIT16115'
  },
  {
    id: '4',
    name: 'Fernando Mendes',
    image: 'https://images.impresa.pt/famashow/2018-05-10-fernandomendes.jpg-1/original/mw-860',
    points: 0,
    email: 'mendes@hi.com',
    password: '000',
    gym_access: null,
    insurance_card: null
  },
  {
    id: '5',
    name: 'Carla Silva',
    image: null,
    points: 92,
    email: 'user@hi.com',
    password: '000',
    gym_access: null,
    insurance_card: null
  }
];
