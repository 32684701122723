import styled from 'styled-components';

export const Message = styled.div`
  display: none;

  @media screen and (min-width: 425px) {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    background: radial-gradient(var(--c-main-1), var(--c-main-2));
    background-position: 0% 50%;
    background-size: 400% 400%;

    animation: Gradient 10000ms ease infinite;
    animation-play-state: running;

    .container {
      width: 90%;
      max-width: 28rem;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .logo {
        fill: var(--c-white);
        width: 12rem;
        height: 12rem;
        margin-bottom: 6rem;
      }

      & > * {
        color: var(--c-white);
        text-align: center;
      }

      h2 {
        font-size: 2.4rem;
      }
    }
  }
`;
