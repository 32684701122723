import 'swiper/swiper-bundle.css';

import { DesktopMessage } from 'components';
import { AppProvider } from 'hooks';
import { useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import Routes from 'routes';
import GlobalStyle from 'styles/global';
import { setViewportHeight } from 'utils';

const App: React.FC = () => {
  const [desktopMessage, setDesktopMessage] = useState(false);

  useEffect(setViewportHeight, []);

  useEffect(() => {
    if (window.matchMedia('(min-width: 500px)').matches) {
      setDesktopMessage(true);
    }
  }, []);

  return (
    <BrowserRouter>
      <AppProvider>
        {desktopMessage ? <DesktopMessage /> : <Routes />}
        <GlobalStyle />
      </AppProvider>
    </BrowserRouter>
  );
};

export default App;
