import styled from 'styled-components';

export const Section = styled.section`
  background: var(--c-white);
  padding: 5.6rem 2rem;

  .section-header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
  }

  .accordion-container {
    min-height: 100vh;

    .accordion-item {
      & > button {
        background: var(--c-white);
        color: var(--c-night-blue-900);
        font-size: 1.4rem;
        text-align: left;
        border: none;

        svg {
          stroke: var(--c-smoke-grey-400);
        }
      }

      & > div {
        border: none;
      }

      .content {
        padding: 1rem;
        font-size: 1.4rem;
        line-height: 1.6;
      }

      & + .accordion {
        margin-top: 1rem;
      }
    }
  }
`;
