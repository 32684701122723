import {
  IconBonusOutline,
  IconHealthOutline,
  IconMovementOutline,
  IconNutritionOutline,
  IconsGraphs
} from 'assets/icons';
import { Button, ScoreBadge } from 'components';
import { formatDistanceToNow } from 'date-fns';
import PTLocale from 'date-fns/locale/pt';
import { animated, config, Spring } from 'react-spring';
import VisibilitySensor from 'react-visibility-sensor';
import { GraphData } from 'types';
import { areaToEN } from 'utils';

import { Container } from './styles';

interface ScoreGraphProps extends React.HTMLProps<HTMLDivElement> {
  graph: GraphData;
  card_size: 'xs' | 'md';
}

export const ScoreGraph: React.FC<ScoreGraphProps> = ({
  graph,
  card_size,
  className
}: ScoreGraphProps) => {
  const { area, last_achievement, update_time, total_points, current_points } = graph;
  const { name, points } = last_achievement;

  return (
    <>
      <IconsGraphs height="0" width="0" />
      <Container className={`${areaToEN(area)} ${card_size} ${className || ''}`}>
        {card_size === 'xs' && <span className="label">{area}</span>}

        {areaToEN(area) === 'bonus' && (
          <IconBonusOutline className={`graph-outline ${areaToEN(area)}`} />
        )}
        {areaToEN(area) === 'movement' && (
          <IconMovementOutline className={`graph-outline ${areaToEN(area)}`} />
        )}
        {areaToEN(area) === 'nutrition' && (
          <IconNutritionOutline className={`graph-outline ${areaToEN(area)}`} />
        )}
        {areaToEN(area) === 'health' && (
          <IconHealthOutline className={`graph-outline ${areaToEN(area)}`} />
        )}

        <VisibilitySensor>
          {({ isVisible }) => (
            <Spring
              delay={400}
              to={{
                y: isVisible ? 0 : 200
              }}
              config={config.slow}>
              {({ y }) => (
                <div
                  className={`graph ${areaToEN(area)}`}
                  style={{ clipPath: `url(#i-${areaToEN(area)})` }}>
                  <animated.div
                    className="progress"
                    style={{
                      y,
                      height: `${(current_points * 100) / total_points}%`
                    }}></animated.div>
                </div>
              )}
            </Spring>
          )}
        </VisibilitySensor>
        {card_size === 'xs' && (
          <>
            <span className="status">
              <strong>{current_points}</strong> ponto{current_points !== 1 ? 's' : ''}
            </span>
            <span className="time">
              atualizado {card_size === 'xs' && <br />}
              {formatDistanceToNow(new Date(update_time), {
                addSuffix: true,
                locale: PTLocale
              }).replace('aproximadamente', '')}
            </span>
          </>
        )}
        {card_size === 'md' && (
          <>
            <span className="caption">
              {name} + {points} pontos
            </span>
            <Button size="xs">Consultar objetivos</Button>
            <ScoreBadge area={area} points={current_points} className="badge" />
          </>
        )}
      </Container>
    </>
  );
};
