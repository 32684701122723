import { Cover, Header, IconButton, Main, SearchForm, VideosSlider } from 'components';
import { useVideos } from 'hooks/useVideos';
import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { api } from 'services/api';
import { colors, services } from 'static';
import { CoverData } from 'types';

import { Section } from './styles';
import { VideoData } from './types';

export const VideoList: React.FC = () => {
  const {
    hasVideos,
    bootcampVideos,
    strengthVideos,
    cardioVideos,
    stretchAndRecoverVideos,
    boxAndFightVideos,
    yogaVideos,
    pilatesVideos,
    cyclingVideos,
    danceVideos,
    meditationVideos,
    allVideos,
    setAllVideos,
    setBootcampVideos,
    setStrengthVideos,
    setCardioVideos,
    setStretchAndRecoverVideos,
    setBoxAndFightVideos,
    setYogaVideos,
    setPilatesVideos,
    setCyclingVideos,
    setDanceVideos,
    setMeditationVideos
  } = useVideos();

  const { oceanBlue300 } = colors;
  const [coverContent, setCoverContent] = useState<CoverData>({} as CoverData);
  const [searchedVideos, setSearchedVideos] = useState<{ videos?: VideoData[] }>({
    videos: [] as VideoData[]
  });
  const [showResults, setShowResults] = useState(false);

  const getVideos = useCallback(async () => {
    const response = await api.get('/videos/by-category');
    const { data } = response;

    const {
      bootcamp,
      strength,
      cardio,
      stretchAndRecover,
      boxAndFight,
      yoga,
      pilates,
      cycling,
      dance,
      meditation
    } = data;

    function mapList(list: VideoData[]) {
      return list
        .map(({ video, details, specs }) => {
          const { level } = specs;
          const { imageLink, durationInSeconds, publishedDate } = video;
          const { name, tag, description } = details;
          const minutes = Math.floor(durationInSeconds / 60);
          return {
            img: imageLink,
            title: `${name.slice(0, 64)}${name.length > 64 ? '...' : ''}`,
            tag,
            description,
            minutes,
            publishedDate,
            level
          };
        })
        .splice(0, 5);
    }

    setBootcampVideos(mapList(bootcamp));
    setStrengthVideos(mapList(strength));
    setCardioVideos(mapList(cardio));
    setStretchAndRecoverVideos(mapList(stretchAndRecover));
    setBoxAndFightVideos(mapList(boxAndFight));
    setYogaVideos(mapList(yoga));
    setPilatesVideos(mapList(pilates));
    setCyclingVideos(mapList(cycling));
    setDanceVideos(mapList(dance));
    setMeditationVideos(mapList(meditation));

    setAllVideos([
      ...bootcamp,
      ...strength,
      ...cardio,
      ...stretchAndRecover,
      ...boxAndFight,
      ...yoga,
      ...pilates,
      ...cycling,
      ...dance,
      ...meditation
    ]);
  }, []);

  useEffect(() => {
    if (!hasVideos) getVideos();
  }, []);

  useEffect(() => {
    setCoverContent(
      services.filter(({ slug }) => slug === '/movimento/videos').shift()?.cover ||
        ({} as CoverData)
    );
  }, []);

  useEffect(() => {
    if (searchedVideos.videos && searchedVideos.videos.length > 0) setShowResults(true);
  }, [searchedVideos]);

  return (
    <>
      <Header />
      <Main>
        <Cover {...coverContent} />
        <Section className="search">
          <span className="sub-title">comece a ver</span>
          <p>As melhores video-aulas para si</p>
          <SearchForm
            label="pesquisar aulas"
            content={{ videos: allVideos }}
            area="videos"
            setCurrentContent={setSearchedVideos}
          />
        </Section>
        {showResults && searchedVideos.videos && searchedVideos.videos?.length > 0 && (
          <Section className="results">
            <IconButton icon="close" color={oceanBlue300} onClick={() => setShowResults(false)} />
            <h2>
              {searchedVideos.videos.length} resultado
              {searchedVideos.videos.length !== 1 ? 's' : ''}
            </h2>
            {searchedVideos.videos.map(({ details }, i) => (
              <div className="result-card" key={i}>
                <Link to={`/movimento/video/${details.tag}`}>{details.name.slice(0, 38)}</Link>
              </div>
            ))}
          </Section>
        )}
        {bootcampVideos && (
          <Section>
            <h2>bootcamp</h2>
            <VideosSlider slides={bootcampVideos} category="Bootcamp" />
          </Section>
        )}
        {strengthVideos && (
          <Section>
            <h2>strength</h2>
            <VideosSlider slides={strengthVideos} category="Strength" />
          </Section>
        )}
        {cardioVideos && (
          <Section>
            <h2>cardio</h2>
            <VideosSlider slides={cardioVideos} category="Cardio" />
          </Section>
        )}
        {stretchAndRecoverVideos && (
          <Section>
            <h2>stretch and recover</h2>
            <VideosSlider slides={stretchAndRecoverVideos} category="Stretch and Recover" />
          </Section>
        )}
        {boxAndFightVideos && (
          <Section>
            <h2>box and fight</h2>
            <VideosSlider slides={boxAndFightVideos} category="Box and Fight" />
          </Section>
        )}
        {yogaVideos && (
          <Section>
            <h2>yoga</h2>
            <VideosSlider slides={yogaVideos} category="Yoga" />
          </Section>
        )}
        {pilatesVideos && (
          <Section>
            <h2>pilates</h2>
            <VideosSlider slides={pilatesVideos} category="Pilates" />
          </Section>
        )}
        {cyclingVideos && (
          <Section>
            <h2>cycling</h2>
            <VideosSlider slides={cyclingVideos} category="Cycling" />
          </Section>
        )}
        {danceVideos && (
          <Section>
            <h2>dance</h2>
            <VideosSlider slides={danceVideos} category="Dance" />
          </Section>
        )}
        {meditationVideos && (
          <Section>
            <h2>meditation</h2>
            <VideosSlider slides={meditationVideos} category="Meditation" />
          </Section>
        )}
      </Main>
    </>
  );
};
