import styled from 'styled-components';

export const QRDetails = styled.div`
  background: var(--c-silver-grey-100);
  padding: 0 2rem 3.6rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .sub-title {
    color: var(--c-smoke-grey-400);
    margin-bottom: 1.4rem;
  }

  p {
    text-align: center;
  }
`;

export const ManualLog = styled.section`
  padding: 3.6rem 2rem;
  background: var(--c-white);

  h2 {
    max-width: 90%;
  }
`;

export const DataTrackers = styled.section`
  padding: 3.6rem 2rem;
  background: var(--c-white);

  .slider {
    padding: 3rem 0;
  }
`;

export const Card = styled.div`
  padding: 2rem;
  border-radius: 2rem;
  background: var(--c-silver-grey-100);
  height: 24rem;
  position: relative;

  display: grid;
  grid-template-rows: 3fr 1fr;
  gap: 2rem;

  img {
    width: 100%;
    height: 14rem;
    object-fit: contain;
  }

  button {
    margin: 0 auto;
  }
`;

export const GraphSection = styled.section`
  .graph {
    &::before {
      background: var(--c-white);
    }
  }
`;
