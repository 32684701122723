import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  width: 14rem;
  height: 14rem;

  .content {
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .area,
    .points,
    .label {
      text-align: center;
      color: var(--c-white);
      width: 100%;
    }

    .area,
    .label {
      font-size: 1.6rem;
      font-weight: var(--f-semi-bold);
      opacity: 0.7;
    }

    .area {
      text-transform: uppercase;
      margin-bottom: 1.6rem;
    }

    .points {
      font-size: 3.6rem;
      font-weight: var(--f-bold);
    }

    .label {
    }
  }

  .content,
  svg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  svg {
    filter: brightness(0.85);

    #gradient {
      .stop-1 {
        stop-color: var(--c-bonus-1);
      }

      .stop-2 {
        stop-color: var(--c-bonus-2);
      }
    }

    &.nutrition {
      #gradient {
        .stop-1 {
          stop-color: var(--c-nutrition-1);
        }

        .stop-2 {
          stop-color: var(--c-nutrition-2);
        }
      }
    }

    &.movement {
      #gradient {
        .stop-1 {
          stop-color: var(--c-movement-2);
        }

        .stop-2 {
          stop-color: var(--c-movement-1);
        }
      }
    }

    &.health {
      #gradient {
        .stop-1 {
          stop-color: var(--c-health-1);
        }

        .stop-2 {
          stop-color: var(--c-health-2);
        }
      }
    }
  }
`;
