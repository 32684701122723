import React, { createContext, useContext, useState } from 'react';

interface LocationContextData {
  setLocation(location: string): void;
  location: string | null;
}

const LocationContext = createContext<LocationContextData>({} as LocationContextData);

const LocationProvider: React.FC = ({ children }: { children?: React.ReactNode }) => {
  const [location, setLocation] = useState<string | null>(null);

  return (
    <LocationContext.Provider value={{ setLocation, location }}>
      {children}
    </LocationContext.Provider>
  );
};

function useLocation(): LocationContextData {
  const context = useContext(LocationContext);
  if (!context) throw Error('the hook useLocation must be used inside a UserProvider');
  return context;
}

export { LocationProvider, useLocation };
