import styled from 'styled-components';

export const Container = styled.div`
  width: 40%;
  position: relative;

  & > button {
    width: 100%;
    background: transparent;
    border: none;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 1.4rem;
    text-transform: uppercase;
  }

  svg {
    margin-left: 0.4rem;
    width: 1.8rem;
    height: 2rem;
  }

  .content {
    width: 18rem;
    background: var(--c-white);
    overflow: hidden;
    padding: 1rem;
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
    box-shadow: 0px 10px 15px #9a9a9a36;

    position: absolute;
    top: 100%;
    left: -4rem;
    z-index: -1;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    height: 0;

    &.show {
      height: unset;
      z-index: 1;
    }

    button {
      width: 100%;
      border: none;
      background: transparent;
      text-align: left;
      padding: 0.8rem 0;
      line-height: 1.2;

      & + button {
        border-top: 1px solid var(--c-silver-grey-100);
      }
    }
  }
`;
