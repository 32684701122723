import React, { useState } from 'react';
import { FiChevronDown } from 'react-icons/fi';

import { Container } from './styles';

interface FilterProps {
  label: string;
  options: string[];
  handleFilter(item: string | null): void;
}

export const Filter: React.FC<FilterProps> = ({ label, options, handleFilter }: FilterProps) => {
  const [showFilterOptions, setShowFilterOptions] = useState(false);

  return (
    <Container>
      <button onClick={() => setShowFilterOptions(!showFilterOptions)}>
        <span>{label}</span> <FiChevronDown />
      </button>
      <div className={`content ${showFilterOptions ? 'show' : ''}`}>
        <button
          onClick={() => {
            handleFilter('');
            setShowFilterOptions(false);
          }}>
          todos
        </button>
        {options.map((item, i) => (
          <button
            key={i}
            onClick={() => {
              handleFilter(item);
              setShowFilterOptions(false);
            }}>
            {item}
          </button>
        ))}
      </div>
    </Container>
  );
};
